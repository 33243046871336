import React from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import HeaderAdmin from './components/header/header.admin'
import './DasbhoardAdmin.css'
export default function DashboardAdmin() {
  const location = useLocation();
  const currentPath = location.pathname;
  return (
    <div className='dash_board'>
      <HeaderAdmin />
      <div className='DashBoard_Container d-flex gap-2'>
        <aside className='adminsidebar'>
          <div className='Links'>
            <Link to='activeagents' className={`categoryelement  d-flex justify-content-between align-items-center ${currentPath.includes("activeagents") && "categoryelement_active"}`}>
              <div className="wrapper d-flex align-items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                >
                  <path
                    d="M4.125 3.125C3.57272 3.125 3.125 3.57272 3.125 4.125V20.875C3.125 21.4273 3.57272 21.875 4.125 21.875H20.875C21.4273 21.875 21.875 21.4273 21.875 20.875V4.125C21.875 3.57272 21.4273 3.125 20.875 3.125H4.125ZM18.75 18.2292C18.75 18.5168 18.5168 18.75 18.2292 18.75H6.77083C6.48318 18.75 6.25 18.5168 6.25 18.2292C6.25 17.9415 6.48318 17.7083 6.77083 17.7083H18.2292C18.5168 17.7083 18.75 17.9415 18.75 18.2292ZM18.75 16.1458C18.75 16.4335 18.5168 16.6667 18.2292 16.6667H6.77083C6.48318 16.6667 6.25 16.4335 6.25 16.1458C6.25 15.8582 6.48318 15.625 6.77083 15.625H18.2292C18.5168 15.625 18.75 15.8582 18.75 16.1458ZM18.75 11.5C18.75 12.0523 18.3023 12.5 17.75 12.5H7.25C6.69771 12.5 6.25 12.0523 6.25 11.5V7.25C6.25 6.69772 6.69772 6.25 7.25 6.25H17.75C18.3023 6.25 18.75 6.69772 18.75 7.25V11.5Z"
                    fill="#FAFAFC"
                  />
                </svg>
                <p>Active Agents</p>
              </div>
            </Link>
          </div>
          <div className='Links'>
            <Link to='banagents' className={`categoryelement  d-flex justify-content-between align-items-center ${currentPath.includes("banagents") && "categoryelement_active"}`}>
              <div className="wrapper d-flex align-items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                >
                  <path
                    d="M4.125 3.125C3.57272 3.125 3.125 3.57272 3.125 4.125V20.875C3.125 21.4273 3.57272 21.875 4.125 21.875H20.875C21.4273 21.875 21.875 21.4273 21.875 20.875V4.125C21.875 3.57272 21.4273 3.125 20.875 3.125H4.125ZM18.75 18.2292C18.75 18.5168 18.5168 18.75 18.2292 18.75H6.77083C6.48318 18.75 6.25 18.5168 6.25 18.2292C6.25 17.9415 6.48318 17.7083 6.77083 17.7083H18.2292C18.5168 17.7083 18.75 17.9415 18.75 18.2292ZM18.75 16.1458C18.75 16.4335 18.5168 16.6667 18.2292 16.6667H6.77083C6.48318 16.6667 6.25 16.4335 6.25 16.1458C6.25 15.8582 6.48318 15.625 6.77083 15.625H18.2292C18.5168 15.625 18.75 15.8582 18.75 16.1458ZM18.75 11.5C18.75 12.0523 18.3023 12.5 17.75 12.5H7.25C6.69771 12.5 6.25 12.0523 6.25 11.5V7.25C6.25 6.69772 6.69772 6.25 7.25 6.25H17.75C18.3023 6.25 18.75 6.69772 18.75 7.25V11.5Z"
                    fill="#FAFAFC"
                  />
                </svg>
                <p>Ban Agents</p>
              </div>
            </Link>
          </div>

        </aside>
        <div className="View">
          <Outlet />
        </div>
      </div>
    </div>
  )
}

