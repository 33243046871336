/** @format */

import { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { useMessageContext } from '../../../context/Message.context';
import GetTemplateData from '../../../utils/apis/GetTemplateData.api';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
export default function IDXWrappertemplateone() {
  const [data, setData] = useState(null);
  const { preview } = useParams();
  const { setTheme } = useMessageContext();
  useEffect(() => {
    const getData = async () => {
      const data = await GetTemplateData(preview, 1, 1);
      if (data) {
        setData(data);
        setTheme({
          primary_color: data.styles[0].css.primary,
          secondary_color: data.styles[0].css.secondary,
          warning_message: {
            fontFamily: 'Syncopate',
            fontSize: '20px',
            fontWeight: '700',
            lineHeight: '20.82px',
          },
          warning_text: {
            fontFamily: 'Garamond',
            fontSize: '18px',
            fontWeight: '400',
            lineHeight: '22.32px',
            textAlign: 'center',
          },
          button: {
            fontFamily: 'Syncopate',
            fontSize: '18px',
            fontWeight: '700',
            lineHeight: '18.74px',
          },
        });
      }
    };
    getData();
  }, []);

  const theme = {
    colors: {
      primary: data?.styles[0].css.primary,
      text: data?.styles[0].css.secondary,
      text2: '#000000',
      background: '#f8f9fa',
    },
    fonts: {
      primary_font: 'Syncopate',
      secondary_font: 'Garamond',
    },
    not_found_page: {
      warning_number: {
        first_letter: 0,
        second_letter: -34,
        third_letter: -30,
        stroke: 3,
        weight: 700,
      },
      button: {
        border_radius: 0,
      },
    },
  };

  const customStyles = data
    ? `
    :root {
        --primary: ${data.styles[0].css.primary};
        --secondary: ${data.styles[0].css.secondary};
    }
    .footer {
      // background: ${data.styles[0].css.primary};
      // color: ${data.styles[0].css.secondary};
      background:'white';
      color:'black';
      
  }
  .footer{
    display: flex;
    width: 100%;
    padding-top: 3rem;
    padding-bottom: 3rem;
}
  `
    : '';

  return data ? (
    <ThemeProvider theme={theme}>
      <style>{customStyles}</style>
      <Header {...data.header}></Header>
      <Outlet />
      <Footer
        {...{
          footerData: data.footer,
        }}></Footer>
    </ThemeProvider>
  ) : (
    <></>
  );
}
