import { ReactNode, createContext, useContext, useReducer } from 'react';

type AdminProps = {
  isAdmin: boolean
}

export type StateProps = {
  Admin: AdminProps | null;
}

type ContextValue = {
  state: StateProps;
  setAdmin: (Admin: AdminProps) => void;
}

const initialState: StateProps = {
  Admin: {
    isAdmin: false
  }
}

type Action = { type: 'set_admin'; payload: { Admin: AdminProps } }

const AuthReducer = (state: StateProps, action: Action) => {
  switch (action.type) {
    case 'set_admin':
      return { ...state, Admin: action.payload.Admin }
  }
}

const AuthContext = createContext<ContextValue | undefined>(undefined)

interface AuthContextProviderProps {
  children: ReactNode;
}

const AuthContextProvider = ({
  children
}: AuthContextProviderProps) => {
  const [state, dispatch] = useReducer(AuthReducer, initialState);

  const setAdmin = (Admin: AdminProps) => {
    dispatch({ type: 'set_admin', payload: { Admin } })
  }

  const contextValue: ContextValue = {
    state,
    setAdmin
  }
  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  )
}

//customHook

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('AuthContext must be used in withing a AuthContext')
  }
  return context
}

export default AuthContextProvider
