/** @format */

import styled from 'styled-components';

import { LoadingContainer } from '../../utilComponent/idxListing/idxListing.style';

import { ImageNotFoundContainer } from '../../utilComponent/idxView/idxView.style';

export const BuilderImageNotFoundContainer = styled(ImageNotFoundContainer)``;

export const BuilderLoadingContainer = styled(LoadingContainer)`
  .loader {
    border-top: 2px solid
      ${(props) =>
        props.theme?.colors?.primary
          ? props.theme?.colors?.primary
          : 'var(--primary)'};
  }
`;

export const BuilderMatchContainer = styled.div`
  width: min(120rem, 100%);
  margin: 0 auto;
  .peginate_class {
    width: min(95%, 50rem);
    margin: 2rem auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 0.625rem;
    flex-wrap: wrap;
    .page,
    .break {
      list-style-type: none;
      color: var(--primay);
      border: 1px solid gray;
      padding: 0.25rem 1rem;
      flex: 1;
      text-align: center;
      transition: all 200ms;
      &:hover {
        transition: all 200ms;
        color: var(--secondary);
        background-color: var(--primary);
      }
    }
    .selected {
      transition: all 200ms;
      color: var(--secondary);
      background-color: var(--primary);
    }
    .previous,
    .next {
      list-style-type: none;
      flex: 1;
      text-align: center;
      padding: 0.25rem 1rem;
      border: 1px solid gray;
      color: var(--primary);
      a {
        color: var(--primary);
        text-decoration: none;
      }
    }
    .previous {
      border-bottom-left-radius: 0.345rem;
      border-top-left-radius: 0.345rem;
      cursor: pointer;
    }
    .next {
      cursor: pointer;
      border-bottom-right-radius: 0.345rem;
      border-top-right-radius: 0.345rem;
    }
  }
`;
export const BuilderMatchSearchContainer = styled.div`
  padding: 1rem 1.5rem;
  display: flex;
  /* justify-content: space-between; */
  gap: 1rem;
  align-items: center;
  /* flex-wrap: wrap; */
  .search_filter {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
  }
  .browse_homes {
    margin-left: auto;
  }
  @media screen and (max-width: 730px) {
    flex-direction: column;
  }
`;

export const BuilderDealsPropertyContainer = styled.div`
  width: min(115rem, 94%);
  display: grid;
  grid-template-columns: repeat(auto-fit, minMax(288px, 1fr));
  justify-content: center;
  justify-items: center;
  margin: 3rem auto;
  gap: 1rem;
  @media screen and (min-width: 1281px) {
    width: min(95rem, 95%);
    grid-template-columns: repeat(auto-fit, minMax(290px, 1fr));
  }
`;
