/** @format */

import { Link } from "react-router-dom";
import styled from "styled-components";

export const SliderContainer = styled.div`
  font-family: "Tenor Sans";
  // margin-bottom: 3rem;
  .slider {
    padding: 1rem;
    .city_card {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .city_card:hover .city_image img {
      scale: 1.1;
      transition: all 0.5s ease-in-out;
    }
  }
  .custom_arrows_prev {
    position: absolute;
    top: 50%;
    left: -3%;
    z-index: 1;
    cursor: pointer;
    transform: translateY(-50%);
    @media screen and (max-width: 933px) {
      // top: 105%;
      // left: 85%;
    }
    @media screen and (max-width: 753px) {
      left: 0;
    }
  }
  .custom_arrows_next {
    position: absolute;
    top: 50%;
    right: -3%;
    cursor: pointer;
    transform: translateY(-50%);
    @media screen and (max-width: 933px) {
      // top: 105%;
      // right: 0;
    }
  }
`;

export const City = styled(Link)`
  font-family: 'Syncopate';
  position: relative;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* max-width: 31.25rem;
  max-height: 22rem; */
  overflow: hidden;
  .city_image {
    width: 100%;
    overflow: hidden;
    img {
      /* max-width: 22.25rem; */
      max-height: 22rem;
      min-height: 22rem;
      min-width: 100%;
      object-fit: cover;
      filter: brightness(50%);
    }
  }
  .city_name {
    font-family: 'Syncopate';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: clamp(1.25rem, 2vw, 2.25rem);
    color: ${(props) => props?.theme?.colors?.text || "var(--secondary)"};
    z-index: 1;
    text-transform: uppercase;
  }
`;
