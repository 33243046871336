import { styled } from "styled-components";
import { DashboardMainContainer } from "../../CommonStyle/dashboardMain.style";
export const MainContainer = styled(DashboardMainContainer)`
  height: 100%;
  margin-top: 0;
  overflow: scroll;
  h3 {
    color: #fafafc;
  }
  .Profile {
    margin-top: 2rem;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: flex-start;
  flex-direction:column;
  gap: 0.40rem;
  margin-bottom: 1.5rem;
  label {
    color: #fafafc;
  }
  input {
    background-color: #18181a;
    padding: 1.25rem;
    border-radius: 0.75rem;
    color: #fafafc;
    width:100%;
  }
  textarea {
    background-color: #18181a;
    padding: 1.25rem;
    border-radius: 0.75rem;
    color: #fafafc;
    width:100%;
  }
`;

export const InputImageContainer = styled.div`
  width:100%;
  height:100%;
  label {
    color: #fafafc;
  }
  .profile_image{
   display: block;
   width:100%;
   height:100%;
   aspect-ratio: 3 /2;
  }
`
export const FormContainer = styled.form`
 display:flex;
 justify-content:space-between;
 align-items:flex-start;
 gap:5rem;
 .profile_image_section{
   flex:0.6;
   width:100%;
   height:100%;
 }
.profile_info_section{
  flex:0.4;
  width:100%;
  height:100%;
 }
@media (max-width:1110px){
  flex-direction:column;
  gap:1rem;
}

`

export const ConfirmUpdate = styled.div`
 position: fixed;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
 width: 40rem;
 height: 20rem;
 background-color: white;
 text-align:center;
 display:flex;
 justify-content: center;
 align-items:center;
 flex-direction:column;
 border-radius:1.20rem;
 .mls_actions{
     margin-top:1rem;
    .mls_cancle,.mls_delete{
      border:none;
      background-color:black;
      padding-block:0.35rem;
      padding-inline:0.55rem;
      color:white;
      margin-inline:0.25rem;
      border-radius:0.20rem;
   }
}
`
export const Button = styled.button`
 background-color:black;
 color:white;
 padding-block:0.30rem;
 padding-inline:0.40rem;
 border-radius:0.60rem;
 border:none;
 font-size:clamp(1.2rem, 2vw,1.6rem);
`
