import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import "../../Template1/Template1.css";
import "./BlogView.css";
import { FlexContainer, MainContainer } from "./BlogView.style";
export default function BlogView() {
  const [data, setData] = useState(null);
  const [blogData, setBlogData] = useState(null);

  useEffect(() => {
    const blogId = new URLSearchParams(window.location.search).get("id");
    const token = Cookies.get("token");

    axios
      .get(`/api/get-agent-blog/${blogId}`, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((res) => {
        setBlogData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get("/api/get-template?n=1&p=1", {
        headers: {
          "x-access-token": token,
        },
      })
      .then((res) => {
        res.data && setData(res.data);
      });
  }, []);

  const customStyles = data
    ? `
        :root {
            --primary: ${data.styles[0].css.primary};
            --secondary: ${data.styles[0].css.secondary};
        }
        .footer {
            background: ${data.styles[0].css.primary};
            color: ${data.styles[0].css.secondary};
            
        }
        body {
            min-height: 100vh;
        }
        #root{
            min-height: 100vh;
        }

        .footer{
            display: flex;
            width: 100%;
            padding-top: 3rem;
            padding-bottom: 3rem;
        }

        .card-img-top{
            margin: auto;
            margin-bottom: 2rem;
        }
    `
    : "";
  return data ? (
    <div
      style={{
        minHeight: "100vh",
        width: "min(120rem,100%)",
        margin: "0 auto",
      }}
    >
      <style>{customStyles}</style>
      {/* {data && <Header {...data.header}></Header>} */}
      {/* <div className="container" style={{marginTop:"3rem", display: "grid"}}>
              <h1 style={{
                  marginBottom: "2rem", fontFamily: 'Syncopate' }} className='text-center'>{blogData?.title}</h1>
              <img src={"/public/" + blogData?.coverImage} className="card-img-top blog_img" alt="..." />
            <div className="row">
                <div className="col-md-12"> 
                      <div style={{ fontFamily:'Garamond',color:'black'}} dangerouslySetInnerHTML={{__html: blogData?.description}}></div>
                </div>
            </div>
        </div> */}
      <MainContainer className="">
        <div className="image_container_hero_section">
          <p className='heading'>{blogData?.title}</p>
          <img src={"/public/" + blogData?.coverImage} alt="blog_hero_section" />
        </div>
      </MainContainer>
      <FlexContainer>
        <div className="row">
          <div className="order-1 order-md-1 col-12 col-md-6" style={{ overflow: 'hidden', paddingRight: '0' }}>
            <img style={{ objectFit: 'cover', height: '100%', width: '100%' }} src={"/public/" + blogData?.descImage1} alt="" />
          </div>
          <div className="order-2 order-md-2 col-12 col-md-6 p-5">
            <p className='storyDis' dangerouslySetInnerHTML={{ __html: blogData?.description2 }}></p>
          </div>
        </div>
      </FlexContainer>
      <FlexContainer>
        <div className="row">
          <div className="order-2 order-md-1 col-12 col-md-6 p-5">
            <p className='storyDis' dangerouslySetInnerHTML={{ __html: blogData?.description3 }}></p>
          </div>
          <div className="order-1 order-md-2 col-12 col-md-6" style={{ overflow: 'hidden', paddingLeft: '0' }}>
            <img style={{ objectFit: 'cover', height: '100%', width: '100%' }} src={"/public/" + blogData?.descImage2} alt="" />
          </div>
        </div>
      </FlexContainer>
    </div>
  ) : (
    <div></div>
  );
}
