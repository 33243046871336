import { Link } from "react-router-dom";
import "./Header.css";
interface NavbarProps {
  text: string;
  logo: string; // Assuming Logo is an image URL
  color: string;
  nav: { text: string; link: string; color: string }[];
}
export default function Header({ text, logo, nav, color }: NavbarProps) {
  return (
    <div className="template1" style={{ zIndex: '1050' }}>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container">
          {/* <a className="navbar-brand" href="home" style={{color: color}}>
          <img src={"/public/"+logo} alt={""} width="30" height="30" />
        </a> */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link
                  className="nav-link"
                  style={{
                    fontFamily: "Garamond",
                    margin: "0rem 2rem",
                    textTransform: "uppercase",
                  }}
                  to={"../home"}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to={"../about"}
                  style={{
                    color: "white",
                    fontFamily: "Garamond",
                    margin: "0rem 2rem",
                    textTransform: "uppercase",
                  }}
                >
                  About us
                </Link>
              </li>
              {/*<li className="nav-item" >
                <Link className="nav-link" to={'../buyerguide'} style={{ color: 'white', fontFamily: "Garamond", margin: "0rem 2rem", textTransform: "uppercase" }}>
                  Buyers
                </Link>
              </li>
              <li className="nav-item" >
                <Link className="nav-link" to={'../sellerguide'} style={{ color: 'white', fontFamily: "Garamond", margin: "0rem 2rem", textTransform: "uppercase" }}>
                  Sellers
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={'../idxlisting'} style={{ color: 'white', fontFamily: "Garamond", margin: "0rem 2rem", textTransform: "uppercase" }}>
                  Browse Homes
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={'../tools'} style={{ color: 'white', fontFamily: "Garamond", margin: "0rem 2rem", textTransform: "uppercase" }}>
                  Tools
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={'../map'} style={{ color: 'white', fontFamily: "Garamond", margin: "0rem 2rem", textTransform: "uppercase" }}>
                  Map
                </Link>
              </li>*/}
              <li className="nav-item">
                <div className="dropdown ">
                  <a
                    className="dropdown-toggle nav-link"
                    href="#"
                    id="sellers"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    SELLERS
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="sellers">
                    <li>
                      <Link
                        style={{ color: "black" }}
                        className="nav-link"
                        to={"../sellerguide"}
                      >
                        Sellers
                      </Link>
                    </li>
                    <li>
                      <Link
                        style={{ color: "black" }}
                        className="nav-link"
                        to={"../homevaluation"}
                      >
                        Home Valuation
                      </Link>
                    </li>
                    <li>
                      <Link
                        style={{ color: "black" }}
                        className="nav-link"
                        to={"../blogs"}
                      >
                        Blogs
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <div className="dropdown ">
                  <a
                    className="dropdown-toggle nav-link"
                    href="#"
                    id="buyers"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    BUYERS
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="buyers">
                    <li>
                      <Link
                        style={{ color: "black" }}
                        className="nav-link"
                        to={"../buyerguide"}
                      >
                        Buyers
                      </Link>
                    </li>
                    <li>
                      <Link
                        style={{ color: "black" }}
                        className="nav-link"
                        to={"../mortagecalculator"}
                      >
                        Mortage Calculator
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <div className="dropdown">
                <a
                  className="dropdown-toggle nav-link"
                  href="#"
                  id="browse_home"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  BROWSE HOMES
                </a>
                <ul className="dropdown-menu" aria-labelledby="browse_home">
                  <li>
                    <Link
                      style={{ color: "black" }}
                      className="nav-link"
                      to={"../exclusivecards"}
                    >
                      Exclusive Search
                    </Link>
                  </li>
                  <li>
                    <Link
                      style={{ color: "black" }}
                      className="nav-link"
                      to={"../featuredcards"}
                    >
                      Featured Search
                    </Link>
                  </li>
                  <li>
                    <Link
                      style={{ color: "black" }}
                      className="nav-link"
                      to="../idxlisting"
                    >
                      Idx Search
                    </Link>
                  </li>
                  <li>
                    <Link
                      style={{ color: "black" }}
                      className="nav-link"
                      to={"../soldlisting"}
                    >
                      Sold Search
                    </Link>
                  </li>
                  <li>
                    <Link
                      style={{ color: "black" }}
                      className="nav-link"
                      to={"../builderdeals"}
                    >
                      Builder Search
                    </Link>
                  </li>
                  <li>
                    <Link
                      style={{ color: "black" }}
                      className="nav-link"
                      to={"../map"}
                    >
                      Map Search
                    </Link>
                  </li>
                </ul>
              </div>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to={"../contact"}
                  style={{
                    color: "white",
                    fontFamily: "Garamond",
                    margin: "0rem 2rem",
                    textTransform: "uppercase",
                  }}
                >
                  Contact us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
