/** @format */

import { useEffect, useState } from "react";

import {
  ContactContainer,
  ContactDetails,
  Container,
  MainContainer,
  SumbitForm,
} from "./contact.style";

import { useLocation } from "react-router-dom";
import SiteBuilder from "../../SiteBuilder/SiteBuilder";

import { useNavigate, useParams } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { useMessageContext } from "../../../context/Message.context";
import useContactUs from "../../../utils/apis/Contactus.api";
import GetTemplateData from "../../../utils/apis/GetTemplateData.api";
import LiveEdithTextHighLighter from "../../../liveEditHighlighterstyles/liveEdithTextHighLighter.component";

export default function Contact({ templateData, templateId }) {
  const [data, setData] = useState(null);
  const { preview } = useParams();
  const { SubmitFormSellerLead, handleFormData, formData } = useContactUs();
  const location = useLocation();
  const navigate = useNavigate();
  const { setTheme } = useMessageContext();
  const getQueryParams = () => {
    return new URLSearchParams(location.search);
  };

  const params = getQueryParams();

  const siteBuilder = params.get("siteBuilder");

  useEffect(() => {
    if (templateData) {
      setData(templateData);
    }
  }, [templateData]);

  useEffect(() => {
    const getData = async () => {
      if (!templateData) {
        const data = await GetTemplateData(preview, templateId, 3);
        if (data) {
          setData(data);
          setTheme({
            primary_color: data.styles[0].css.primary,
            secondary_color: data.styles[0].css.secondary,
            warning_message: {
              fontFamily: "Tenor Sans",
              fontSize: "20px",
              fontWeight: "400",
              lineHeight: "23.4px",
            },
            warning_text: {
              fontFamily: "Libre Baskerville",
              fontSize: "18px",
              fontWeight: "400",
              lineHeight: "22.32px",
              textAlign: "center",
            },
            button: {
              fontFamily: "Tenor Sans",
              fontSize: "18px",
              fontWeight: "400",
              lineHeight: "23.4px",
            },
          });
        }
      } else {
        setData(templateData);
      }
    };
    getData();
  }, []);

  if (!data)
    return (
      <div id="loadingScreen" className="loading-screen">
        <div className="loader"></div>
      </div>
    );

  const handleRedirect = () => {
    navigate(-1);
  };

  const theme = {
    colors: {
      primary: data.styles[0].css.primary,
      text: data.styles[0].css.secondary,
      text2: "#000000",
      background: "#f8f9fa",
    },
  };
  const content = (
    <ThemeProvider theme={theme}>
      <MainContainer>
        <Container imageUrl={`/public/${data?.sec1?.img}`}>
          <div className="mobile_view_image">
            <img src={`/public/${data?.sec1?.img}`} alt="contact_image" />
          </div>
          <div className="svg_wrapper" onClick={handleRedirect}>
            <svg
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M48.8144 48.8095L1.19531 1.19043M48.8144 1.19043L1.19531 48.8095"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>

          <ContactContainer>
            <LiveEdithTextHighLighter
              section="sec1"
              type={templateId === 2 ? "description" : "title"}
            >
              <div
                className="contact_us_heading"
                dangerouslySetInnerHTML={{
                  __html:
                    templateId === 2
                      ? data?.sec1?.description
                      : data?.sec1?.title,
                }}
              ></div>
            </LiveEdithTextHighLighter>
            {/* <h3 className="contact_us_heading">
              {templateId === 2
                ? data.sec1.description
                : templateData.sec1.title}
            </h3> */}
            <div className="contact_us_container">
              <ContactDetails>
                <LiveEdithTextHighLighter section="sec1" type="details">
                  <div className="heading">
                    <h1>contact details</h1>
                  </div>
                </LiveEdithTextHighLighter>
                <div className="contactInfo">
                  <h1>phone</h1>
                  <LiveEdithTextHighLighter section="sec1" type="phone">
                    <div
                      className="contact_info"
                      dangerouslySetInnerHTML={{
                        __html: data?.sec1?.phone,
                      }}
                    ></div>
                  </LiveEdithTextHighLighter>
                </div>
                <div className="contactInfo">
                  <h1>Email</h1>
                  <LiveEdithTextHighLighter section="sec1" type="email">
                    <div
                      className="contact_info"
                      dangerouslySetInnerHTML={{
                        __html: data?.sec1?.email,
                      }}
                    ></div>
                  </LiveEdithTextHighLighter>
                </div>
                <div className="contactInfo">
                  <h1>Address</h1>
                  <LiveEdithTextHighLighter section="sec1" type="address">
                    <div
                      className="contact_info"
                      dangerouslySetInnerHTML={{
                        __html: data?.sec1?.address,
                      }}
                    ></div>
                  </LiveEdithTextHighLighter>
                </div>
              </ContactDetails>
              <div className="bar"></div>
              <SumbitForm onSubmit={(e) => SubmitFormSellerLead(e)}>
                <div className="inputINfo">
                  <label className="form_label" htmlFor="Name">
                    Name
                  </label>
                  <input
                    className="input"
                    type="text"
                    value={formData.name}
                    onChange={(e) => handleFormData("name", e)}
                    required
                  />
                </div>
                <div className="inputINfo">
                  <label className="form_label" htmlFor="Phone">
                    Phone
                  </label>
                  <input
                    className="input"
                    type="text"
                    value={formData.phone}
                    onChange={(e) => handleFormData("phone", e)}
                    required
                  />
                </div>
                <div className="inputINfo">
                  <label className="form_label" htmlFor="Email">
                    Email
                  </label>
                  <input
                    className="input"
                    type="email"
                    value={formData.email}
                    onChange={(e) => handleFormData("email", e)}
                    required
                  />
                </div>
                <div className="inputINfo">
                  <label className="form_label" htmlFor="Address">
                    Address
                  </label>
                  <textarea
                    className="input"
                    value={formData.address}
                    onChange={(e) => handleFormData("address", e)}
                    required
                  />
                </div>
                <div className="inputINfo">
                  <label className="form_label" htmlFor="Message">
                    Message
                  </label>
                  <textarea
                    className="input"
                    value={formData.message}
                    onChange={(e) => handleFormData("message", e)}
                    required
                  />
                </div>
                <button className="submit_form">
                  Submit
                  <svg
                    width="10"
                    height="20"
                    viewBox="0 0 10 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.85092 0.586716C1.54312 0.586109 1.24482 0.693977 1.00782 0.891594C0.874422 1.00287 0.764157 1.13954 0.683335 1.29377C0.602513 1.44799 0.552722 1.61674 0.536814 1.79036C0.520906 1.96398 0.539193 2.13905 0.590628 2.30555C0.642064 2.47204 0.725635 2.62669 0.836559 2.76064L6.7383 9.86565L1.04734 16.9839C0.937909 17.1195 0.856192 17.2755 0.806881 17.443C0.75757 17.6105 0.741638 17.7861 0.760001 17.9598C0.778364 18.1335 0.830659 18.3018 0.913881 18.4551C0.997103 18.6084 1.10961 18.7436 1.24494 18.853C1.38124 18.9736 1.54086 19.0647 1.71378 19.1203C1.8867 19.176 2.06918 19.1951 2.24978 19.1764C2.43038 19.1577 2.6052 19.1017 2.76326 19.0118C2.92131 18.922 3.0592 18.8002 3.16827 18.6541L9.53109 10.7008C9.72484 10.4636 9.83077 10.1661 9.83077 9.85903C9.83077 9.55199 9.72484 9.25448 9.53109 9.01729L2.94432 1.06392C2.81217 0.903502 2.6443 0.776693 2.45432 0.693781C2.26435 0.610868 2.05762 0.574188 1.85092 0.586716Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </SumbitForm>
            </div>
          </ContactContainer>
        </Container>
      </MainContainer>
    </ThemeProvider>
  );

  return (
    <SiteBuilder
      {...{
        template: content,
        siteBuilder: siteBuilder,
        data,
        setData,
      }}
    ></SiteBuilder>
  );
}
