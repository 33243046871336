import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useDashboardExclusiveListing } from "../../../../context/DashboardExclusiveListing";
export default function useExclusiveListing() {
  const {
    setProperty,
    state: { ExclusiveListing },
    setFormVisualState,
  } = useDashboardExclusiveListing();
  const [loader, setLoader] = useState<Boolean>(false);

  const token = Cookies.get("token");

  const addExclusiveListing = async (exlcusiveProperty: any): Promise<void> => {
    try {
      const response = await axios.post(
        "/admin/add-exclusive-listing",
        exlcusiveProperty,
        {
          headers: {
            "x-access-token": token,
          },
        },
      );
      if (response.status >= 200 && response.status < 300) {
        const Value = [...ExclusiveListing, exlcusiveProperty];
        setProperty(Value);
        setFormVisualState(false);
      }
    } catch (error) {
      alert("failed to add ");
      throw new Error(error);
    }
  };

  const deleteExclusiveListing = async (id: string): Promise<void> => {
    try {
      const response = await axios.get(
        "/admin/delete-exclusive-listing/" + id,
        {
          headers: {
            "x-access-token": token,
          },
        },
      );
      if (response.status >= 200 && response.status < 300) {
        await GetExclusiveListing({ token: token, source: null });
      }
    } catch (err) {
      alert("failed to delete ");

      throw new Error(err.message);
    }
  };

  const updateExclusiveListing = async (data: any): Promise<void> => {
    try {
      const response = await axios.post(
        `/admin/update-exclusive-listing`,
        data,
        {
          headers: {
            "x-access-token": token,
          },
        },
      );
      if (response.status >= 200 && response.status < 300) {
        await GetExclusiveListing({ token: token, source: null });
      }
    } catch (err) {
      alert("failed to update ");
      throw new Error(err.message);
    } finally {
      setFormVisualState(false);
    }
  };

  const GetExclusiveListing = async ({ token, source }): Promise<void> => {
    setLoader(true);
    try {
      const config = {
        headers: {
          "x-access-token": token,
        },
      };
      if (source) {
        config["cancelToken"] = source.token;
      }
      const GetData = await axios.get("/admin/get-exclusive-listing", config);
      setProperty(GetData.data.data);
      setLoader(false);
    } catch (err) {
      setLoader(false);
      throw new Error(err);
    } finally {
      setLoader(false);
    }
  };

  const uploadImage = async (file: File): Promise<string> => {
    try {
      const formData = new FormData();
      formData.append("image", file);
      const Image = await axios.post("/api/upload", formData, {
        headers: {
          "x-access-token": token,
        },
      });
      console.log(Image.data);
      const Imagefile = await Image.data.file;

      return Imagefile;
    } catch (err) {
      throw new Error(err.message);
    }
  };

  useEffect(() => {
    const source = axios.CancelToken.source();

    GetExclusiveListing({ token, source });
    return () => {
      source.cancel("Api call got cancelled by cleanup");
    };
  }, []);

  return {
    loader,
    addExclusiveListing,
    deleteExclusiveListing,
    updateExclusiveListing,
    uploadImage,
  };
}
