/** @format */ 
import styled from 'styled-components';

export const MainContainer = styled.div`
  height: auto; 

  .image_container_hero_section {
    padding-bottom: 2rem; 
    position: relative;
    font-family: 'Montserrat';


    // .heading {
    //   font-family: 'Syncopate', sans-serif;
    //   font-size: clamp(1.8rem, 3rem, 5vw); 
    //   color: var(--primary);
    //   text-align: center;
    //   position: absolute;
    //   top: 2%; 
    //   width: 100%;
    //   z-index: 1; 
    // }

    img {
      width: 100%;
      height: 66vw; 
      object-fit: cover;
      // border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }

    .thumbnail-slider {
      // margin-top: 2rem; 
      
      .slick-slide {
        padding: 0; 
        margin: 0;     }

        .slick-list {
          padding: 20px 50px !important;
        }

      img {
        // width: 80px;
        // height: 80px;
        object-fit: cover;
        border-radius: 5px;
        border: 2px solid transparent;
        transition: all 0.3s ease;
        cursor: pointer;
      }

      .thumbnail-content {
        padding: 3rem; 
        text-align: center; 
        margin-top: 1rem; 
        margin-bottom: 2rem; 
        background-color: rgba(255, 255, 255, 0.9); 
        border-radius: 8px; 
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
      }

      .thumbnail-content h2 {
      font-family: 'Syncopate';
        font-size: 2rem; 
        color: var(--primary);
        margin-bottom: 0.5rem; 
      }

      .thumbnail-content p {
        font-size: 1rem;
        color: var(--secondary);
        line-height: 1.5; 
      }

      img:hover {
        // border-color: var(--primary);
        transform: scale(1.1);
      }
    }
  }
`;
