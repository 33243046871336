import { styled } from "styled-components";

export const AgentListContainerStyle = styled.div`
  border-radius: 0.9375rem;
  background: var(--Element-BG-Dark-Grey, #18181a);
  padding: 0.94rem;
  padding-top: 0.25rem;
  position: relative;
  overflow: auto;
  /* overflow: scroll; */
  /* scrollbar-width: thin; */
  /* scrollbar-color: #999 #f1f1f1; */
  height: 100%;
  flex: 1;

 .agent_header {
  position:sticky;
  top: 0;
  left: 0;
  height: 2rem;
  background: var(--Element-BG-Dark-Grey, #18181a);
  padding-top: 0.94rem;
 }

 .agent_tab_header,
 .agent_info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr  1fr 0.4fr;
  align-items: start;
 }

`
