/** @format */

import styled from 'styled-components';

export const Section2Container = styled.div`
  width: min(110rem, 95%);
  position: relative;
 background-color: ${(props) => props.theme.colors.primary};
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  color:white;
  .Left_section {
    flex: 0.6;
    width: 100%;
    height: 100%;
    .ImageSection {
      width: 100%;
      height: 100%;
      object-fit: cover;
      height: 80vh;
    }
  }
  .Right_section {
    padding: 1rem;
    flex: 0.4;
    width: 100%;
    height: 100%;
    .heading {
      font-size: 48px;
      text-transform: uppercase;
      color: white;
    }
    .sub_heading {
      color: white;
    }
  }
  @media screen and (max-width: 866px) {
    flex-direction: column;
  }
`;
