import { useParams } from 'react-router-dom';
import { PopupForm, PopupFormContainer } from './SoldHomeForm.style';
import { useState } from 'react';
import ReactDOM from 'react-dom';

export default function SoldHomeForm({
  handleOpenClose,
  handleState,
  handleSubmitForm,
}) {
  const { preview } = useParams();
  const [showAlert, setShowAlert] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const email = e.target.email.value;
    const phone = e.target.phone.value;
    // Check if any field is empty
    if (!name || !email || !phone) {
      setShowAlert(true);
      return;
    }
    setShowAlert(false);
    handleSubmitForm(e);
  };

  const closeAlert = () => {
    setShowAlert(false);
  };

  return ReactDOM.createPortal(
    <PopupFormContainer>
      <PopupForm
        $disabledCheck={preview === 'preview' || preview === 'base'}
        onSubmit={handleSubmit}
        onClick={(event) => event.stopPropagation()}>
        <h3>
          Real Estate board requires you to be signed in to see the full details
          of sold properties
        </h3>
        <input
          className="Input"
          type="text"
          name="name"
          placeholder="Name"
          onChange={(e) => handleState('name', e.target.value)}
        />
        <input
          className="Input"
          type="email"
          name="email"
          placeholder="Email"
          onChange={(e) => handleState('email', e.target.value)}
        />
        <input
          className="Input"
          type="number"
          name="phone"
          placeholder="Contact"
          maxLength={11}
          onChange={(e) => handleState('phone', e.target.value)}
        />
        {(preview === 'preview' || preview === 'base') && (
          <p className="warning">
            Note:You need to publish your website for accessing the Sold Home
            data
          </p>
        )}
        <div className="buttons">
          <button
            className="button"
            onClick={handleOpenClose}>
            Cancel
          </button>
          <button
            disabled={preview === 'preview' || preview === 'base'}
            className="button submit_button">
            Submit
          </button>
        </div>
      </PopupForm>
      {showAlert && (
        <div
          style={{
            position: 'fixed',
            top: '20%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
            textAlign: 'center',
            zIndex: 9999,
          }}>
          <p>Please fill in all fields.</p>
          <button
            className="button"
            style={{
              padding: '10px 20px',
              borderRadius: '5px',
              cursor: 'pointer',
              marginTop: '10px',
            }}
            onClick={closeAlert}>
            OK
          </button>
        </div>
      )}
    </PopupFormContainer>,
    document.body
  );
}
