import React from 'react'
import { Route, Routes } from "react-router-dom"
import DashboardAdmin from '../pages/DashboardAdmin/DashboardAdmin.admin'
import AdminContextProvider from '../context/DashboardAdmin.context'
import { useAuth } from '../context/Auth.context';
import { Navigate } from 'react-router-dom';
import AgentListTable from '../pages/DashboardAdmin/components/AgentsList/AgentsList.admin';
import AgentBlockListTable from '../pages/DashboardAdmin/components/AgentsList/AgentBlockList.admin';

export default function DashboardAdminRoute() {
  const { state: { Admin } } = useAuth();
  if (!Admin?.isAdmin) {
    return <Navigate to="/dashboard/blogs" />;
  }
  return (
    <Routes>
      <Route path='admin/*' element={
        <AdminContextProvider>
          <DashboardAdmin />
        </AdminContextProvider>
      }>
        <Route path='activeagents' element={<AgentListTable />} />
        <Route path='banagents' element={<AgentBlockListTable />} />
      </Route>
    </Routes>
  )
}

