import React, { useState, useEffect } from 'react';
// import './Mortgagecalculator.css'
// import styled from 'styled-components';

import { Mort } from './MortageCalculator.style';

const MortageCalculator: React.FC = () => {
  const [homePrice, setHomePrice] = useState<string | number>(300000);
  const [downPayment, setDownPayment] = useState<string | number>(60000);
  const [interestRate, setInterestRate] = useState<string | number>(3.5);
  const [amortizationPeriod, setAmortizationPeriod] = useState<number>(25);
  const [paymentType, setPaymentType] = useState<string>('monthly'); 

  const [monthlyPayment, setMonthlyPayment] = useState<number>(0);
  const [biWeeklyPayment, setBiWeeklyPayment] = useState<number>(0);
  const [acceleratedBiWeeklyPayment, setAcceleratedBiWeeklyPayment] = useState<number>(0);

  const calculatePayments = () => {
    const loanAmount = Number(homePrice) - Number(downPayment);
    const monthlyRate = Number(interestRate) / 100 / 12;
    const totalPayments = amortizationPeriod * 12;

    const monthlyPaymentCalc =
      loanAmount * monthlyRate / (1 - Math.pow(1 + monthlyRate, -totalPayments));
    setMonthlyPayment(monthlyPaymentCalc);

    const biWeeklyPaymentCalc = monthlyPaymentCalc * 12 / 26;
    setBiWeeklyPayment(biWeeklyPaymentCalc);

    const acceleratedBiWeeklyPaymentCalc = monthlyPaymentCalc / 2;
    setAcceleratedBiWeeklyPayment(acceleratedBiWeeklyPaymentCalc);
  };

  useEffect(() => {
    calculatePayments();
  }, [homePrice, downPayment, interestRate, amortizationPeriod]);

  const mortgageInsurance = (Number(downPayment) * 0.01).toFixed(2);
  const mortgageTotal = (Number(homePrice) - Number(downPayment)).toFixed(2);

  const getPaymentAmount = () => {
    if (paymentType === 'monthly') {
      return monthlyPayment.toFixed(2);
    } else if (paymentType === 'biWeekly') {
      return biWeeklyPayment.toFixed(2);
    } else if (paymentType === 'acceleratedBiWeekly') {
      return acceleratedBiWeeklyPayment.toFixed(2);
    }
    return '';
  };

  const handleInputChange = (setter) => (e) => {
    const value = e.target.value;
    setter(value === "" ? "" : Number(value));
  };

  return (
    <Mort>
      <form className="container">
        <div className="row pt-0">
          <div className="col-md-10 mx-auto">
            <div className="row py-4">
              <div className="col-12 form-group text-center mb-5">
                <h1>Mortgage Calculator</h1>
              </div>
              <div className="col-md-6 mt-3">
                <div className="form-group">
                  <label className='form-label'>Home Price</label>
                  <input
                    type="number"
                    value={homePrice}
                    onChange={handleInputChange(setHomePrice)}
                    className='material-input'
                  />
                </div>
              </div>
              <div className="col-md-6 mt-3">
                <div className="form-group">
                  <label className='form-label'>Down Payment</label>
                  <input
                    type="number"
                    value={downPayment}
                    onChange={handleInputChange(setDownPayment)}
                    className='material-input'
                  />
                </div>
              </div>
              <div className="col-md-6 mt-3">
                <div className="form-group">
                  <label className='form-label'>Mortgage Total</label>
                  <input
                    type="number"
                    value={mortgageTotal}
                    className='material-input' readOnly
                  />
                </div>
              </div>
              <div className="col-md-6 mt-3">
                <div className="form-group">
                  <label className='form-label'>Amortization Period</label>
                  <select
                    value={amortizationPeriod}
                    onChange={(e) => setAmortizationPeriod(Number(e.target.value))}
                    className='material-select'
                  >
                    <option value={15}>15 Years</option>
                    <option value={20}>20 Years</option>
                    <option value={25}>25 Years</option>
                    <option value={30}>30 Years</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6 mt-3">
                <div className="form-group">
                  <label className='form-label'>Mortgage Rate/Year</label>
                  <input
                    type="number"
                    value={interestRate}
                    onChange={handleInputChange(setInterestRate)}
                    className='material-input'
                  />
                </div>
              </div>
              <div className="col-md-6 mt-3">
                <div className="form-group">
                  <label className='form-label'>Payment Type</label>
                  <select
                    value={paymentType}
                    onChange={(e) => setPaymentType(e.target.value)}
                    className='material-select'
                  >
                    <option value="monthly">Monthly Payment</option>
                    <option value="biWeekly">Bi-Weekly Payment</option>
                    <option value="acceleratedBiWeekly">Accelerated Bi-Weekly Payment</option>
                  </select>
                </div>
              </div>
              <div className="col-12 mt-3 text-end">
                <div className="form-group">
                  <h3>Payment Amount: ${getPaymentAmount()}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Mort>
  );
};

export default MortageCalculator;