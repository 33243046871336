import styled from "styled-components";

export const Section2Container = styled.div`
  width: min(110rem, 95%);
  position: relative;
  margin: 2rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background: ${({ theme: { colors } }) => colors.primary};

  .Left_section {
    flex: 0.6;
    width: 100%;
    height: 100%;
    .ImageSection {
      width: 100%;
      height: 100%;
      object-fit: cover;
      height: 80vh;
    }
  }
  .Right_section {
    padding: 1rem;
    flex: 0.4;
    width: 100%;
    height: 100%;
    .heading {
      font-size: 48px;
      text-transform: uppercase;
      color: black;
    }
    .sub_heading {
      color: black;
    }
  }
  @media screen and (max-width: 866px) {
    flex-direction: column;
  }
`;