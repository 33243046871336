import { Container } from './imageredirector2.style'; 
import { Link } from 'react-router-dom';
import usePermissionSoldProperty from '../../../CustomHooks/usePermissionSoldProperty.customHook';
import SoldHomeForm from '../../../utilComponent/SoldHomeForm/SoldHomeForm.component';
import ExclusiveListing from './Images/exclusive_listing.jpg';
import Seller from './Images/seller.jpg';
import Buyer from './Images/buyer.jpg';
import HomeValuation from './Images/home_valuation.jpg';

export default function ImageRedirectContainer2() {
  const {
    user,
    open,
    handleOpenClose,
    handleNavigation,
    handleSubmitForm,
    handleState,
  } = usePermissionSoldProperty();

  return (
    <Container>
      <div className="row">
        <Link to="../exclusivecards" className="images">
          <p className="link">Exclusive Listing</p>
          <img src={ExclusiveListing} alt="Exclusive Listing" />
          <span className="horizontal-line top-line"></span>
          <span className="horizontal-line bottom-line"></span>
        </Link>
        <Link to="../sellerguide" className="images">
          <p className="link">Seller</p>
          <img src={Seller} alt="Seller" />
          <span className="horizontal-line top-line"></span>
          <span className="horizontal-line bottom-line"></span>
        </Link>
      </div>

      <div className="row">
        <Link to="../buyerguide" className="images">
          <p className="link">Buyer</p>
          <img src={Buyer} alt="Buyer" />
          <span className="horizontal-line top-line"></span>
          <span className="horizontal-line bottom-line"></span>

        </Link>
        <Link to="../homevaluation" className="images">
          <p className="link">Home Valuation</p>
          <img src={HomeValuation} alt="Home Valuation" />
          <span className="horizontal-line top-line"></span>
          <span className="horizontal-line bottom-line"></span>
        </Link>
      </div>

      {open && (
        <SoldHomeForm
          handleOpenClose={handleOpenClose}
          handleState={handleState}
          handleSubmitForm={handleSubmitForm}
        />
      )}
    </Container>
  );
}
