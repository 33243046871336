/** @format */

import CrmListtingHeader from "./MlsListtingHeader/MlsListtingHeader";

import CrmDetailCard from "./CrmDetailCard/MlsDetailCard";

// import './CrmListting.css';

import { useDashboardMlsContext } from "../../../../../context/DashboardMlsListing.context";
import Spinner from "../../../../../utils/spinner/spinner.component";
import MlsForm from "./CrmEdit/mlsForm";
import { MainContainer, MlsFormContainer } from "./MlsListing.style";
import useListing from "./useListing";

export default function CrmListing() {
  const { loader } = useListing();
  const {
    state: { property, formVisualState },
  } = useDashboardMlsContext();
  return (
    <div className="Container crmListting_container">
      <CrmListtingHeader />
      <MainContainer>
        {property.length > 0 && !loader ? (
          property.map((data, i) => <CrmDetailCard key={i} Data={data} />)
        ) : loader ? (
          <div className="center_box">
            <Spinner />
          </div>
        ) : (
          <div className="center_box">
            <p>no mls listing are there</p>
          </div>
        )}
      </MainContainer>
      {formVisualState && (
        <MlsFormContainer>
          <MlsForm />
        </MlsFormContainer>
      )}
    </div>
  );
}
