import { ReactNode, createContext, useContext, useReducer } from "react";

export type formType = "add" | "update" | null;

export type PropertyProps = {
  _id?: string;
  userId?: string;
  title: string;
  price: string;
  description: string;
  images: string[];
};

export type state = {
  previewProperty: PropertyProps | null;
  ExclusiveListing: [];
  formVisualState: boolean;
  formType: formType;
  previewVisualState: boolean;
  previewId: string;
};

type ContextValue = {
  state: state;
  setProperty: (value: any[]) => void;
  // setExclusiveProperty: (value: []) => void;
  setFormVisualState: (value: boolean) => void;
  setFormType: (value: formType) => void;
  setPreviewProperty: (value: PropertyProps) => void;
  setPreivewVisualState: (value: boolean) => void;
  setPreviewId: (value: string) => void;
};

const initialState: state = {
  previewProperty: null,
  ExclusiveListing: [],
  formVisualState: false,
  formType: null,
  previewVisualState: false,
  previewId: null,
};

type Action =
  | {
      type: "set_property";
      payload: {
        value: [];
      };
    }
  | {
      type: "set_exclusiveProperty";
      payload: { value: PropertyProps };
    }
  | {
      type: "set_formvisualState";
      payload: { value: boolean };
    }
  | {
      type: "set_formType";
      payload: { value: formType };
    }
  | {
      type: "set_previewview";
      payload: {
        value: boolean;
      };
    }
  | {
      type: "set_preview_id";
      payload: {
        value: string;
      };
    };

const DashboardExclusiveReducer = (state: state, action: Action) => {
  switch (action.type) {
    case "set_property":
      return { ...state, ExclusiveListing: action.payload.value };
    case "set_exclusiveProperty":
      return { ...state, previewProperty: action.payload.value };
    case "set_formType":
      return { ...state, formType: action.payload.value };
    case "set_formvisualState":
      return { ...state, formVisualState: action.payload.value };
    case "set_previewview":
      return { ...state, previewVisualState: action.payload.value };
    case "set_preview_id":
      return { ...state, previewId: action.payload.value };
  }
};

const DashboardExclusiveListingContext = createContext<
  ContextValue | undefined
>(undefined);

interface DashboardContextProviderProps {
  children: ReactNode;
}

const DashboardExclusiveContextProvider = ({
  children,
}: DashboardContextProviderProps) => {
  const [state, dispatch] = useReducer(DashboardExclusiveReducer, initialState);

  const setProperty = (value: []) => {
    dispatch({ type: "set_property", payload: { value } });
  };

  const setPreviewProperty = (value: PropertyProps) => {
    dispatch({ type: "set_exclusiveProperty", payload: { value } });
  };

  const setFormType = (value: formType) => {
    dispatch({ type: "set_formType", payload: { value } });
  };

  const setFormVisualState = (value: boolean) => {
    dispatch({ type: "set_formvisualState", payload: { value } });
  };
  const setPreivewVisualState = (value: boolean) => {
    dispatch({ type: "set_previewview", payload: { value } });
  };
  const setPreviewId = (value: string) => {
    dispatch({ type: "set_preview_id", payload: { value } });
  };

  const contextValue: ContextValue = {
    state,
    setProperty,
    setPreviewProperty,
    setFormType,
    setFormVisualState,
    setPreviewId,
    setPreivewVisualState,
  };

  return (
    <DashboardExclusiveListingContext.Provider value={contextValue}>
      {children}
    </DashboardExclusiveListingContext.Provider>
  );
};

//customHook

export function useDashboardExclusiveListing() {
  const context = useContext(DashboardExclusiveListingContext);
  if (!context) {
    throw new Error(
      `DashboardExclusiveListingContext must be used in withing a DashboardExclusiveListingContextProvider4`,
    );
  }
  return context;
}

export default DashboardExclusiveContextProvider;
