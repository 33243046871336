import styled from "styled-components";
import { LinkButton } from "../../WebsiteDesigner/Header.style";

export const PreviewWrapper = styled.div`
  width: min(120rem, 100%);
  height: 4rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-radius: none;
  padding-block: 1rem;
  padding-inline: 2rem;
  background: #0f0f11;
  margin: 0px;
  width: 100%;
  padding: 10px 6rem;
`;

export const PreviewButton = styled(LinkButton)``;
