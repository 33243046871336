/** @format */

import styled from 'styled-components';

import { Container } from '../herosectionhome/herosectionhome.style';

type Props = {
  mobileImage: string;
};

export const AboutContainer = styled(Container)``;

// export const AboutContainer = styled.div`
//   font-family: 'Montserrat';
//   width: min(100%, 120rem);
//   min-height: fit-content;
//   max-height: 100%;
//   margin: 0 auto;
//   position: relative;

//   p,
//   h1,
//   button {
//     word-break: break-word;
//     font-family: 'Montserrat';
//   }

//   .HomeContainer {
//     margin-block: 0;
//     margin-inline: auto;
//     padding-block: 2rem;
//     width: 100%;
//     height: 70vh;
//     min-height: 550px;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     gap: 1rem;
//     position: relative;
//     z-index: 1;
//     overflow: hidden;

//     .leftSection {
//       flex: 1;
//       z-index: 2;
//       position: relative;
//       margin-bottom: clamp(1rem, 1.5vw, 1.5rem);
//       padding-left: 2rem;

//       .Text {
//         font-size: clamp(2rem, 3vw, 3rem);
//         font-weight: 900;
//         color: ${(props) => props.theme.colors.text2};
//         text-transform: uppercase;
//         margin: 0;

//         * {
//           font-size: clamp(2rem, 3vw, 3rem);
//           font-weight: 600;
//           color: ${(props) => props.theme.colors.text2};
//           text-transform: uppercase;
//           margin: 0;
//         }
//       }

//       p {
//         font-weight: 400;
//         color: ${(props) => props.theme.colors.text2};
//         font-size: clamp(0.75rem, 1.3vw, 1.3125rem);
//         margin-bottom: clamp(1rem, 1.5vw, 1.5rem);
//         margin-left: 2rem;
//         text-align: left;
//       }

//       h1 {
//         margin-left: 2rem;
//         text-align: left;
//       }

//       button {
//         padding: clamp(0.15rem, 1vw, 0.75rem);
//         display: flex;
//         align-items: center;
//         gap: 1rem;
//         font-weight: 700;
//         font-size: clamp(0.75rem, 1vw, 1.125rem);
//         text-transform: uppercase;
//       }
//     }

//     .rightSection {
//       position: absolute;
//       top: 0;
//       left: 0;
//       width: 100%;
//       height: 100%;
//       z-index: 1;

//       img {
//         width: 100%;
//         height: 100%;
//         object-fit: cover;
//         opacity: 0.8;
//       }
//     }

//    @media screen and (max-width: 991px) {
//       .leftSection {
//         flex: 1;
//         padding-top: 1rem;
//       }
//     }

//     @media screen and (max-width: 689px) {
//       flex-direction: column;
//       align-items: flex-start;
//       padding-inline: 1rem;

//       .leftSection {
//         padding-top: 1rem;
//       }

//       .rightSection {
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: 100%;
//         z-index: 1;

//         img {
//           width: 100%;
//           height: 100%;
//           object-fit: cover;
//         }
//       }
//     }
//   }
// `;
