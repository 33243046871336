import ExclusiveView from "../../../../../utilComponent/ExclusiveListingSlider/ExclusiveView";
import { useDashboardExclusiveListing } from "../../../../../../context/DashboardExclusiveListing";
import {
  PreviewExclusiveContainer,
  PreivewContainer,
} from "./ExclusivePriview.style";
export const ExclusivePriviewCard = () => {
  const {
    state: { previewId },
    setPreivewVisualState,
  } = useDashboardExclusiveListing();

  const handlePreviewClose = () => {
    setPreivewVisualState(false);
  };

  return (
    <PreivewContainer>
      <PreviewExclusiveContainer>
        <div className="header_preview">
          <h3>Preview of Exclusive Property</h3>
          <button onClick={handlePreviewClose}>Close</button>
        </div>
        <ExclusiveView id={previewId} />
      </PreviewExclusiveContainer>
    </PreivewContainer>
  );
};
