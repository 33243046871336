import ExclusiveListingHeading from "./components/ExclusiveListingHeader/ExclusiveListingHeader";
import {
  ExclusiveMainContainer,
  ExclusiveFormContainer,
} from "./ExclusiveListings.style";
import { useDashboardExclusiveListing } from "../../../../context/DashboardExclusiveListing";
import { ExclusiveForm } from "./components/ExclusiveForm/ExclusiveForm";
import useExclusiveListing from "./useExclusive";
import Spinner from "../../../../utils/spinner/spinner.component";
import ExclusivePropertyCard from "./components/ExclusiveCard/ExclusiveCard";
import { ExclusivePriviewCard } from "./components/ExclusivePriview/ExclusivePriview";
const ExclusiveListings = () => {
  const {
    state: { ExclusiveListing, formVisualState, previewVisualState },
  } = useDashboardExclusiveListing();
  const { loader } = useExclusiveListing();
  return (
    <div className="Container">
      <ExclusiveListingHeading />
      <ExclusiveMainContainer>
        {ExclusiveListing.length > 0 && !loader ? (
          ExclusiveListing.map((data, i) => (
            <ExclusivePropertyCard key={i} Data={data} />
          ))
        ) : loader ? (
          <div className="center_box">
            <Spinner />
          </div>
        ) : (
          <div>
            <p>no Exclusive listing are there </p>
          </div>
        )}
      </ExclusiveMainContainer>
      {formVisualState && (
        <ExclusiveFormContainer>
          <ExclusiveForm />
        </ExclusiveFormContainer>
      )}
      {previewVisualState && <ExclusivePriviewCard />}
    </div>
  );
};

export default ExclusiveListings;
