import './PropertyCard1.style.css';
import { useNavigate } from 'react-router-dom';
export default function PropertyCard1({ PropertyData }) {
  const Navigate = useNavigate();
  const HandleNavigation = () => {
    Navigate(`${PropertyData.redirect}/${PropertyData.id}`);
  };

  return (
    <div className="altman-properties-item" data-aos="slide-right" onClick={HandleNavigation}>
      <div className="sub-main">
        <div className="altman-properties-item-inner-wrapper">
          <div className="altman-properties-item-photo">
            <canvas
              width="529"
              height="460"
              className="lazy-load-canvas-element"
              data-lazyload-src={`${PropertyData.img[0]}`}
              style={{
                backgroundImage: `url(${PropertyData.img[0]})`,
              }}
            />
          </div>
          <div className="altman-properties-item-content-status-city ease">
            <div>{PropertyData.sale_or_lease}</div>
            <div>{PropertyData.city}</div>
          </div>
          <div className="altman-properties-item-content ease">
            <div className="altman-properties-item-content-address">
              <h3>{PropertyData.title}</h3>
              <div>{PropertyData.city}</div>
            </div>
            <ul className="altman-properties-item-content-features ease ps-0">
              <li>{PropertyData.bedrooms} BD</li>
              <li>{PropertyData.bathrooms} BH</li>
              <li>{PropertyData.garage} GAR</li>
            </ul>
            <div className="altman-properties-item-content-price ease">
              ${PropertyData.price}
            </div>
          </div>
          <div
            className="altman-properties-item-link-label ease"
            onClick={HandleNavigation}
          >
            View Details
          </div>
        </div>
      </div>
    </div>
  );
}
