import styled from "styled-components";
import {
  DashboardNavContainer,
  DashboardButton,
  SortSection,
} from "../../../../CommonStyle/dashboardNav.style";

export const ExclusiveHeader = styled(DashboardNavContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const AddButton = styled(DashboardButton)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ExclusiveSortSection = styled(SortSection)``;
