import React, { useEffect, useState } from 'react'
import axios from 'axios';
import Cookies from 'js-cookie';
import { FeaturePropertyContainer, PropertyCardSlider } from './FeaturePropertySlider.style';
import { MdOutlineArrowBackIos } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
export type mlsDataProps = {
  _id?: string;
  userId?: string;
  mlsNumber: string;
  address: string;
  price: number | string;
  beedroom: string | number;
  bathroom: string | number;
  garage: string | number;
  image: string;
  createdAt?: string;
  updatedAt?: string;
};
interface ArrowProps {
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

// Custom Next Arrow Component
const NextArrow: React.FC<ArrowProps> = ({ className, style, onClick }) => {
  return (
    <div
      className={className}
      style={{ ...style, display: "block", right: "40px", zIndex: 1 }}
      onClick={onClick}
    />
  );
};

// Custom Prev Arrow Component
const PrevArrow: React.FC<ArrowProps> = ({ className, style, onClick }) => {
  return (
    <div
      className={className}
      style={{ ...style, display: "block", left: "20px", zIndex: 1 }}
      onClick={onClick}
    />
  );
};
export default function FeaturePropertySlider() {
  const [Property, setProperty] = useState<mlsDataProps[]>([])
  const navigate = useNavigate();

  const Redirect = (mlsNumber) => {
    navigate(`../idxlisting/mls/idxView/${mlsNumber}`)
  }
  useEffect(() => {
    const source = axios.CancelToken.source();
    const GetMlsListing = async (): Promise<void> => {
      const token = Cookies.get('token');
      try {
        const GetData = await axios.get('/api/get-feature-properties', {
          cancelToken: source.token,
          headers: {
            'x-access-token': token,
          },
        });
        setProperty(GetData.data.data);
      } catch (err) {
        throw new Error(err);
      } finally {
      }
    };
    GetMlsListing();
    return () => {
      source.cancel('Api call got canceled by cleanup.');
    };
  }, []);
  const CardSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,
  };
  return (
    <FeaturePropertyContainer>
      <Slider {...CardSettings} className='slider_container'>
        {Property && Property.map((data) => (
          <PropertyCardSlider key={data._id}>
            <img src={data.image} alt="property_image" className='image' />
            <div className="property_details">
              <h6 className="heading">
                FEATURED LISTING
              </h6>
              <h2 className="second_heading">
                {data.address}
              </h2>
              <div className="property_info_container">
                <p className='info_property'>${data.price}</p>
                <p className='info_property'>{data.beedroom} Beds</p>
                <p className='info_property'>{data.bathroom} Baths</p>
              </div>
            </div>
            <div className="redirect" onClick={() => Redirect(data.mlsNumber)}>
              LEARN MORE
            </div>
          </PropertyCardSlider>
        ))}
      </Slider>
    </FeaturePropertyContainer>
  )
}
