/** @format */

import { useEffect, useState } from 'react';
import {
  Amenities,
  Appoinment,
  AppointmentContainer,
  Container,
  Detail,
  HomeValue,
  LeftContainer,
  Map,
  OverView,
  PropertyDetails,
  RightContainer,
} from './Details.style';
import BulletPoints from './Images/BulletsPoints.svg';
import Banks from './Images/fluent-emoji_bank.svg';
import BusStand from './Images/fluent-emoji_bus-stop.svg';
import Parks from './Images/fluent-emoji_evergreen-tree.svg';
import Attractions from './Images/fluent-emoji_ferris-wheel.svg';
import House from './Images/fluent-emoji_house-with-garden.svg';
import Hospital from './Images/fluent-emoji_medical-symbol.svg';
import Cinemas from './Images/fluent-emoji_movie-camera.svg';
import Food from './Images/fluent-emoji_pot-of-food.svg';
import Shopping from './Images/fluent-emoji_shopping-bags.svg';
import Sunset from './Images/fluent-emoji_sunset.svg';
// import VideoPlayer from '../VideoPlayer/VideoPlayer.compoent';
import { useParams } from 'react-router-dom';
import { ConvertTime } from '../../../utils/timezoneconvertor';
import FeatureFacts from './Components/Feature&Facts/Feature_Facts.component';
import NearBySchool from './Components/NearBySchool/NearBySchool.component';

import { useMessageContext } from '../../../context/Message.context';
import AppointmentForm from './Components/appointment/Appointment.component';
import moment from 'moment';
export default function Details({ Data }) {
  const [readMore, setReadMore] = useState(true);
  const [appointment, setAppointment] = useState(false);
  const [lowPrice, setLowPrice] = useState(null);
  const [highPrice, setHighPrice] = useState(null);
  const [redBuyersPrice, setRedBuyersPrice] = useState(null);
  const { type } = useParams();
  const {
    state: { SuccessShow, ErrorShow },
  } = useMessageContext();
  const handleRedmore = () => {
    setReadMore(!readMore);
  };
  function Rounded(num) {
    return (Math.round(num * 100) / 100).toFixed();
  }
  const convertToValue = (num) => {
    const RoundedValue = Rounded(num);
    return RoundedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  useEffect(() => {
    const property_price_home_value = () => {
      if (Data?.list_price) {
        let real_value = Data?.list_price;
        let home_value;
        const price_discount = Math.floor(Math.random() * 5) + 1;
        const price_symbol_array = ['+', '-'];
        const price_symbol =
          price_symbol_array[
          Math.floor(Math.random() * price_symbol_array.length)
          ];
        const discounted_amount = (price_discount / 100) * real_value;
        if (price_symbol === '-') {
          home_value = real_value - discounted_amount;
        } else {
          home_value = real_value + discounted_amount;
        }
        let round = (Math.round(home_value * 100) / 100).toFixed();
        // lease block
        if (Data?.sale_or_lease?.toLowerCase() === 'lease') {
          if (real_value >= 15000) {
            const price_discount_new = Math.floor(Math.random() * 7) + 1;
            const discounted_amount_new =
              (price_discount_new / 100) * real_value;
            setLowPrice(home_value - discounted_amount_new);
            setHighPrice(home_value + discounted_amount_new);
          } else {
            const price_discount_new = Math.floor(Math.random() * 5) + 1;
            const discounted_amount_new =
              (price_discount_new / 100) * real_value;
            setLowPrice(home_value - discounted_amount_new);
            setHighPrice(home_value + discounted_amount_new);
          }
        } else {
          // Sale block
          if (real_value >= 1800000) {
            setLowPrice(home_value - 150000);
            setHighPrice(home_value + 150000);
          } else {
            setLowPrice(home_value - 100000);
            setHighPrice(home_value + 100000);
          }
        }
        setRedBuyersPrice(
          round.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        );
      }
    };
    property_price_home_value();
  }, [Data?.list_price]);
  useEffect(() => {
    setAppointment(false);
  }, [SuccessShow, ErrorShow]);
  const SoldInDays = (listedDay, soldDay) => {
    if (!listedDay || !soldDay) {
      return null;
    }
    let start = moment(listedDay);
    let end = moment(soldDay);

    return end.diff(start, 'days');
  };
  return (
    <>
      <Container className='container'>
        <LeftContainer>
          <OverView
            id="Overview"
            Check={readMore}>
            {/* <h1 className="cusfont">{`Why ${Data?.address},${Data?.city} ?`}</h1> */}
            <h1 className="cusfont">{`Property Highlights`}</h1>
            <div className="InfoContainer">
              <div className="Info">
                <h1><svg xmlns="http://www.w3.org/2000/svg" className="mb-1" width="18" height="18" viewBox="0 0 576 512"><path d="M575.8 255.5c0 18-15 32.1-32 32.1l-32 0 .7 160.2c0 2.7-.2 5.4-.5 8.1l0 16.2c0 22.1-17.9 40-40 40l-16 0c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1L416 512l-24 0c-22.1 0-40-17.9-40-40l0-24 0-64c0-17.7-14.3-32-32-32l-64 0c-17.7 0-32 14.3-32 32l0 64 0 24c0 22.1-17.9 40-40 40l-24 0-31.9 0c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2l-16 0c-22.1 0-40-17.9-40-40l0-112c0-.9 0-1.9 .1-2.8l0-69.7-32 0c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z" /></svg> Type</h1>
                <h3>
                  {Data?.property_type === null
                    ? 'No data'
                    : Data?.property_type}
                </h3>
              </div>
              <div className="Info">
                <h1><svg xmlns="http://www.w3.org/2000/svg" className="mb-1" width="18" height="18" viewBox="0 0 640 512"><path d="M320.7 352c8.1-89.7 83.5-160 175.3-160c8.9 0 17.6 .7 26.1 1.9L309.5 7c-6-5-14-7-21-7s-15 1-22 8L10 231.5c-7 7-10 15-10 24c0 18 14 32.1 32 32.1l32 0 0 69.7c-.1 .9-.1 1.8-.1 2.8l0 112c0 22.1 17.9 40 40 40l16 0c1.2 0 2.4-.1 3.6-.2c1.5 .1 3 .2 4.5 .2l31.9 0 24 0c22.1 0 40-17.9 40-40l0-24 0-64c0-17.7 14.3-32 32-32l64 0 .7 0zM496 512a144 144 0 1 0 0-288 144 144 0 1 0 0 288zm0-96a24 24 0 1 1 0 48 24 24 0 1 1 0-48zm0-144c8.8 0 16 7.2 16 16l0 80c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-80c0-8.8 7.2-16 16-16z" /></svg> Style</h1>
                <h3>
                  {Data.propertyDetails.find((data) => data.name === "Building Type").value || "***"}
                </h3>
              </div>
              <div className="Info">
                <h1><svg xmlns="http://www.w3.org/2000/svg" className="mb-1" width="18" height="18" viewBox="0 0 640 512"><path d="M32 32c17.7 0 32 14.3 32 32l0 256 224 0 0-160c0-17.7 14.3-32 32-32l224 0c53 0 96 43 96 96l0 224c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-32-224 0-32 0L64 416l0 32c0 17.7-14.3 32-32 32s-32-14.3-32-32L0 64C0 46.3 14.3 32 32 32zm144 96a80 80 0 1 1 0 160 80 80 0 1 1 0-160z" /></svg> Bedroom</h1>
                <h3>
                  {' '}
                  {Data?.bedrooms === null
                    ? 0
                    : Data?.bedrooms > Number(Data?.additional_bathroom)
                      ? Data?.bedrooms - Number(Data?.additional_bathroom)
                      : Data?.bedrooms}
                </h3>
              </div>
              <div className="Info">
                <h1><svg xmlns="http://www.w3.org/2000/svg" className="mb-1" width="18" height="18" viewBox="0 0 512 512"><path d="M96 77.3c0-7.3 5.9-13.3 13.3-13.3c3.5 0 6.9 1.4 9.4 3.9l14.9 14.9C130 91.8 128 101.7 128 112c0 19.9 7.2 38 19.2 52c-5.3 9.2-4 21.1 3.8 29c9.4 9.4 24.6 9.4 33.9 0L289 89c9.4-9.4 9.4-24.6 0-33.9c-7.9-7.9-19.8-9.1-29-3.8C246 39.2 227.9 32 208 32c-10.3 0-20.2 2-29.2 5.5L163.9 22.6C149.4 8.1 129.7 0 109.3 0C66.6 0 32 34.6 32 77.3L32 256c-17.7 0-32 14.3-32 32s14.3 32 32 32l448 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 256 96 77.3zM32 352l0 16c0 28.4 12.4 54 32 71.6L64 480c0 17.7 14.3 32 32 32s32-14.3 32-32l0-16 256 0 0 16c0 17.7 14.3 32 32 32s32-14.3 32-32l0-40.4c19.6-17.6 32-43.1 32-71.6l0-16L32 352z" /></svg> Bathroom</h1>
                <h3>{!Data?.bathrooms ? 0 : Data?.bathrooms}</h3>
              </div>
              <div className="Info">
                <h1><svg xmlns="http://www.w3.org/2000/svg" className="mb-1" width="18" height="18" viewBox="0 0 512 512"><path d="M.2 468.9C2.7 493.1 23.1 512 48 512l96 0 320 0c26.5 0 48-21.5 48-48l0-96c0-26.5-21.5-48-48-48l-48 0 0 80c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-80-64 0 0 80c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-80-64 0 0 80c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-80-80 0c-8.8 0-16-7.2-16-16s7.2-16 16-16l80 0 0-64-80 0c-8.8 0-16-7.2-16-16s7.2-16 16-16l80 0 0-64-80 0c-8.8 0-16-7.2-16-16s7.2-16 16-16l80 0 0-48c0-26.5-21.5-48-48-48L48 0C21.5 0 0 21.5 0 48L0 368l0 96c0 1.7 .1 3.3 .2 4.9z" /></svg> Square Feet</h1>
                <h3>
                  {type === 'sold' ? (
                    <>
                      <h3>
                        {JSON.parse(Data?.property_details).square_footage ===
                          '' ||
                          !JSON.parse(Data?.property_details).square_footage
                          ? 'No Data'
                          : !JSON.parse(Data?.property_details).square_footage}
                      </h3>
                    </>
                  ) : (
                    <>
                      <h3>
                        {Data?.property_details.Sqft === '' ||
                          !Data?.property_details.sqft
                          ? 'No Data'
                          : Data?.property_details.Sqft}
                      </h3>
                    </>
                  )}
                </h3>
              </div>
              <div className="Info">
                <h1><svg xmlns="http://www.w3.org/2000/svg" className="mb-1" width="18" height="18" viewBox="0 0 640 512"><path d="M0 488L0 171.3c0-26.2 15.9-49.7 40.2-59.4L308.1 4.8c7.6-3.1 16.1-3.1 23.8 0L599.8 111.9c24.3 9.7 40.2 33.3 40.2 59.4L640 488c0 13.3-10.7 24-24 24l-48 0c-13.3 0-24-10.7-24-24l0-264c0-17.7-14.3-32-32-32l-384 0c-17.7 0-32 14.3-32 32l0 264c0 13.3-10.7 24-24 24l-48 0c-13.3 0-24-10.7-24-24zm488 24l-336 0c-13.3 0-24-10.7-24-24l0-56 384 0 0 56c0 13.3-10.7 24-24 24zM128 400l0-64 384 0 0 64-384 0zm0-96l0-80 384 0 0 80-384 0z" /></svg> Garage</h1>
                <h3>{!Data?.garage_spaces ? 0 : Data?.garage_spaces}</h3>
              </div>
              <div className="Info">
                <h1><svg xmlns="http://www.w3.org/2000/svg" className="mb-1" width="18" height="18" viewBox="0 0 640 512"><path d="M192 96a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm-8 352l0-96 16 0 0 96-16 0zm-64 0l-88 0c-17.7 0-32 14.3-32 32s14.3 32 32 32l120 0 80 0 376 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-344 0 0-191.1 28.6 47.5c9.1 15.1 28.8 20 43.9 10.9s20-28.8 10.9-43.9l-58.3-97c-17.4-28.9-48.6-46.6-82.3-46.6l-29.7 0c-33.7 0-64.9 17.7-82.3 46.6l-58.3 97c-9.1 15.1-4.2 34.8 10.9 43.9s34.8 4.2 43.9-10.9L120 256.9 120 448zM464 64l0 242.7-25.4-25.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l80 80c12.5 12.5 32.8 12.5 45.3 0l80-80c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L528 306.7 528 64c0-17.7-14.3-32-32-32s-32 14.3-32 32z" /></svg> Basement</h1>
                <h3>{Data.propertyDetails.find((data) => data.name === "Basement").value || "***"}
                </h3>
              </div>
              <div className="Info">
                <h1><svg xmlns="http://www.w3.org/2000/svg" className="mb-1" width="18" height="18" viewBox="0 0 576 512"><path d="M0 48C0 21.5 21.5 0 48 0L336 0c26.5 0 48 21.5 48 48l0 159-42.4 17L304 224l-32 0c-8.8 0-16 7.2-16 16l0 32 0 24.2 0 7.8c0 .9 .1 1.7 .2 2.6c2.3 58.1 24.1 144.8 98.7 201.5c-5.8 2.5-12.2 3.9-18.9 3.9l-96 0 0-80c0-26.5-21.5-48-48-48s-48 21.5-48 48l0 80-96 0c-26.5 0-48-21.5-48-48L0 48zM80 224c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zm80 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zM64 112l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16L80 96c-8.8 0-16 7.2-16 16zM176 96c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zm80 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zM423.1 225.7c5.7-2.3 12.1-2.3 17.8 0l120 48C570 277.4 576 286.2 576 296c0 63.3-25.9 168.8-134.8 214.2c-5.9 2.5-12.6 2.5-18.5 0C313.9 464.8 288 359.3 288 296c0-9.8 6-18.6 15.1-22.3l120-48zM527.4 312L432 273.8l0 187.8c68.2-33 91.5-99 95.4-149.7z" /></svg> Age</h1>
                <h3>
                  {
                    Data?.propertyDetails?.find((data) => data.name === 'Age')
                      .value || "***"
                  }
                </h3>
              </div>
              <div className="Info">
                <h1><svg xmlns="http://www.w3.org/2000/svg" className="mb-1" width="18" height="18" viewBox="0 0 640 512"><path d="M480 0c-17.7 0-32 14.3-32 32l0 160 0 320 64 0 0-320 112 0c8.8 0 16-7.2 16-16l0-128c0-8.8-7.2-16-16-16L512 32c0-17.7-14.3-32-32-32zM416 159L276.8 39.7c-12-10.3-29.7-10.3-41.7 0l-224 192C1 240.4-2.7 254.5 2 267.1S18.6 288 32 288l32 0 0 192c0 17.7 14.3 32 32 32l64 0c17.7 0 32-14.3 32-32l0-96c0-17.7 14.3-32 32-32l64 0c17.7 0 32 14.3 32 32l0 96c0 17.7 14.3 32 32 32l64.7 0 .2 0-1 0 0-353z" /></svg> Frontage</h1>
                <h3>{Data.factsFeatures.filter(feature => feature.name === "HighLights").map(section => section.children.find(child => child.name === "Frontage")?.value)
                  .filter(value => value !== undefined)}
                </h3>
              </div>
              <div className="Info">
                <h1><svg xmlns="http://www.w3.org/2000/svg" className="mb-1" width="18" height="18" viewBox="0 0 512 512"><path d="M512 80c0 18-14.3 34.6-38.4 48c-29.1 16.1-72.5 27.5-122.3 30.9c-3.7-1.8-7.4-3.5-11.3-5C300.6 137.4 248.2 128 192 128c-8.3 0-16.4 .2-24.5 .6l-1.1-.6C142.3 114.6 128 98 128 80c0-44.2 86-80 192-80S512 35.8 512 80zM160.7 161.1c10.2-.7 20.7-1.1 31.3-1.1c62.2 0 117.4 12.3 152.5 31.4C369.3 204.9 384 221.7 384 240c0 4-.7 7.9-2.1 11.7c-4.6 13.2-17 25.3-35 35.5c0 0 0 0 0 0c-.1 .1-.3 .1-.4 .2c0 0 0 0 0 0s0 0 0 0c-.3 .2-.6 .3-.9 .5c-35 19.4-90.8 32-153.6 32c-59.6 0-112.9-11.3-148.2-29.1c-1.9-.9-3.7-1.9-5.5-2.9C14.3 274.6 0 258 0 240c0-34.8 53.4-64.5 128-75.4c10.5-1.5 21.4-2.7 32.7-3.5zM416 240c0-21.9-10.6-39.9-24.1-53.4c28.3-4.4 54.2-11.4 76.2-20.5c16.3-6.8 31.5-15.2 43.9-25.5l0 35.4c0 19.3-16.5 37.1-43.8 50.9c-14.6 7.4-32.4 13.7-52.4 18.5c.1-1.8 .2-3.5 .2-5.3zm-32 96c0 18-14.3 34.6-38.4 48c-1.8 1-3.6 1.9-5.5 2.9C304.9 404.7 251.6 416 192 416c-62.8 0-118.6-12.6-153.6-32C14.3 370.6 0 354 0 336l0-35.4c12.5 10.3 27.6 18.7 43.9 25.5C83.4 342.6 135.8 352 192 352s108.6-9.4 148.1-25.9c7.8-3.2 15.3-6.9 22.4-10.9c6.1-3.4 11.8-7.2 17.2-11.2c1.5-1.1 2.9-2.3 4.3-3.4l0 3.4 0 5.7 0 26.3zm32 0l0-32 0-25.9c19-4.2 36.5-9.5 52.1-16c16.3-6.8 31.5-15.2 43.9-25.5l0 35.4c0 10.5-5 21-14.9 30.9c-16.3 16.3-45 29.7-81.3 38.4c.1-1.7 .2-3.5 .2-5.3zM192 448c56.2 0 108.6-9.4 148.1-25.9c16.3-6.8 31.5-15.2 43.9-25.5l0 35.4c0 44.2-86 80-192 80S0 476.2 0 432l0-35.4c12.5 10.3 27.6 18.7 43.9 25.5C83.4 438.6 135.8 448 192 448z" /></svg> Taxes</h1>
                <h3>{Data.propertyDetails.find((data) => data.name === "Taxes").value || "***"}</h3>
              </div>
              {type === 'sold' && (
                <div className="Info">
                  <h1>Sold On</h1>
                  <h3>{Data?.sold_date?.split(' ')?.[0]}</h3>
                </div>
              )}
              {type === 'sold' && (
                <div className="Info">
                  <h1>Sold In</h1>
                  <h3>
                    {SoldInDays(
                      JSON.parse(Data?.property_details)?.Input_date,
                      JSON.parse(Data?.property_details)?.Unavail_dt
                    )}
                  </h3>
                </div>
              )}
            </div>
            {Data?.property_description && (
              <div
                className="Description"
                dangerouslySetInnerHTML={{
                  __html: Data?.property_description,
                }}></div>
            )}
            {Data?.property_description && (
              <div
                className="Wrapper"
                onClick={handleRedmore}>
                <p className="read_more">Read more about the property</p>

                <svg
                  className="SvgWrapper"
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="5"
                  viewBox="0 0 10 5"
                  fill="none">
                  <path
                    d="M0 0L5 5L10 0H0Z"
                    fill="white"
                  />
                </svg>
              </div>
            )}
          </OverView>
          <Map id="Map">
            <h1 className="cusfont">Explore Neighborhood - Map View</h1>
            <div className="ContainerMap">
              <div className="leftSection">
                <iframe
                  title="Map"
                  src={`https://maps.google.com/maps?q= ${Data?.address} &hl=en&z=15&output=embed&`}
                  allowfullscreen="allowfullscreen"
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                  style={{ border: '0px' }}></iframe>
              </div>
              <div className="rightSection">
                <div className="BulletPoints">
                  <div className="wrapper">
                    <img
                      src={BusStand}
                      alt=""
                    />
                  </div>
                  <p>Commute</p>
                </div>
                <div className="BulletPoints">
                  <div className="wrapper">
                    <img
                      src={Banks}
                      alt=""
                    />
                  </div>
                  <p>Banks</p>
                </div>
                <div className="BulletPoints">
                  <div className="wrapper">
                    <img
                      src={Cinemas}
                      alt=""
                    />
                  </div>
                  <p>Cinemas</p>
                </div>
                <div className="BulletPoints">
                  <div className="wrapper">
                    <img
                      src={Parks}
                      alt=""
                    />
                  </div>
                  <p>Parks</p>
                </div>
                <div className="BulletPoints">
                  <div className="wrapper">
                    <img
                      src={Shopping}
                      alt=""
                    />
                  </div>
                  <p>Shopping</p>
                </div>
                <div className="BulletPoints">
                  <div className="wrapper">
                    <img
                      src={Food}
                      alt=""
                    />
                  </div>
                  <p>Food</p>
                </div>
                <div className="BulletPoints">
                  <div className="wrapper">
                    <img
                      src={Hospital}
                      alt=""
                    />
                  </div>
                  <p>Hospitals</p>
                </div>
                <div className="BulletPoints">
                  <div className="wrapper">
                    <img
                      src={Attractions}
                      alt=""
                    />
                  </div>
                  <p>Attractions</p>
                </div>
              </div>
            </div>
          </Map>
          {Data?.property?.amenities && (
            <Amenities>
              <h1 className="cusfont">Amenities</h1>
              <div className="Container">
                {Data?.property?.amenities?.map((data, i) => (
                  <div
                    className="Amenities"
                    key={i}>
                    <div className="wrapper">
                      <img
                        src={Sunset}
                        alt=""
                      />
                    </div>
                    <p>{data}</p>
                  </div>
                ))}
              </div>
            </Amenities>
          )}
          {Data?.nearby_school?.length && (
            <NearBySchool Data={Data?.nearby_school} />
          )}
          <PropertyDetails id="Property_Details">
            <h1 className="cusfont mb-3">Property Details</h1>
            <div className="DetailsContainer row">
              {Data?.propertyDetails?.map((data) => (
                <Detail key={data.name} className='col-md-6'>
                  <div className="Wrapper">
                    <img
                      src={BulletPoints}
                      alt="BulletPoints"
                    />
                  </div>
                  <p>
                    {' '}
                    {data.name} :{' '}
                    <span>
                      {' '}
                      {Data?.name === 'Added'
                        ? ConvertTime(data?.value)
                        : data?.value}
                    </span>
                  </p>
                </Detail>
              ))}
            </div>
          </PropertyDetails>
          {/* //feature */}
          <FeatureFacts Data={Data?.factsFeatures} />
          {/* homeValuation  */}
          {type !== 'sold' && (
            <HomeValue
              id="estimated-section"
              className="border-bottom-0 pb-0">
              <div className="estimated-card">
                <h3 className="cusfont">Your Estimated Home Value</h3>
                <div className="estimated-card-header">
                  <div className="estimated-card-header">
                    <div className="estimated-property-details">
                      <div className="d-info">
                        <svg
                          width="12"
                          height="16"
                          viewBox="0 0 12 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M6.00006 0.5C2.69181 0.5 6.10835e-05 3.19175 6.10835e-05 6.49625C-0.0216889 11.33 5.77206 15.338 6.00006 15.5C6.00006 15.5 12.0218 11.33 12.0001 6.5C12.0001 3.19175 9.30831 0.5 6.00006 0.5ZM6.00006 9.5C4.34256 9.5 3.00006 8.1575 3.00006 6.5C3.00006 4.8425 4.34256 3.5 6.00006 3.5C7.65756 3.5 9.00006 4.8425 9.00006 6.5C9.00006 8.1575 7.65756 9.5 6.00006 9.5Z"
                            fill="black"
                          />
                        </svg>
                        <span>{Data?.address}</span>
                      </div>
                      <div className="d-info">
                        <svg
                          width="28"
                          height="19"
                          viewBox="0 0 28 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M22.75 2.75H15.75C14.0931 2.75 12.75 4.09315 12.75 5.75V8.5C12.75 10.1569 11.4069 11.5 9.75 11.5H5.75C4.09315 11.5 2.75 10.1569 2.75 8.5V1.5C2.75 0.809644 2.19036 0.25 1.5 0.25C0.809644 0.25 0.25 0.809644 0.25 1.5V17.75C0.25 18.4404 0.809644 19 1.5 19C2.19036 19 2.75 18.4404 2.75 17.75V17.125C2.75 16.0895 3.58947 15.25 4.625 15.25H23.375C24.4105 15.25 25.25 16.0895 25.25 17.125V17.75C25.25 18.4404 25.8096 19 26.5 19C27.1904 19 27.75 18.4404 27.75 17.75V7.75C27.75 6.42392 27.2232 5.15215 26.2855 4.21447C25.3478 3.27678 24.0761 2.75 22.75 2.75ZM7.75 10.25C8.74456 10.25 9.69839 9.85491 10.4016 9.15165C11.1049 8.44839 11.5 7.49456 11.5 6.5C11.5 5.50544 11.1049 4.55161 10.4016 3.84835C9.69839 3.14509 8.74456 2.75 7.75 2.75C6.75544 2.75 5.80161 3.14509 5.09835 3.84835C4.39509 4.55161 4 5.50544 4 6.5C4 7.49456 4.39509 8.44839 5.09835 9.15165C5.80161 9.85491 6.75544 10.25 7.75 10.25Z"
                            fill="#323232"
                          />
                        </svg>

                        <span>{Data?.bedrooms} Bed</span>
                      </div>
                      <div className="d-info">
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M26.875 17.5C26.5298 17.5 26.25 17.7798 26.25 18.125V18.75C26.25 20.858 25.2073 22.7224 23.6037 23.8528C23.228 24.1176 23.0161 24.5757 23.128 25.0215L23.4379 26.2566C23.5964 26.888 23.119 27.5 22.468 27.5H22.0308C21.5719 27.5 21.1719 27.1877 21.0606 26.7425L20.8144 25.7575C20.7031 25.3123 20.3031 25 19.8442 25H10.1558C9.69691 25 9.29693 25.3123 9.18563 25.7575L8.93937 26.7425C8.82807 27.1877 8.42809 27.5 7.96922 27.5H7.53198C6.88103 27.5 6.4036 26.888 6.56205 26.2566L6.87183 25.0222C6.98381 24.576 6.77154 24.1178 6.39536 23.853C5.68775 23.3548 5.08916 22.713 4.64049 21.9664C4.05685 20.9951 3.74898 19.8831 3.75 18.75V18.125C3.75 17.7798 3.47018 17.5 3.125 17.5C2.77982 17.5 2.5 17.2202 2.5 16.875V16C2.5 15.4477 2.94772 15 3.5 15H24C24.5523 15 25 14.5523 25 14V6.25C25 5.91848 24.8683 5.60054 24.6339 5.36612C24.3995 5.1317 24.0815 5 23.75 5C23.3556 5 23.0209 5.16924 22.79 5.43548C22.5381 5.72585 22.6957 6.13914 22.9385 6.43712C23.2529 6.82283 23.4847 7.27331 23.6182 7.76168C23.7639 8.2944 23.3023 8.75 22.75 8.75H17.25C16.6977 8.75 16.237 8.29454 16.3825 7.76178C16.5529 7.13817 16.8832 6.56346 17.3483 6.09835C18.0516 5.39509 19.0054 5 20 5C20.1274 5 20.2398 4.91917 20.289 4.80173C20.8537 3.45526 22.1881 2.5 23.75 2.5C24.7446 2.5 25.6984 2.89509 26.4017 3.59835C27.1049 4.30161 27.5 5.25544 27.5 6.25V16.875C27.5 17.2202 27.2202 17.5 26.875 17.5Z"
                            fill="#323232"
                          />
                        </svg>
                        <span>{Data?.bathrooms} Bath</span>
                      </div>
                      <div className="d-info">
                        <svg
                          width="18"
                          height="19"
                          viewBox="0 0 18 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.75 18C17.75 18.5523 17.3023 19 16.75 19H16.25C15.6977 19 15.25 18.5523 15.25 18V8.75C15.25 8.19772 14.8023 7.75 14.25 7.75H3.75C3.19772 7.75 2.75 8.19772 2.75 8.75V18C2.75 18.5523 2.30228 19 1.75 19H1.25C0.697715 19 0.25 18.5523 0.25 18V5.83032C0.25 5.47147 0.442286 5.14012 0.753861 4.96208L8.50386 0.533508C8.81129 0.357832 9.18871 0.357832 9.49614 0.533508L17.2461 4.96208C17.5577 5.14012 17.75 5.47147 17.75 5.83032V18ZM4 10C4 9.44771 4.44772 9 5 9H13C13.5523 9 14 9.44771 14 10V10.5C14 11.0523 13.5523 11.5 13 11.5H5C4.44772 11.5 4 11.0523 4 10.5V10ZM4 13.75C4 13.1977 4.44772 12.75 5 12.75H13C13.5523 12.75 14 13.1977 14 13.75V14.25C14 14.8023 13.5523 15.25 13 15.25H5C4.44772 15.25 4 14.8023 4 14.25V13.75ZM13 16.5C13.5523 16.5 14 16.9477 14 17.5V18C14 18.5523 13.5523 19 13 19H5C4.44772 19 4 18.5523 4 18V17.5C4 16.9477 4.44772 16.5 5 16.5H13Z"
                            fill="#323232"
                          />
                        </svg>

                        <span> {Data?.garage_spaces} Garages</span>
                      </div>
                    </div>
                    <div className="redbuyers_info">
                      <small>Redbuyer’s Estimate:</small>
                      <p class="font-weight-bold ">${redBuyersPrice}</p>
                    </div>
                  </div>
                  <div className="sale_range">
                    <small>Estimated Sales Range:</small>
                    <p class="font-weight-bold">
                      ${convertToValue(lowPrice)}-${convertToValue(highPrice)}
                    </p>
                  </div>
                </div>
                <div class="estimated-card-body mr-lg-5">
                  <div class="estimated-slider-wrapper">
                    <div class="slider-bar">
                      <div class="slider-price slider-price--left">
                        ${convertToValue(lowPrice)}
                      </div>
                      <div class="slider-price slider-price--center">
                        ${redBuyersPrice}
                      </div>
                      <div class="slider-price slider-price--right">
                        ${convertToValue(highPrice)}
                      </div>
                      <div class="slider-bar-ball center"></div>
                    </div>
                  </div>
                </div>
                <div class="estimated-card-footer">
                  <p class="mb-2">
                    *Approximate value based on Comparable homes in the area.
                  </p>
                  {/* <p class="mb-0">
                    **An accurate cash offer might be different from the
                    estimate shown above and exact offer will be provided after
                    viewing the property
                  </p> */}
                </div>
              </div>
            </HomeValue>
          )}
        </LeftContainer>
        <RightContainer>
          {/* <Appoinment onClick={() => setAppointment(!appointment)}>
            <div className="animation">

            </div>
            <div className="contents w-100" onClick={() => setAppointment(!appointment)}>
              <div className="d-flex">
                <div
                  className="me-2">
                  <img src={House} alt="House" style={{ maxWidth: '50px' }}/>
                </div>
                <div>
                  <strong>Mr.loremipsum</strong>
                  <p className="m-0">company name</p>
                </div>
              </div>
              <div className="col-12">
                <table className="w-100 mt-2">
                  <tr>
                    <th>Email</th>
                    <td className="text-end">loremipsum@gmail.com</td>
                  </tr>
                  <tr>
                    <th>Mobile</th>
                    <td className="text-end">+1-0000000000</td>
                  </tr>
                </table>
                <button class="btn btn-outline-dark w-100 mt-2">Contact Agent</button>
              </div>
            </div>
          </Appoinment> */}
        </RightContainer>
      </Container>
      {appointment && (
        <AppointmentContainer onClick={() => setAppointment(!appointment)}>
          <AppointmentForm Data={Data} />
        </AppointmentContainer>
      )}
    </>
  );
}
