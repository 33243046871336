import { styled } from "styled-components";

export const AgentEditForm = styled.form`
  .agent_button{
   background: var(--Active-Black, #232325);
   color: white;
   padding-inline:0.30rem;
   padding-block:0.40rem;
   border-radius:0.20rem;
   border:1px solid white;
  }
  .agent_edit_header{
    display:flex;
    justify-content:space-between;
  }
 .input_section_container{
  display:flex;
  flex-direction:column;
 }
.agent_action_button{
  margin-top: 1rem;
  display:flex;
  justify-content:space-between;
  margin-bottom:0.40rem;
 }
`
export const AgentMainContainer = styled.div`
 width:min(65%,56rem);
  border-radius: 0.5rem;
  background: var(--Active-Black, #232325);
  color: white;
  padding: 0.5rem 0.3125rem 0.5rem 0.625rem;
  border: none;
  outline: none;

 .agent_button{
   background: var(--Active-Black, #232325);
   color: white;
   padding-inline:0.30rem;
   padding-block:0.40rem;
   border-radius:0.20rem;
   border:1px solid white; 
  }

 .agent_edit_header{
    display:flex;
    justify-content:space-between;
 }

`
