/** @format */

import styled from 'styled-components';

export const MainContainer = styled.div`
  height: 75vh;

  .image_container_hero_section {
    height: 100%;
    position: relative;
    .heading {
      font-family: 'Syncopate';
      font-size: clamp(1rem, 2rem, 2vw);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

type FlexContainerProps = {
  $flexDirection?: string;
};

export const FlexContainer = styled.div<FlexContainerProps>`
  display: flex;
  gap: 1rem;
  margin: 3rem auto;
  flex-wrap: wrap;
  flex-direction: ${({ $flexDirection }) => $flexDirection || 'row'};
  padding: 1rem;
  .left_section {
    flex: 0.5;
    height: 100%;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .right_section {
    flex: 0.5;
  }
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;
