/** @format */

import { useEffect, useState } from 'react';
import {
  BuilderDealsPropertyContainer,
  BuilderImageNotFoundContainer,
  BuilderLoadingContainer,
  BuilderMatchContainer,
  BuilderMatchSearchContainer,
} from './builderdeals.style';

import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { useFilterContext } from '../../../context/filter.context';
import Citysearch from '../../utilComponent/CitySearch/citysearch.component';
import FilterProperty from '../../utilComponent/FilterBox/filter.component';

//images
import NotFound from '../../../images/No result.gif';
import ConvertToCanadianNumber from '../../../utils/New_construction_details/ConvertNumberToDollar.util';
import { PropertySetImageBanner } from '../../../utils/New_construction_details/propertyCardSetImage';
import PropertyCard, {
  PROPERTY_TYPE_CLASSES,
} from '../PropertyCard/PropertyCard.utilComponent';
import { TimeLabel } from '../../../utils/New_construction_details/timelabel';
import BuilderCard from './components/builderCard/builderCard.list';
type propertyProps = {
  id: number;
  photos: any[];
  list_price: number;
  address: string;
  county: string;
  zip_code: string;
  city: string;
  realtor: string;
  bedroom: string;
  bathroom: string;
  sale_or_lease: string;
  area: string;
  redirect: string;
  garage: string;
  street_address: string;
  property_name: string;
  sqft_area: string;
  updated_at: string;
  plan_properties: [
    {
      building_status: string;
      builder_name: string;
      property_name: string;
    },
  ];
};
type BuilderDealsProps = {
  cardType?: PROPERTY_TYPE_CLASSES;
};
export default function Builderdeals({ cardType }: BuilderDealsProps) {
  const [currentPage, setCurrentPage] = useState(1);
  const [cities, setCities] = useState(null);
  const [lastPage, setLastPage] = useState();
  const { state } = useFilterContext();
  const handlePageChange = (pageIndex: { selected: number }) => {
    const UpdatedValue = pageIndex.selected + 1;
    setCurrentPage(UpdatedValue);
    window.scrollTo(0, 0);
  };
  //templateData
  const {
    city,
    page,
    bathroom,
    bedroom,
    building_status,
    home_type,
    min_price,
    max_price,
    search,
    sortBy,
    sortDir,
    property_type,
  } = state;
  const [property, setProperty] = useState(null);
  const [loader, setLoader] = useState('loading');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const source = axios.CancelToken.source();
    const fetchData = async () => {
      setLoader('loading');
      setProperty(null);
      try {
        const response = await axios.get(
          `https://www.redbuyers.com/api/new-construction`,
          {
            cancelToken: source.token,
            params: {
              ...(min_price && { min_price }),
              ...(max_price && { max_price }),
              ...(home_type && { home_type }),
              ...(bedroom && { bedroom }),
              ...(bathroom && { bathroom }),
              ...(sortBy && { sortBy }),
              ...(sortDir && { sortDir }),
              ...(property_type && { property_type }),
              ...(currentPage && { page: currentPage }),
              // ...(search && { search }),
              city: search,
            },
          },
        );
        const cities = response.data.data.cities;
        const property = response.data.data.property.data;
        const link = response.data.data.property.last_page;
        setCities(cities);
        setLastPage(link);
        // if (property.length > 0) {
        //     setProperty(property);
        // }
        setProperty(
          property.map((item: propertyProps) => ({
            id: item.id,
            img: PropertySetImageBanner(item),
            price: ConvertToCanadianNumber(item.list_price),
            title: `${item.street_address}`,
            bedrooms: item.bedroom,
            bathrooms: item.bathroom,
            garage: item.garage,
            sale_or_lease: item.plan_properties[0].building_status || 'New',
            plan: item.property_name,
            company: item.plan_properties[0].builder_name,
            parent_property: item.plan_properties[0].property_name,
            property_sqft: item.sqft_area,
            timeLabel: TimeLabel(item.updated_at),
            redirect: 'builder_view',
          })),
        );
        setLoader('idle');
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Fetching data with axios was cancelled');
        } else {
          throw error;
        }
      }
    };
    fetchData();
    return () => {
      source.cancel('Operation canceled by cleanup.');
    };
  }, [
    page,
    city,
    bedroom,
    bathroom,
    building_status,
    home_type,
    min_price,
    max_price,
    search,
    sortDir,
    sortBy,
    currentPage,
    property_type,
  ]);

  return (
    <BuilderMatchContainer>
      <BuilderMatchSearchContainer>
        <Citysearch cities={cities} types={'builder_deals'} />
        <FilterProperty types='builder_deals' />
      </BuilderMatchSearchContainer>
      {loader === 'loading' && <BuilderLoadingContainer />}
      {loader === 'idle' &&
        (property !== null ? (
          <BuilderDealsPropertyContainer>
            {property?.map((data, i) => (
              // <BuilderdealsPropertyCard
              //     key={data.id}
              //     Data={data}
              // />
              <BuilderCard PropertyData={data} />
            ))}
          </BuilderDealsPropertyContainer>
        ) : (
          <BuilderImageNotFoundContainer>
            <img src={NotFound} alt='notfound' />
          </BuilderImageNotFoundContainer>
        ))}
      <ReactPaginate
        containerClassName='peginate_class'
        pageClassName='page'
        onPageChange={handlePageChange}
        forcePage={currentPage - 1}
        pageCount={lastPage}
      />
    </BuilderMatchContainer>
  );
}
