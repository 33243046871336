/** @format */

import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 2rem;
  display: grid;
  // grid-template-columns: 1fr minmax(0, 30%);
  gap: 1.5rem;
  .cusfont {
    font-family: Tenor Sans;
   }
  // h1 .cusfont{
  //   font-size: calc(1rem + 1.5vw);
  // }
  @media screen and (max-width: 922px) {
    grid-template-columns: 1fr;
  }
`;

export const LeftContainer = styled.div`
.Info {
 margin-bottom: 15px;
}
`;

export const RightContainer = styled.div`
  align-self: flex-start;
  position: sticky;
  display: block;
  top: 80px;
  .ACard {
    width: inherit;
    padding: 1.5rem;
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.2rem;
    background: #eaf3ff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    h1 {
      color: #212121;
      font-family: Inter;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0;
    }
    p {
      color: #575f6b;
      font-family: Inter;
      font-size: 0.8125rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0;
    }
  }
  @media screen and (max-width: 922px) {
    display: none;
  }
`;

export const OverView = styled.div`
  // padding-bottom: 3rem;
  background-color: #f7fbff;
    padding: 30px;
  h1 {
    color: #13131a;
    font-family: Inter;
    font-size: 1.25rem;
    font-size: clamp(1rem, 1rem + 1vw, 2.25rem);
    font-weight: 500;
  }
  .InfoContainer {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    .Info {
      h1 {
        color: #6e6e73;
        font-family: Inter;
        font-size: 0.875rem;
        font-size: clamp(0.65rem, 0.75rem + 1vw, 0.875rem);
        font-weight: 600;
      }
      h3 {
        color: #323232;
        font-family: Inter;
        font-size: 1rem;
        font-size: clamp(0.75rem, 0.75rem + 1vw, 1rem);
        font-weight: 600;
      }
    }
  }
  .Description {
    margin-top: 2rem;
    padding-left: 1rem;
    padding-bottom: 2rem;
    height: ${(props) => (props.Check ? '5rem' : '100%')};
    overflow: hidden;
    background: linear-gradient(
      180deg,
      hsla(0, 0%, 100%, 0),
      hsla(0, 0%, 85%, 0.2)
    );
  }
  .Wrapper {
    width: max-content;
    border-radius: 1rem;
    border: 1px solid #d1d1d1;
    background: ${(props) => props.theme.colors.primary};
    padding: 0.25rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    margin: -1rem auto 0 auto;
    .read_more {
      color: white;
    }
    .SvgWrapper {
      transform: rotate(${(props) => (props.Check ? '0deg' : '180deg')});
      svg {
        path {
          fill: white;
        }
      }
    }
    p {
      margin: 0;
    }
    &:hover {
      cursor: pointer;
    }
  }
`;

export const Map = styled.div`
  border-top: 2px solid #e1e1e1;
  border-bottom: 2px solid #e1e1e1;
  padding: 4rem 0;

  .ContainerMap {
    display: flex;
    gap: 1.5rem;
    align-items: center;
  }
  h1 {
    color: #13131a;
    font-family: Inter;
    font-size: 1.25rem;
    font-size: clamp(1rem, 1rem + 1vw, 2.25rem);
    margin-bottom: 2rem;
    font-weight: 500;
  }
  .leftSection {
    flex: 2;
    height: 300px;
    iframe {
      width: 100%;
      height: 100%;
      min-height: 15rem;
      border-radius: 0.625rem;
    }
  }
  .rightSection {
    text-align: center;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    flex: 1;
    .wrapper {
      margin: auto;
      width: 3rem;
      height: 3rem;
      background-color: #fff;
      border-radius: 50%;
      filter: drop-shadow(0px 2px 5px rgba(0, 97, 223, 0.2));
      display: flex;
      justify-content: center;
      align-items: center;
    }
    p {
      margin: 0.75rem 0 0 0;
      color: ${(props) => props.theme.colors.primary};
      font-weight: 500;
    }
    .BulletPoints {
      margin: 0 auto;
    }
  }
  @media screen and (max-width: 1160px) {
    .ContainerMap {
      flex-direction: column;
      .leftSection {
        width: 100%;
        height: 250px;
      }
      .rightSection {
        width: 100%;
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
`;

export const Amenities = styled.div`
  padding: 4rem 0;
  border-bottom: 2px solid #e1e1e1;
  h1 {
    color: #13131a;
    font-family: Inter;
    font-size: 1.25rem;
    font-size: clamp(1rem, 1rem + 1vw, 1.25rem);
    font-weight: 500;
    margin-bottom: 2rem;
  }
  .Container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    gap: 1.5rem;
    .wrapper {
      margin: auto;
      width: 3rem;
      height: 3rem;
      background-color: #fff;
      border-radius: 50%;
      filter: drop-shadow(0px 2px 5px rgba(0, 97, 223, 0.2));
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const PropertyDetails = styled.div`
  // padding: 4rem 0;
  // border-bottom: 2px solid #e1e1e1;
      background-color: #212529;
    padding: 30px;
    color: #fff;
  h1 {
    color: #fff;
    font-family: Inter;
    font-size: 1.25rem;
    font-size: clamp(1rem, 1rem + 1vw, 2.25rem);
    font-weight: 500;
  }
  .Details {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    gap: 1.5rem;
    p {
      ${(props) => props.theme.colors.primary}
    }
    span {
      color: #fff;
      font-weight: 500;
    }
  }
`;

export const Detail = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  gap: 1.5rem;
  p {
    // color: ${(props) => props.theme.colors.primary};
    color: #ededed;
  }
`;

export const Appoinment = styled.div`
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  display: flex;
  // gap: 1rem;
  &.mobileAnimation {
    display: none;
    background: none;
  }
    .contents {
     h6 {
         font-size: 0.9rem;
     }
     p {
         font-size: 0.8rem;
     }
    }
  p {
    margin: 0;
    color: #212121;
    font-family: Inter;
    font-size: 0.875rem;
    font-weight: 500;
    margin-left: 1rem;
  }
  .animation {
    .wrapper {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${(props) => props.theme.colors.primary};
      border-radius: 50%;
      // padding: 0.3rem;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 10px solid ${(props) => props.theme.colors.primary};
        border-radius: 50%;
        animation: wave 2s linear infinite;
      }
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 10px solid ${(props) => props.theme.colors.primary};
        border-radius: 50%;
        animation: wave 1s linear infinite;
      }
      @keyframes wave {
        0% {
          transform: scale(1);
          opacity: 1;
        }
        100% {
          transform: scale(2);
          opacity: 0;
        }
      }
    }
  }
  &:hover {
    // background: linear-gradient(
    //   271deg,
    //   ${(props) => props.theme.colors.primary} 15.89%,
    //   rgba(0, 97, 223, 0) 98.9%
    // );
    .content {
      color: #ffff;
    }
  }
  @media screen and (max-width: 925px) {
    &.mobileAnimation {
      display: block;
      position: sticky;
      bottom: 3rem;
      width: fit-content;
      margin-left: auto;
      box-shadow: none;
    }
  }
`;

export const AvailableFloorPlans = styled.div`
  padding: 4rem 0;
  border-bottom: 2px solid #e1e1e1;
  h1 {
    color: #13131a;
    font-family: Inter;
    font-size: 1.25rem;
    font-size: clamp(1rem, 1rem + 1vw, 2.25rem);
    font-weight: 500;
  }
  .FloorPlansContainer {
    display: flex;
    flex-direction: column;
  }
`;
export const AppointmentContainer = styled.div`
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.4)
  );
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HomeValue = styled.div`
  &#estimated-section {
    .estimated-card {
      background-color: #f7fbff;
      padding: 30px;
    }

    .estimated-card-header {
      display: flex;
      gap: 20px;
      align-items: flex-start;
      flex-wrap: wrap;
      &,
      small {
        font-size: 13px;
      }

      .estimated-property-details {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 6px;
        line-height: 0;
        width: 270px;

        .d-info {
          svg {
            width: 20px;
            path {
              fill: ${(props) => props.theme.colors.primary};
            }
          }
        }
        & > * {
          display: flex;
          align-items: center;
          gap: 3px;
        }

        & > :first-child {
          flex-basis: 100%;
          margin-bottom: 3px;
        }
      }
      .redbuyers_info {
        small,
        p {
          font-weight: bold;
          color: ${(props) => props.theme.colors.primary};
        }
      }
      .sale_range {
        p {
          color: ${(props) => props.theme.colors.primary};
        }
      }
      margin-bottom: 40px;
    }

    .estimated-card-body {
      margin-bottom: 100px;

      .estimated-slider-wrapper {
        background: linear-gradient(90deg, #fff 0%, #ebecf0 100%);
        height: 100px;
        padding: 30px 50px;
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.2);

        .slider-bar {
          height: 7px;
          background-color: ${(props) => props.theme.colors.primary};
          position: relative;

          .slider-bar-ball {
            width: 19px;
            height: 19px;
            border-radius: 50%;
            background: ${(props) => props.theme.colors.primary};
            position: absolute;
          }

          &::before,
          &::after {
            content: '';
            display: block;
            width: 17px;
            height: 17px;
            border-radius: 50%;
            background: ${(props) => props.theme.colors.primary};
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
          }

          &::after {
            right: 0;
            transform: translate(50%, -50%);
          }

          .slider-bar-ball.center {
            width: 28px;
            height: 28px;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 6px solid #fff;
          }
        }
      }

      .slider-price {
        width: 110px;
        height: 45px;
        background: linear-gradient(
          119deg,
          #fff 0%,
          rgba(235, 236, 240, 0.19) 100%
        );
        box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.2);
        color: var(--primary-clr);
        position: absolute;
        top: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 15px;
        transform: translateX(-50%);

        &::after {
          content: '';
          display: block;
          position: absolute;
          border: 8px solid transparent;
          border-bottom-color: #f9f9fa;
          top: -8px;
          transform: translateY(-50%);
        }

        &--right {
          right: 0;
          transform: translateX(50%);
        }

        &--center {
          background: ${(props) => props.theme.colors.primary};
          color: #fff;
          box-shadow: 0px 31.539297103881836px 63.07859420776367px
            rgba(0, 0, 0, 0.2);
        }

        &--center::after {
          border-bottom-color: ${(props) => props.theme.colors.primary};
        }
      }

      .slider-bar-ball.center,
      .slider-price--center {
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .estimated-card-footer {
      p {
        font-size: 13px;
        color: ${(props) => props.theme.colors.primary};
      }
    }
  }
`;
