/** @format */

import { motion, useCycle } from "framer-motion";
import { useRef, useState} from "react";
import MenuToggle from "./menuetoggle.component";
import { NavLink, NavbarContainer, Sidebar } from "./navbar.style";
import { useDimensions } from "./use-dimentions";

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};
const liVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};
const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 85% 40px)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: "circle(25px at 85% 30px)",
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
};
export default function NavbarT3() {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);
  const [isOpens, setIsOpen] = useState(false);
  const toggleDropdown = () => setIsOpen(!isOpens);
  return (
    <NavbarContainer>
      <div className="links_container">
        <NavLink to={"../home"}>Home</NavLink>
        {/* <NavLink to={"../idxlisting"}>Properties</NavLink>*/}
        <NavLink to={"../about_us"}>About Us</NavLink>
        {/*<NavLink to={"../map"}>Map</NavLink>*/}
        <div className="dropdown ">
          <a
            className="dropdown-toggle"
            href="#"
            id="sellers"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Sellers
          </a>
          <ul className="dropdown-menu" aria-labelledby="sellers">
            <li>
              <NavLink to={"../sellerguide"}>Sellers</NavLink>
            </li>
            <li>
              <NavLink to={"../homevaluation"}>Home Valuation</NavLink>
            </li>
            <li>
              <NavLink to={"../blogs"}>Blogs</NavLink>
            </li>
          </ul>
        </div>
        <div className="dropdown ">
          <a
            className="dropdown-toggle"
            href="#"
            id="buyers"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Buyers
          </a>
          <ul className="dropdown-menu" aria-labelledby="buyers">
            <li>
              <NavLink to={"../buyerguide"}>Buyers</NavLink>
            </li>
            <li>
              <NavLink to={"../mortagecalculator"}>Mortgage Calculator</NavLink>
            </li>
          </ul>
        </div>
        <div className="dropdown">
          <a
            className="dropdown-toggle"
            href="#"
            id="browse_home"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Browse Homes
          </a>
          <ul className="dropdown-menu" aria-labelledby="browse_home">
            <li>
              <NavLink to={"../exclusivecards"}>Exclusive Search</NavLink>
            </li>
            <li>
              <NavLink to={"../featuredcards"}>Featured Search</NavLink>
            </li>
            <li>
              <NavLink to="../idxlisting">Idx Search</NavLink>
            </li>
            <li>
              <NavLink to={"../soldlisting"}>Sold Search</NavLink>
            </li>
            <li>
              <NavLink to={"../builderdeals"}>Builder Search</NavLink>
            </li>
            <li>
              <NavLink to={"../map"}>Map Search</NavLink>
            </li>
          </ul>
        </div>
        <NavLink to={"../contact_us"}>Contact</NavLink>
      </div>
      <Sidebar
        initial={false}
        animate={isOpen ? "open" : "closed"}
        custom={height}
        ref={containerRef}
      >
        <motion.div className="background" variants={sidebar}>
          <motion.ul variants={variants}>
            <li><NavLink to={"../home"}>Home</NavLink></li>
            {/* <NavLink to={"../idxlisting"}>Properties</NavLink>*/}
            <li><NavLink to={"../about_us"}>About Us</NavLink></li>
            {/*<NavLink to={"../map"}>Map</NavLink>*/}
            <li>
              <div className="dropdown ">
                <a
                  className="dropdown-toggle"
                  href="#"
                  id="sellers"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Sellers
                </a>
                <ul className="dropdown-menu" aria-labelledby="sellers">
                  <li>
                    <NavLink to={"../sellerguide"}>Sellers</NavLink>
                  </li>
                  <li>
                    <NavLink to={"../homevaluation"}>Home Valuation</NavLink>
                  </li>
                  <li>
                    <NavLink to={"../blogs"}>Blogs</NavLink>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <div className="dropdown ">
                <a
                  className="dropdown-toggle"
                  href="#"
                  id="buyers"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Buyers
                </a>
                <ul className="dropdown-menu" aria-labelledby="buyers">
                  <li>
                    <NavLink to={"../buyerguide"}>Buyers</NavLink>
                  </li>
                  <li>
                    <NavLink to={"../mortagecalculator"}>Mortgage Calculator</NavLink>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <div className="dropdown">
                <a
                  className="dropdown-toggle"
                  href="#"
                  id="browse_home"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Browse Homes
                </a>
                <ul className="dropdown-menu" aria-labelledby="browse_home">
                  <li>
                    <NavLink to={"../exclusivecards"}>Exclusive Search</NavLink>
                  </li>
                  <li>
                    <NavLink to={"../featuredcards"}>Featured Search</NavLink>
                  </li>
                  <li>
                    <NavLink to="../idxlisting">Idx Search</NavLink>
                  </li>
                  <li>
                    <NavLink to={"../soldlisting"}>Sold Search</NavLink>
                  </li>
                  <li>
                    <NavLink to={"../builderdeals"}>Builder Search</NavLink>
                  </li>
                  <li>
                    <NavLink to={"../map"}>Map Search</NavLink>
                  </li>
                </ul>
              </div>
            </li>
            <li><NavLink to={"../contact_us"}>Contact</NavLink></li>
          </motion.ul>
          <MenuToggle toggle={() => toggleOpen()} />
        </motion.div>
      </Sidebar>
    </NavbarContainer>
  );
}
