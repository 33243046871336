import React, { useEffect } from 'react'
import ReactGoogleAutocomplete from 'react-google-autocomplete'

import utilapivaluationComponent from './component/utilapivaluation.component'
import { Container, HomeValuationForm, PropertyContainer, HomeValue, SpinnerMainContainer } from './homeValuation.styled'
import bannerImg from './bannerImg.jpg';
import bgImg from './banner_home.png';
import oops from './oops.png';
import { calcLength, px } from 'framer-motion';
import PropertyCard1 from '../PropertyCard1/PropertyCard1.utilComponent';
import usePermissionSoldProperty from '../../CustomHooks/usePermissionSoldProperty.customHook';
import SoldHomeForm from '../SoldHomeForm/SoldHomeForm.component';
import SoldProperty from '../SoldProperty/SoldProperty.utilComponents';
import Spinner from '../../../utils/spinner/spinner.component';
import ConvertToCanadianNumber from '../../../utils/New_construction_details/ConvertNumberToDollar.util';
function HomeValuation() {
  const { Valuation, onSubmit, Loading, handleStateChagne, setFormData, FormData, Property } = utilapivaluationComponent()
  const {
    user,
    open,
    handleOpenClose,
    handleNavigation,
    handleSubmitForm,
    handleState,
  } = usePermissionSoldProperty();
  // useEffect(() => {
  //   onSubmit()
  // }, [])
  const handleNavigationGoogle = (place) => {
    // console.log(place)
    // console.log(place.formatted_address, place.geometry.location.lat(), place.geometry.location.lng())
    setFormData((prev) => ({
      ...prev, address: place.formatted_address, latitude: place.geometry.location.lat(), longitude: place.geometry.location.lng(), zip_code: place.address_components[7].long_name,
      city: place.address_components[2].long_name, address_short: place.address_components[0].short_name + place.address_components[1].short_name
    }))
  }
  const FormSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    window.scrollBy({ top: 500, behavior: 'smooth' });
    onSubmit()

    const secValue = document.getElementById('secValue');
    if (secValue) {
      secValue.style.display = 'block';
    }
  }
  const FormContinue = (e: React.FormEvent) => {
    e.preventDefault()

    const secContinue = document.getElementById('secContinue');
    if (secContinue) {
      secContinue.style.display = 'none';
    }

    const secDetail = document.getElementById('secDetail');
    if (secDetail) {
      secDetail.style.display = 'block';
    }
  }
  const handleBack = () => {
    const secContinue = document.getElementById('secContinue');
    if (secContinue) {
      secContinue.style.display = 'block';
    }

    const secDetail = document.getElementById('secDetail');
    if (secDetail) {
      secDetail.style.display = 'none';
    }

    const secValue = document.getElementById('secValue');
    if (secValue) {
      secValue.style.display = 'none';
    }
  };
  console.log(Loading)
  return (
    <>
      <Container className='container-fluid'>
        <div className="row">
          <div className="d-flex col-md-6 col-lg-8 justify-content-center align-items-center text-light" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${bannerImg})`, backgroundSize: 'cover', backgroundPosition: 'center', minHeight: `calc(100vh - 55px)` }}>
            <div className="text-center p-5">
              <h2>Home Valuation</h2>
              <h5>We are using Artificial Intelligence with AVM(Automated Valuation Method) to find the home value.</h5>
              <br />
            </div>
          </div>
          <div className="col-md-6 col-lg-4 my-5 px-5">
            <HomeValuationForm onSubmit={FormContinue} id='secContinue'>
              <h4>We require your information to provide accurate valuations!</h4>
              <div className="row">
                <div className="col-12">
                  <div className="mui-form-group">
                    <input type="text" className="mui-input" id="name" name="name" placeholder=" " required onChange={handleStateChagne} value={FormData.name} />
                    <label htmlFor="name" className="mui-label">Name</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mui-form-group">
                    <input type="email" className="mui-input" id="email" name="email" placeholder=" " required onChange={handleStateChagne} value={FormData.email} />
                    <label htmlFor="email" className="mui-label">Email</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mui-form-group">
                    <input type="tel" className="mui-input" id="phone" name="phone" placeholder=" " required onChange={handleStateChagne} value={FormData.phone} />
                    <label htmlFor="phone" className="mui-label">Phone</label>
                  </div>
                </div>
                <div className="col-md-12 text-center">
                  <button className='btn btn-primary mt-4 w-100' type='submit'>Continue</button>
                </div>
              </div>
            </HomeValuationForm>

            <HomeValuationForm onSubmit={FormSubmit} style={{ display: 'none' }} id='secDetail'>
              <h4>These questions help us provide a better home value.</h4>
              <div className="row">
                <div className="col-12">
                  <div className="mui-form-group">
                    <ReactGoogleAutocomplete
                      id="googleAutoComplete"
                      className="mui-input"
                      apiKey="AIzaSyABlrsdtJlypejWdegbPBnSPwYvInJUGT0"
                      onPlaceSelected={(place) => handleNavigationGoogle(place)}
                      placeholder=" " // Keep placeholder empty for floating label logic
                      options={{
                        types: ['address'],
                        componentRestrictions: { country: 'ca' },
                      }}
                    />
                    <label htmlFor="googleAutoComplete" className="mui-label">Address</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mui-form-group">
                    <select className="mui-select" id="propertyType" name="type" required onChange={handleStateChagne}>
                      <option value="" disabled selected hidden></option>
                      <option value="Detached">Detached Home</option>
                      <option value="Semi-Detached">Semi-Detached</option>
                      <option value="Att/Row/Townhouse">Att/Row/Townhouse</option>
                      <option value="Condo Townhouse">Condo Townhouse</option>
                      <option value="Condo Apartment">Condo Apartment</option>
                      <option value="Other">Other</option>
                    </select>
                    <label htmlFor="propertyType" className="mui-label">Property Type</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mui-form-group">
                    <select className="mui-select" id="homeStyle" name="homeStyle" required onChange={handleStateChagne}>
                      <option value="" disabled selected hidden></option>
                      <option value="2 Story">2 Story</option>
                      <option value="3 Story">3 Story</option>
                      <option value="Bunglow">Bunglow</option>
                      <option value="Back split">Back split</option>
                      <option value="Side split">Side split</option>
                      <option value="Other">Other</option>
                    </select>
                    <label htmlFor="homeStyle" className="mui-label">Home Style</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mui-form-group">
                    <select className="mui-select" id="bedrooms" name="bedrooms" required onChange={handleStateChagne} value={FormData.bedrooms}>
                      <option value="" disabled selected hidden></option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6+">6+</option>
                    </select>
                    <label htmlFor="bedrooms" className="mui-label">Bedrooms</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mui-form-group">
                    <select className="mui-select" id="bathrooms" name="bathrooms" required onChange={handleStateChagne} value={FormData.bathrooms}>
                      <option value="" disabled selected hidden></option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6+">6+</option>
                    </select>
                    <label htmlFor="bathrooms" className="mui-label">Bathrooms</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mui-form-group">
                    <select className="mui-select" id="garage" name="garage" required onChange={handleStateChagne} value={FormData.garage}>
                      <option value="" disabled selected hidden></option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3+">3+</option>
                    </select>
                    <label htmlFor="garage" className="mui-label">Garage</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mui-form-group">
                    <select className="mui-select" id="sqft" name="square_foot" required onChange={handleStateChagne} value={FormData.square_foot}>
                      <option value="" disabled selected hidden></option>
                      <option value="500-1000">500-1000</option>
                      <option value="1000-2000">1000-2000</option>
                      <option value="2000-3000">2000-3000</option>
                      <option value="3000-4000">3000-4000</option>
                      <option value="4000+">4000+</option>
                    </select>
                    <label htmlFor="sqft" className="mui-label">Square Footage</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mui-form-group">
                    <select className="mui-select" id="basement" name="valuation_property_finshed_basement_type" required onChange={handleStateChagne} value={FormData.valuation_property_finshed_basement_type}>
                      <option value="" disabled selected hidden></option>
                      <option value="Finished">Finished</option>
                      <option value="Unfinished">Unfinished</option>
                    </select>
                    <label htmlFor="basement" className="mui-label">Basement</label>
                  </div>
                </div>
                <div className="col-6 text-center">
                  <button className='btn btn-outline-primary mt-4 w-100' type='button' onClick={handleBack}>Back</button>
                </div>
                <div className="col-6 text-center">
                  <button className='btn btn-primary mt-4 w-100' type='submit'>Submit</button>
                </div>
              </div>
            </HomeValuationForm>
          </div>
        </div>

        {Loading ?
          (
            <SpinnerMainContainer>
              <Spinner />
            </SpinnerMainContainer>
          ) : Loading !== null || Loading === false ? (
            Valuation && Valuation.average > 0 ? (
              <div className="row" id='secValue'>
                <div className="col-12">
                  <div className="row" style={{ backgroundImage: `url(${bgImg})` }}>
                    <div className="col-md-8 col-lg-7 col-xl-6 my-4">
                      <HomeValue
                        id="estimated-section"
                        className="border-bottom-0 pb-0 mt-xl-5">
                        <div className="estimated-card" style={{ backgroundColor: '#badcff' }}>
                          <h1 className="cusfont fw-bold">Your Home Value</h1>
                          <p className='mb-2'>Based on Automated Valuation Method (AVM)</p>
                          <p><h2 className='mb-0 fw-bold'>${ConvertToCanadianNumber(Valuation.average)}</h2> Approximate value based on similar homes in the area.</p>
                          <div className="estimated-card-body mr-lg-5 mt-3" style={{ marginBottom: '60px' }}>
                            <div className="estimated-slider-wrapper" style={{ height: '65px' }}>
                              <div className="slider-bar">
                                <div className="slider-price slider-price--left" style={{ top: '55px' }}>
                                  ${ConvertToCanadianNumber(Valuation.min)}
                                </div>
                                <div className="slider-price slider-price--center" style={{ top: '55px' }}>
                                  ${ConvertToCanadianNumber(Valuation.average)}
                                </div>
                                <div className="slider-price slider-price--right" style={{ top: '55px' }}>
                                  ${ConvertToCanadianNumber(Valuation.max)}
                                </div>
                                <div className="slider-bar-ball center"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </HomeValue>
                    </div>
                  </div>
                </div>
                <div className="co-12">
                  <div className="row g-2">
                    <div className="col-12 text-center my-3">
                      <h2>What Went Into Your Offer</h2>
                    </div>
                    <PropertyContainer>
                      {Property.map((item) => (
                        <SoldProperty
                          PropertyData={item}
                          type={'temp_3'}
                          user={user} handleNavigation={handleNavigation}
                        />
                      ))}
                    </PropertyContainer>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row" id='secValue'>
                <div className="col-12">
                  <div className="row ps-md-5" style={{ backgroundImage: `url(${bgImg})` }}>
                    <div className="col-md-10 col-lg-9 col-xl-8 my-4">
                      <HomeValue id="estimated-section" className="border-bottom-0 pb-0 mt-xl-5">
                        <div className="row estimated-card" style={{ backgroundColor: '#badcff' }}>
                          <div className="col-8 mb-4">
                            <h1 className="cusfont fw-bold mb-4">⚠️Oops</h1>
                            <p className='text-muted mb-2'>We are unable to provide you with an offer at this time using the details provided.</p>
                            <p className='text-muted mb-2'>Please change the criteria (bed, bath, garage) and try again to get your offer.</p>
                            <button className='btn btn-warning mt-4' type='button' onClick={handleBack}>Back</button>
                          </div>
                          <div className="col-4 d-flex align-items-center">
                            <img className='w-100' src={oops} alt="" />
                          </div>
                        </div>
                      </HomeValue>
                    </div>
                  </div>
                </div>
              </div>
            )
          ) : null
        }


      </Container>
      {open && (
        <SoldHomeForm handleOpenClose={handleOpenClose} handleState={handleState} handleSubmitForm={handleSubmitForm} />
      )
      }
    </>
  )
}
export default HomeValuation

