/** @format */

import styled from 'styled-components';

export const CardContainer = styled.div`
  background:${({ theme: { colors } }) => colors.text};
  max-width: 27.9375rem;
  max-height: 19.8125rem;
  padding: 1.875rem;
  gap: 1.875rem;
  border-radius: 1.25rem;
  margin: 0 10px;
  .svg_wrapper{
    svg{
      path{
        fill:${({ theme: { colors } }) => colors.primary};
      }
    }
  }
  .review {
    font-family: Montserrat;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.3181rem;
    text-align: left;
    color: ${({ theme: { colors } }) => colors.primary};
    margin: 1.2rem auto;
  }
  .reviewer {
    font-family: Montserrat;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.3181rem;
    text-align: left;
     color: ${({ theme: { colors } }) => colors.primary};
  }
`;
