import React from 'react';
import { useNavigate } from 'react-router-dom';
//import './builderCard.css';
import { CoverImages } from '../../../PropertyCard/helperFunctions';
import { Container } from './builderCard.style';
export const BuilderCard = ({ PropertyData }) => {
  const Navigate = useNavigate();
  const handleNavigation = () => {
    Navigate(`${PropertyData.redirect}/${PropertyData.id}`);
  };
  return (
    <Container onClick={handleNavigation}>
      <img
        src={
          CoverImages(PropertyData.img, 'builder_deals', 'property_temp1')[0]
        }
      />
      <div className='lean-card-content'>
        <h4 className='lp-h4'>{PropertyData.title}</h4>
        <p className='label--12 address'>${PropertyData.price}</p>
        <span className='badge bg-success text-uppercase mt-2'>
          {PropertyData.sale_or_lease}
        </span>
        <div className='item-footer'>
          <p className='mb-2'>
            {PropertyData.bedrooms} Beds | {PropertyData.bathrooms} Baths |{' '}
            {PropertyData.garage} Garages
          </p>
          <p>Listed Price : ${PropertyData.price}</p>
          <p>plan : {PropertyData.plan}</p>
          <p>company : {PropertyData.company}</p>
          <p>Parent property : {PropertyData.parent_property}</p>
          <p>Property sqft : {PropertyData.property_sqft}</p>
        </div>
      </div>
    </Container>
  );
};
export default BuilderCard;
