import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useAdmin } from '../../../../context/DashboardAdmin.context'
import Cookies from "js-cookie";
import { AgentListContainerStyle } from './AgentList.style';
import Spinner from '../../../../utils/spinner/spinner.component'
import Agent from './components/Agent.component';
import useAgentAdmin from '../../utilComponents/useAgentAdmin.admin';
export default function AgentBlockListTable() {
  const { state: { AgentBlockList } } = useAdmin()
  const [loading, setLoading] = useState<boolean>(false)
  const token = Cookies.get("token");
  const { getAgentList, getBanAgentList } = useAgentAdmin({})
  useEffect(() => {
    const GetList = async () => {
      setLoading(true)
      try {
        await getBanAgentList()
      } catch (e) {
        throw new Error(e)
      } finally {
        setLoading(false)
      }
    }
    GetList()
  }, [])
  return (
    <AgentListContainerStyle>
      <p>Ban Agent List</p>
      <div className='agent_header'>
        <div className='agent_tab_header'>
          <p className='heading'>User Name</p>
          <p className='heading bar'>User ActiveTemplate</p>
          <p className='heading bar'>Agent Name</p>
          <p className='heading bar'>Agent Phone</p>
          <p className='heading bar'>Agent Status</p>
          <p className='heading bar'>Edit Agent</p>
        </div>
        <div className='' >
          {AgentBlockList && AgentBlockList?.length !== 0 ? (
            AgentBlockList?.map((data: any) => (
              <Agent data={data} key={data._id} />
            ))
          ) : <p>No Agent </p>}
        </div>
      </div>
    </AgentListContainerStyle>
  )
}

