/** @format */

import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import {
  mlsDataProps,
  useDashboardMlsContext,
} from "../../../../../context/DashboardMlsListing.context";

export default function useListing() {
  const {
    setProperty,
    state: { property },
    setFormVisualState,
  } = useDashboardMlsContext();
  const [loader, setLoader] = useState<boolean>(false);

  const addMlsListing = async (mlsProperty: mlsDataProps): Promise<void> => {
    const token = Cookies.get("token");
    try {
      const response = await axios.post(
        "/admin/add-idx-mls-number",
        mlsProperty,
        {
          headers: {
            "x-access-token": token,
          },
        },
      );
      if (response.status >= 200 && response.status < 300) {
        const Value = [...property, mlsProperty];
        setProperty(Value);
        setFormVisualState(false);
      }
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  };
  const deleteMlsListing = async (mlsId: string): Promise<void> => {
    const token = Cookies.get("token");
    try {
      const response = await axios.delete("/admin/delete-idx-mls-number", {
        headers: {
          "x-access-token": token,
        },
        data: {
          id: mlsId,
        },
      });
      if (response.status >= 200 && response.status < 300) {
        const UpdateList = property.filter((data) => data._id !== mlsId);
        setProperty(UpdateList);
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    const GetMlsListing = async (): Promise<void> => {
      const token = Cookies.get("token");
      setLoader(true);
      try {
        const GetData = await axios.get("/admin/get-idx-mls-number", {
          cancelToken: source.token,
          headers: {
            "x-access-token": token,
          },
        });
        setProperty(GetData.data.data);
        setLoader(false);
      } catch (err) {
        setLoader(false);
        throw new Error(err);
      } finally {
        setLoader(false);
      }
    };
    GetMlsListing();
    return () => {
      source.cancel("Api call got canceled by cleanup.");
    };
  }, []);
  return {
    loader,
    deleteMlsListing,
    addMlsListing,
  };
}
