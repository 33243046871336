/** @format */

import styled from 'styled-components';

type HeroContainerProps = {
  $color: string;
};
// HeroContainer
export const HeroContainer = styled.div<HeroContainerProps>`
  width: min(120rem, 100%);
  margin: 0 auto;
  display: flex;
  position: relative;
  height: 56vw;
  align-items: flex-end;

  /* &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme: { colors } }) => colors.primary};
    z-index: 0;
  } */

  /* .left_container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden; 
  } */
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .right_section {
    flex: 1;
    position: absolute;
    bottom: 2rem;
    left: 1rem;
    z-index: 2;
    padding: 1rem;
    max-width: 50%;
    margin-left: 0;

    .above_section {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      z-index: 3;
      margin-bottom: 0.5rem;
      margin-left: 0;
      text-align: left;
      // background: rgba(0, 0, 0, 0.6);
      padding: 1rem;

      .header,
      .header p {
        font-family: Montserrat;
        font-size: 2rem;
        font-weight: 600;
        line-height: 3.5rem;
        text-align: left;
        color: ${(props) => props.$color};
      }

      .sub_test,
      .sub_test p {
        font-family: Garamond;
        font-size: 1.125rem;
        font-weight: 200;
        text-align: left;
        color: ${(props) => props.$color};
      }
    }
  }

  @media screen and (max-width: 1286px) {
    flex-direction: column;
    align-items: center;

    .right_section {
      position: absolute;
      bottom: 2rem;
      left: 1rem;
      max-width: 100%;
      text-align: left;
    }
  }
`;

export const AgentAchievement = styled.div`
  position: relative;
  padding: 5rem 0;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  // background: black url(https://images.pexels.com/photos/219692/pexels-photo-219692.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1) no-repeat center center;
  background-size: cover;
  background-attachment: fixed;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1;
  }
    h1 {
      font-size: 2em;
      margin-bottom: 3rem;
      z-index: 2;
      font-family: Montserrat;
    }

    /* Box container */
    .box-container {
      display: flex;
      gap: 2rem;
      z-index: 2;
      justify-content: center;
      flex-wrap: wrap;
    }

    /* Box styling */
    .box {
      width: 250px;
      height: 150px;
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(8px);
      font-size: 2.5em;
      font-weight: bold;
      color: white;
      transition: transform 0.3s ease-in-out;
      opacity: 0;
      animation: fadeIn 1s forwards;
      padding-top: 24px;
      border-radius: 0 47px;
    }
    .box p {
      font-size: 1rem;
      font-weight: 400;
      color: #b2b2b2;
    }

    /* Animation delay for each box */
    .box:nth-child(1) {
      animation-delay: 0.3s;
    }
    .box:nth-child(2) {
      animation-delay: 0.6s;
    }
    .box:nth-child(3) {
      animation-delay: 0.9s;
    }

    /* Hover effect */
    .box:hover {
      transform: scale(1.05);
      scale: 1.05;
      transition: 0.3s ease-in-out;
    }

    /* Fade-in animation */
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(20px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
`;

export const CardOfAchievement = styled.div`
  width: 20rem;
  padding: 2rem;
  border-radius: 8px;
  transition: transform 0.15s ease, box-shadow 0.15s ease, color 0.15s ease;
  background-color: ${({ theme: { colors } }) => colors.backgroundSecondary};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  .header {
    font-family: Montserrat;
    font-size: 2rem;
    font-weight: 400;
    line-height: 3rem;
    text-align: center;
    color: ${({ theme: { colors } }) => colors.primary};
    transition: font-weight 0.15s ease;
  }

  .description {
    font-family: Montserrat;
    font-size: 1.75rem;
    font-weight: 300;
    line-height: 2.5rem;
    text-align: center;
    color: ${({ theme: { colors } }) => colors.primary};
    transition: font-weight 0.15s ease;
  }

  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);

    .header,
    .description {
      font-weight: 500;
      color: ${({ theme: { colors } }) => colors.secondary};
    }
  }
`;

export const AgentStory = styled.div`
  width: min(101.25rem, 95%);
  margin: 3rem auto;
  background: ${({ theme: { colors } }) => colors.text};
  /* padding-block: 1.5rem;
  padding-inline: 0; */
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: clamp(1rem, 2vw, 2rem);
  /* min-height: 20rem;
  max-height: 35rem; */
  /* overflow: hidden; */

  .left_section {
    flex: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0.5rem;
      margin-left: 0;
    }
  }

  .right_section {
    flex: 0.5;
    padding: 0.5rem 1rem;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .header {
      font-family: Montserrat;
      font-size: 2rem;
      font-weight: 400;
      line-height: 3rem;
      text-align: left;
      color: ${({ theme: { colors } }) => colors.primary};
      margin-bottom: 1rem;
    }

    .description {
      font-family: Montserrat;
      font-size: clamp(1rem, 1.125vw, 1.125rem);
      font-weight: 200;
      line-height: 1.5rem;
      text-align: left;
      color: black;
    }
  }

  @media screen and (max-width: 1006px) {
    flex-direction: column;
    max-height: none;

    .left_section {
      flex: 0.5;
      padding: 0;

      img {
        width: 100%;
        height: auto;
        max-height: 60vh;
        padding: 0.5rem 0;
        margin-left: 0;
      }
    }

    .right_section {
      flex: 0.5;
      padding: 1rem 2rem;
      text-align: center;

      .header {
        font-size: clamp(1.5rem, 4vw, 4rem);
        line-height: 1.2;
      }

      .description {
        font-size: clamp(0.875rem, 1vw, 1rem);
        line-height: 1.4rem;
      }
    }
  }
`;
