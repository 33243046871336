import styled from "styled-components";
import { FormContainer } from "../../../../CommonStyle/dashboardMain.style";
export const PreivewContainer = styled(FormContainer)``;

export const PreviewExclusiveContainer = styled.div`
  width: min(75rem, 100%);
  background-color: white;
  max-height: 75%;
  overflow: scroll;
  .header_preview {
    padding-inline: 1rem;
    display: flex;
    justify-content: space-between;
    align-item: center;
    margin-block: 2rem;
    button {
      background-color: black;
      color: white;
      border-radius: 5px;
      border: none;
      outline: none;
    }
  }
`;
