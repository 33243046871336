/** @format */

import { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import { Ourteamcontainer, TeamMemberContainer } from './our_team.styled';

import OurTeamCard from './ourTeamCard/our_team_card.component';

import { useLiveEditorHighLighter } from '../../../../context/liveEditorHighighlighter.context';
import LiveEdithTextHighLighter from '../../../../liveEditHighlighterstyles/liveEdithTextHighLighter.component';
export default function OurTeam({ data }) {
  const SliderRefCard = useRef(null);
  const { state } = useLiveEditorHighLighter();
  useEffect(() => {
    const SlideNumber = state.type.split('-');
    if (SlideNumber.length > 1) {
      if (SliderRefCard.current) {
        SliderRefCard?.current?.slickGoTo(Number(SlideNumber[1]));
      }
    }
  }, [state.type]);
  const ourTeam_setting = {
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: Math.min(data.cards.length, 4),
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1384,
        settings: {
          slidesToShow: Math.min(data.cards.length, 3),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1122,
        settings: {
          slidesToShow: Math.min(data.cards.length, 2),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: Math.min(data.cards.length, 1),
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Ourteamcontainer id="sec4">
      <div className="header_container">
        <p className="heading">
          <LiveEdithTextHighLighter
            section="sec4"
            type="text">
            {data.text}
          </LiveEdithTextHighLighter>
        </p>
        <p className="sub_heading">
          <LiveEdithTextHighLighter
            section="sec4"
            type="description">
            {data.description}
          </LiveEdithTextHighLighter>
        </p>
      </div>
      {/*<Slider
        {...ourTeam_setting}
        className="slider_container"
        ref={SliderRefCard}>
        {data.cards.map((data, i) => (
          <OurTeamCard
            key={i}
            data={data}
            index={i}
          />
        ))}
      </Slider>*/}
      <TeamMemberContainer>
        {data.cards.map((data, i: number) => (
          <OurTeamCard
            key={i}
            data={data}
            index={i}
          />
        ))}
      </TeamMemberContainer>
    </Ourteamcontainer>
  );
}
