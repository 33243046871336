/** @format */

import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useMessageContext } from '../../context/Message.context';
import LiveEdithTextHighLighter from '../../liveEditHighlighterstyles/liveEdithTextHighLighter.component';
import LiveEditImageHighLighter from '../../liveEditHighlighterstyles/liveEditImageHighLighter.component';
import GetTemplateData from '../../utils/apis/GetTemplateData.api';
import SiteBuilder from '../SiteBuilder/SiteBuilder';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import './Template1pg2.css';
import { Description, Title } from './aboutus.style';
//component
import OurTeam from '../Template2/components/ourteam/ourteam.t2';
export default function Template1PG2({ templateData }) {
  const [data, setData] = useState(null);
  const [footerData, setFooterData] = useState(null);
  const location = useLocation();
  const { preview } = useParams();
  const getQueryParams = () => {
    return new URLSearchParams(location.search);
  };
  const { setTheme } = useMessageContext();
  const params = getQueryParams();

  useEffect(() => {
    const getFooter = async () => {
      const footerData = await GetTemplateData(preview, 1, 1)
      if (footerData) {
        setFooterData(footerData)
      }
    }
    getFooter()
  }, [])

  useEffect(() => {
    const getData = async () => {
      if (!templateData) {
        const data = await GetTemplateData(preview, 1, 2);
        if (data) {
          setData(data);
          setTheme({
            primary_color: data.styles[0].css.primary,
            secondary_color: data.styles[0].css.secondary,
            warning_message: {
              fontFamily: 'Syncopate',
              fontSize: '20px',
              fontWeight: '700',
              lineHeight: '20.82px',
            },
            warning_text: {
              fontFamily: 'Garamond',
              fontSize: '18px',
              fontWeight: '400',
              lineHeight: '22.32px',
              textAlign: 'center',
            },
            button: {
              fontFamily: 'Syncopate',
              fontSize: '18px',
              fontWeight: '700',
              lineHeight: '18.74px',
            },
          });
        }
      } else {
        setData(templateData);
      }
    };
    getData();
  }, []);

  if (!data)
    return (
      <div
        id="loadingScreen"
        className="loading-screen">
        <div className="loader"></div>
      </div>
    );

  const siteBuilder = params.get('siteBuilder');

  const customStyles = `
        :root {
            --primary: ${data.styles[0].css.primary};
            --secondary: ${data.styles[0].css.secondary};
        }
    `;
  const section1 = (
    <div
      className="sec1"
      id="sec1"
      style={{
        backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url(${'/public/' + data.sec1.image
          }) `,
        width: '100%',
        height: '56vw',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
      }}>

      <div className="info_container">

        <LiveEdithTextHighLighter
          section="sec1"
          type="text">
          <Title
            $color={data.sec1.color}
            dangerouslySetInnerHTML={{ __html: data.sec1.text }}></Title>
        </LiveEdithTextHighLighter>
        {/* </h2> */}
        <LiveEdithTextHighLighter
          section="sec1"
          type="description">
          <Description
            $color={data.sec1.color}
            dangerouslySetInnerHTML={{
              __html: data.sec1.description,
            }}></Description>
        </LiveEdithTextHighLighter>
        <a
          href={data.sec1.action.link}
          className="btn btn-primary"
          style={{
            fontFamily: 'Tenor Sans, sans-serif',
            color: data.sec1.color,
            border: `1px solid ${data.sec1.color}`,
          }}>
          {data.sec1.action.text}
        </a>
      </div>
    </div>
  );

  const section2 = (
    <div
      className="sec2pg2"
      id="sec2">
      <div className="row">
        <div className="col-md-6">
          <LiveEditImageHighLighter
            section="sec2"
            type="img">
            <img
              src={'/public/' + data.sec2.img}
              alt=""
            />
          </LiveEditImageHighLighter>
        </div>
        <div className="col-md-6">
          <div style={{}}>
            <h2 style={{ color: data.sec2.color, fontFamily: 'Syncopate' }}>
              <LiveEdithTextHighLighter
                section="sec2"
                type="text">
                {data.sec2.text}
              </LiveEdithTextHighLighter>
            </h2>
            <div className='pb-3'>
              {data.sec2.description.map((content, index) => (
                <p style={{ color: data.sec2.color, fontFamily: 'Garamond' }}>
                  <LiveEdithTextHighLighter
                    section="sec2"
                    type={`description${index}`}>
                    {content}
                  </LiveEdithTextHighLighter>
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const section3 = (
    <div
      className="sec3pg2"
      style={{ marginTop: '2rem', marginBottom: '2rem' }}
      id="sec3">
      <div className="container">
        <h3
          style={{
            color: data.sec3.color,
            fontFamily: 'Garamond',
            textAlign: 'center',
          }}>
          <LiveEdithTextHighLighter
            section="sec3"
            type="text">
            {data.sec3.text}
          </LiveEdithTextHighLighter>
        </h3>
      </div>
    </div>
  );

  const section4 = (
    <div
      id="sec4"
      style={{
        position: 'relative',
        minHeight: '100vh',
        backgroundImage: `url('/public/${data.sec4.image}')`,
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
      className="section4_about_us">
      {/* Content Section */}
      <div
        style={{
          position: 'absolute',
          zIndex: 2,
          width: 'min(970px, 95%)',
          marginLeft: 'auto',
          marginRight: '0',
          top: '20%',
          left: '4%',
        }}>
        <div
          className="container"
          style={{
            maxWidth: '70%',
            width: '100%',
          }}>
          <h2
            style={{
              color: data.sec4.color,
              fontFamily: 'Syncopate',
              fontSize: 'clamp(1.25rem, 2.5vw, 3rem)',
              fontWeight: 500,
              lineHeight: '3rem',
            }}>
            <LiveEdithTextHighLighter
              section="sec4"
              type="text">
              {data.sec4.text}
            </LiveEdithTextHighLighter>
          </h2>
          <p
            style={{
              color: data.sec4.color,
              fontFamily: 'Garamond',
              fontSize: 'clamp(0.875rem, 1.5vw, 1.5rem)',
              fontWeight: 400,
              lineHeight: '1.75rem',
            }}>
            <LiveEdithTextHighLighter
              section="sec4"
              type="description">
              {data.sec4.description}
            </LiveEdithTextHighLighter>
          </p>
          <a
            style={{
              fontFamily: 'Syncopate',
              fontSize: '1.25rem',
              fontWeight: 400,
              lineHeight: '1.3013rem',
              color: '#FFFFFF',
              backgroundColor: 'var(--primary)',
              border: '1px solid #FFFFFF',
              borderRadius: 0,
              padding: '0.5rem 1.5rem',
              textDecoration: 'none',
            }}
            href={data.sec4.action.link}
            className="btn btn-primary">
            {data.sec4.action.text}
          </a>
        </div>
      </div>
    </div>
  );

  const section5 = (
    <div
      className="sec5pg2"
      id="sec4">
      <div className="row">
        <div
          className="col-md-6"
          style={{
            backgroundImage: `url(linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), ${'/public/' + data.sec5.img1
              }) `,
            height: '40vh',
          }}>
          <h2
            style={{
              color: data.sec5.color,
              fontFamily: 'Syncopate',
              fontWeight: 700,
            }}>
            {data.sec5.text1}
          </h2>
        </div>
        <div
          className="col-md-6 img2"
          style={{
            backgroundImage: `url(linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), ${'/public/' + data.sec5.img2
              }) `,
            height: '40vh',
          }}>
          <h2
            style={{
              color: data.sec5.color,
              fontFamily: 'Syncopate',
              fontWeight: 700,
            }}>
            {data.sec5.text2}
          </h2>
        </div>
      </div>
    </div>
  );

  const section6 = (
    <div
      className="sec6pg2"
      style={{
        backgroundImage: `url(${'/public/' + data.sec6.img}) `,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        padding: '12rem 4rem',
      }}
      id="sec4">
      <h2
        style={{
          color: data.sec6.color,
          fontFamily: 'Garamond',
          fontWeight: 700,
        }}>
        <LiveEdithTextHighLighter
          section="sec6"
          type="text">
          {data.sec6.text}
        </LiveEdithTextHighLighter>
      </h2>
    </div>
  );
  const section7 = (
    <div
      className="our_team"
      id="sec5">
      <h1 className="heading">
        <LiveEdithTextHighLighter
          section="sec7"
          type="text">
          {data.sec7.text}
        </LiveEdithTextHighLighter>
      </h1>
      <p className="sub-heading">
        <LiveEdithTextHighLighter
          section="sec7"
          type="description">
          {data.sec7.description}
        </LiveEdithTextHighLighter>
      </p>
      <OurTeam
        section={'sec7'}
        data={data.sec7.cards}
      />
    </div>
  );

  const section8 = (
    <div
      className="sec7pg2"
      id="sec6">
      <h2 style={{ color: data.sec8.color, fontFamily: 'Syncopate' }}>
        <LiveEdithTextHighLighter
          section="sec8"
          type="text">
          {data.sec8.text}
        </LiveEdithTextHighLighter>
      </h2>
      <div className="row">
        {data.sec8.cards.map((content, index) => (
          <div className="col-md-4">
            <img
              src={'/public/' + content.img}
              alt=""
            />
            <p
              style={{
                color: content.color,
                fontFamily: 'Garamond',
                marginBottom: '3rem',
              }}>
              <LiveEdithTextHighLighter
                section="sec8"
                type={`text${index}`}>
                {content.text}
              </LiveEdithTextHighLighter>
            </p>
            <p
              style={{
                color: content.color,
                fontFamily: 'Syncopate',
                fontWeight: 600,
                textTransform: 'uppercase',
              }}>
              <LiveEdithTextHighLighter
                section="sec8"
                type={`subtext${index}`}>
                {content.subtext}
              </LiveEdithTextHighLighter>
            </p>
          </div>
        ))}
      </div>
    </div>
  );

  const section9 = (
    <div
      className="sec9"
      style={{
        position: 'relative',
        backgroundImage: `url(${'/public/' + footerData?.sec9.image})`,
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
      }}
      id="sec7">
      {/* Overlay */}
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          zIndex: 1,
        }}></div>

      {/* Content */}
      <div
        className="container"
        style={{
          position: 'relative',
          zIndex: 2,
          padding: '0 20px',
          textAlign: 'center',
        }}>
        <h2
          style={{
            color: footerData?.sec9.color,
            fontFamily: 'Syncopate',
            textTransform: 'uppercase',
            marginBottom: '1rem',
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
          }}>
          {footerData?.sec9.text}
        </h2>
        <p
          style={{
            color: footerData?.sec9.color,
            fontFamily: 'Garamond',
            marginBottom: '1.5rem',
            textShadow: '1px 1px 3px rgba(0, 0, 0, 0.5)',
          }}>
          {footerData?.sec9.description}
        </p>
        <a
          href={footerData?.sec9.action.href || '#'}
          className='btn btn-outline-light'>
          {footerData?.sec9.action.text}
        </a>
      </div>
    </div>
  );

  const content = (
    <div
      className="template1"
      style={{ height: '100%' }}>
      <style>{customStyles}</style>
      <Header {...data.header}></Header>
      {data.sec1.visible && section1}
      {data.sec2.visible && section2}
      {data.sec3.visible && section3}
      {data.sec4.visible && section4}
      {/* {data.sec5.visible && section5} */}
      {/* {data.sec6.visible && section6} */}
      {data.sec7.visible && section7}
      {data.sec8.visible && section8}
      {footerData && footerData?.sec9.visible && section9}
      {footerData && <Footer
        {...{
          footerData: footerData && footerData.footer,
        }}></Footer>
      }
    </div>
  );
  return (
    <div>
      <SiteBuilder
        {...{
          template: content,
          siteBuilder: siteBuilder,
          data,
          setData,
        }}></SiteBuilder>
    </div>
  );
}


