import react from 'react';
import { useNavigate } from 'react-router-dom';
import './BuilderProperty.css';
import { CoverImages } from '../PropertyCard/helperFunctions';
export default function BuilderPropertyCard({ PropertyData }) {
  const Navigate = useNavigate();
  const HandleNavigation = () => {
    Navigate(`${PropertyData.redirect}/${PropertyData.id}`);
  };
  return (
    <div className='lean-card' data-aos='fade-down' onClick={HandleNavigation}>
      <img src={CoverImages(PropertyData.img, 'builder_deals', 'property_temp1')[0]} />
      <div className='lean-card-content'>
        <h4 className='lp-h4'>{PropertyData.title}</h4>
        <p className='label--12 address'>${PropertyData.price}</p>
        <span className='badge bg-success text-uppercase mt-2'>
          {PropertyData.sale_or_lease}
        </span>
        <div className='item-footer'>
          <h6 className='mb-2'>
            {PropertyData.bedrooms} Beds | {PropertyData.bathrooms} Baths |{' '}
            {PropertyData.garage} Garages
          </h6>
          <p>Listed Price : ${PropertyData.price}</p>
          <p>plan : {PropertyData.plan}</p>
          <p>company : {PropertyData.company}</p>
          <p>Parent property : {PropertyData.parent_property}</p>
          <p>Property sqft : {PropertyData.property_sqft}</p>
          {/* <p>{PropertyData.sold_in}</p>
          <p>{PropertyData.text}</p> */}
        </div>
      </div>
    </div>
  );
}
