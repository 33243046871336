/** @format */

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import GetTemplateData from '../../../utils/apis/GetTemplateData.api';

import { ThemeProvider } from 'styled-components';

import ClientReview from '../Components/client_reviews/client_reviews.component';
import NavbarT3 from '../Components/navbar/navbar.t3';
import OurTeam from '../Components/our_team/our_team.component';

import { useMessageContext } from '../../../context/Message.context';
import LiveEditImageHighLighter from '../../../liveEditHighlighterstyles/liveEditImageHighLighter.component';
import LiveEdithTextHighLighter from '../../../liveEditHighlighterstyles/liveEdithTextHighLighter.component';
import FooterT3 from '../footer/footer.t3';
import {
  AgentAchievement,
  AgentStory,
  CardOfAchievement,
  HeroContainer,
} from './about_us.style';
import axios from 'axios';
export default function AboutUST3({ templateData }) {
  const [data, setData] = useState(null);
  const [footerData, setFooterData] = useState(null)
  const { preview } = useParams();
  const { setTheme } = useMessageContext();
  //local Data
  useEffect(() => {
    const getData = async () => {
      if (!templateData) {
        const data = await GetTemplateData(preview, 3, 2);
        if (data) {
          setData(data);
          setTheme({
            primary_color: data.styles[0].css.primary,
            secondary_color: data.styles[0].css.secondary,
            borderRadius: '16px',
            warning_message: {
              fontFamily: 'Oswald',
              fontSize: '20px',
              fontWeight: '400',
              lineHeight: '23.4px',
            },
            warning_text: {
              fontFamily: 'Roboto',
              fontSize: '18px',
              fontWeight: '400',
              lineHeight: '22.32px',
              textAlign: 'center',
            },
            button: {
              fontFamily: 'Roboto',
              fontSize: '18px',
              fontWeight: '400',
              lineHeight: '23.4px',
            },
          });
        }
      } else {
        setData(templateData);
      }
    };
    getData();
  }, [templateData]);
  useEffect(() => {
    const getFooterData = async () => {
      const data = await GetTemplateData(preview, 3, 1)
      if (data) {
        setFooterData(data)
      }
    }
    getFooterData()
  }, [])
  if (!data)
    return (
      <div
        id="loadingScreen"
        className="loading-screen">
        <div className="loader"></div>
      </div>
    );
  const theme = {
    colors: {
      primary: data.styles[0].css.primary,
      text: data.styles[0].css.secondary,
      TextGrey: '#6E6E73',
      white: '#FDFDFE',
      InactiveGrey: '#b2b2b2',
      text2: '#000000',
      background: '#f8f9fa',
    },
  };
  return (
    <ThemeProvider theme={theme}>
      <NavbarT3 />
      {data.sec1.visible && (
        <HeroContainer
          id="sec1"
          $color={data.sec1.color}>
          <img
            src={`/public/${data.sec1.image}`}
            alt="presentation"
          />
          <div className="right_section">
            <div className="above_section">
              <LiveEdithTextHighLighter
                section="sec1"
                type="text1">
                <div
                  dangerouslySetInnerHTML={{ __html: data.sec1.text1 }}
                  className="header"></div>
              </LiveEdithTextHighLighter>
              <LiveEdithTextHighLighter
                section="sec1"
                type="description">
                <div
                  dangerouslySetInnerHTML={{ __html: data.sec1.description }}
                  className="sub_test"></div>
              </LiveEdithTextHighLighter>
            </div>
          </div>
        </HeroContainer>
      )}
      {data.sec2.visible && (
        // <AgentAchievement id="sec2">
        //   {data.sec2.cards.map((data, i) => (
        //     <CardOfAchievement key={i}>
        //       <p className="header">
        //         <LiveEdithTextHighLighter
        //           section="sec2"
        //           type={`text${i}`}>
        //           {data.text}
        //         </LiveEdithTextHighLighter>
        //       </p>
        //       <p className="description">
        //         <LiveEdithTextHighLighter
        //           section="sec2"
        //           type={`description${i}`}>
        //           {data.description}
        //         </LiveEdithTextHighLighter>
        //       </p>
        //     </CardOfAchievement>
        //   ))}
        // </AgentAchievement>
        <AgentAchievement id="sec2" style={{
          background: `black url(/public/${data.sec2.image}) no-repeat center center`,
          backgroundAttachment: 'fixed'
        }} >
          <h1>{data.sec2.text}</h1>
          <div className="box-container">
            <div className="box">{data.sec2.text2} <p> {data.sec2.description2}</p></div>
            <div className="box">{data.sec2.text3}<p> {data.sec2.description3}</p></div>
            <div className="box">{data.sec2.text4}<p>{data.sec2.description4}</p></div>
          </div>
        </AgentAchievement>
      )}
      {data.sec3.visible && (
        <AgentStory id="sec3">
          <div className="left_section">
            <img
              src={`/public/${data.sec3.image}`}
              alt=""
            />
          </div>
          <div className="right_section">
            <p className="header">
              <LiveEdithTextHighLighter
                section="sec3"
                type="text">
                {data.sec3.text}
              </LiveEdithTextHighLighter>
            </p>
            <p className="description mb-2">
              <LiveEdithTextHighLighter
                section={'sec3'}
                type={'description'}>
                <div
                  dangerouslySetInnerHTML={{ __html: data.sec3.description }}
                  className="sub_header"></div>
              </LiveEdithTextHighLighter>
            </p>
            <p className="description">
              <LiveEdithTextHighLighter
                section={'sec3'}
                type={'description2'}>
                <div
                  dangerouslySetInnerHTML={{ __html: data.sec3.description2 }}
                  className="sub_header"></div>
              </LiveEdithTextHighLighter>
            </p>
          </div>
        </AgentStory>
      )}
      {data.sec4.visible && <OurTeam data={data.sec4} />}
      {data.sec5.visible && <ClientReview data={data.sec5} />}
      {data.sec6.visible && (
        <HeroContainer
          id="sec6"
          $color={data.sec6.color}>
          <img
            src={`/public/${data.sec6.image}`}
            alt="presentation"
          />
          <div className="right_section">
            <div className="above_section">
              <LiveEdithTextHighLighter
                section="sec6"
                type="text1">
                <div
                  dangerouslySetInnerHTML={{ __html: data.sec6.text1 }}
                  className="header"></div>
              </LiveEdithTextHighLighter>
              <LiveEdithTextHighLighter
                section="sec6"
                type="description">
                <div
                  dangerouslySetInnerHTML={{ __html: data.sec6.description }}
                  className="sub_test"></div>
              </LiveEdithTextHighLighter>
            </div>
          </div>
        </HeroContainer>
      )}

      <FooterT3 data={footerData} />
    </ThemeProvider>
  );
}
