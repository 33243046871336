import React, { useEffect, useState } from 'react';
import '../../utilComponent/PropertyCard1/PropertyCard1.style.css'
import './ExclusiveListing2.css'
import axios from 'axios';
import Cookies from 'js-cookie';

interface ExclusiveCard {
  _id: string;
  title: string;
  price: string;
  description: string;
  images: string[];
}

export default function ExclusiveListingCards() {
  const [Exclusive, setExclusive] = useState<ExclusiveCard[]>([]);

  

  useEffect(() => {
    const FetchData = async () => {
      const token = Cookies.get('token');
      const agentUrl = window.location.href.split('/')[2];
      let config = {
        headers: {},
        params: {},
      };

      if (token) {
        config.headers['x-access-token'] = token;
      } else {
        config.params['domain'] = agentUrl;
      }

      try {
        const fetchData = await axios.get('/api/get-agent-exclusivelisting', config);
        const fetchedData = fetchData.data.data;
        setExclusive(fetchedData);
      } catch (e) {
        console.error('Error fetching exclusive listings:', e);
      }
    };
    FetchData();
  }, []);

  return (
    <>
    <div className='container-flex exclisveBanner'>
      <div className="excluSection">
        <div className="container">
          <h1>Exclusive Listings</h1>
          <p>Lorem ipsum dolor sit amet!</p>
        </div>
      </div>
    </div>
    <div className="exclusive-container">
      {Exclusive.map((card) => (
        <div className="altman-properties-item"  key={card._id} onClick={() => (window.location.href = '/blue1/exclusiveview/?id=' + card._id)}>
          <div className="sub-main">
            <div className="altman-properties-item-inner-wrapper">
              <div className="altman-properties-item-photo">
                <canvas
                  width="529"
                  height="460"
                  className="lazy-load-canvas-element"
                  data-lazyload-src={`/public/${card.images[0] || ''}`}
                  style={{
                    backgroundImage: `url('/public/${card.images[0] || ''}')`,
                  }}
                />
              </div>
              <div className="altman-properties-item-content-status-city ease">
                <div >Exclusive Listing</div>
                {/* <div>{card.price}</div> */}
              </div>
              <div className="altman-properties-item-content ease">
                <div className="altman-properties-item-content-address">
                  <h3 dangerouslySetInnerHTML={{
                      __html: card?.title,
                    }}></h3>
                  <div></div>
                </div>
                <ul className="altman-properties-item-content-features ease ps-0">
                  {/* <li>{card.price} BH</li>
                  <li>{card.price} GAR</li> */}
                  <li>Exclusive</li>
                  &nbsp;
                </ul>
                <div className="altman-properties-item-content-price ease" dangerouslySetInnerHTML={{__html: card?.price,}}>

                </div>
              </div>
              <div
                className="altman-properties-item-link-label ease"
              
              >
                View Details
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>   </>
  )
       
}