import {
  ExclusiveHeader,
  AddButton,
  ExclusiveSortSection,
} from "./ExclusiveListingHeader.style";
import SearchDashBoard from "../../../../CommonComponent/Search/search.component";
import { useDashboardExclusiveListing } from "../../../../../../context/DashboardExclusiveListing";
const ExclusiveListingHeading = () => {
  const { setFormVisualState, setFormType } = useDashboardExclusiveListing();

  const handleOpen = () => {
    setFormVisualState(true);
    setFormType("add");
  };
  return (
    <ExclusiveHeader>
      <p className="heading">Exclusive Listing</p>
      <div className="crm_actions d-flex gap-5">
        <SearchDashBoard
          placeholder={undefined}
          value={undefined}
          handleChange={undefined}
        />
        <AddButton onClick={handleOpen}>
          <div className="svg_wrapper">
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23.75 15.25C23.75 15.8023 23.3023 16.25 22.75 16.25H16.75C16.4739 16.25 16.25 16.4739 16.25 16.75V22.75C16.25 23.3023 15.8023 23.75 15.25 23.75H14.75C14.1977 23.75 13.75 23.3023 13.75 22.75V16.75C13.75 16.4739 13.5261 16.25 13.25 16.25H7.25C6.69772 16.25 6.25 15.8023 6.25 15.25V14.75C6.25 14.1977 6.69772 13.75 7.25 13.75H13.25C13.5261 13.75 13.75 13.5261 13.75 13.25V7.25C13.75 6.69772 14.1977 6.25 14.75 6.25H15.25C15.8023 6.25 16.25 6.69772 16.25 7.25V13.25C16.25 13.5261 16.4739 13.75 16.75 13.75H22.75C23.3023 13.75 23.75 14.1977 23.75 14.75V15.25Z"
                fill="#FAFAFC"
              />
            </svg>
          </div>
          New Listing
        </AddButton>
      </div>
      <ExclusiveSortSection>
        <div className="wrapper">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
          >
            <path
              d="M3.125 17.75C3.125 18.3023 3.57272 18.75 4.125 18.75H8.375C8.92728 18.75 9.375 18.3023 9.375 17.75V17.6667C9.375 17.1144 8.92728 16.6667 8.375 16.6667H4.125C3.57272 16.6667 3.125 17.1144 3.125 17.6667V17.75ZM4.125 6.25C3.57272 6.25 3.125 6.69772 3.125 7.25V7.33333C3.125 7.88562 3.57272 8.33333 4.125 8.33333H20.875C21.4273 8.33333 21.875 7.88562 21.875 7.33333V7.25C21.875 6.69772 21.4273 6.25 20.875 6.25H4.125ZM3.125 12.5417C3.125 13.094 3.57272 13.5417 4.125 13.5417H14.625C15.1773 13.5417 15.625 13.094 15.625 12.5417V12.4583C15.625 11.906 15.1773 11.4583 14.625 11.4583H4.125C3.57272 11.4583 3.125 11.906 3.125 12.4583V12.5417Z"
              fill="#999999"
            />
          </svg>
        </div>
        <p className="heading">Sort By</p>

        <select className="select_box">
          <option>Recently added</option>
          <option>Recently added</option>
        </select>
      </ExclusiveSortSection>
    </ExclusiveHeader>
  );
};

export default ExclusiveListingHeading;
