import styled from "styled-components";


export const Container = styled.div`
  h1{
  font-family: 'Montserrat';
  }
  p{
  font-family: 'Cormorant Garamond';
  }
`

export const AboutInfoContainer = styled.div`
  min-height: 45vh;
 width:min(95%,100rem);
 margin: 4rem auto;
 display: flex;
 justify-content: space-between;
 align-items: center;
 gap: 3rem;
 .leftSection{
    flex: 1;
    padding: 2rem;
    height: 65% ;
    // background-color: ${props => props.theme.colors.primary} ;
    .InfoImage{
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
 }
 .rightSection{
    flex: 1;
    h1{
      font-size:clamp(2rem , 3vw , 3.51rem ) ;
      text-transform: uppercase;
      margin-bottom: 2rem;
    }
    p{
    color: ${props => props.theme.colors.text2} ;
    text-align: left;
    }
 }
 @media screen and (max-width:765px) {
     flex-direction:column ;
 }
`
export const AbouTeamInfo = styled.div`
width:min(100%,120rem) ;
margin: 0 auto;
background-color:${props => props.theme.colors.primary} ;
display: flex;
align-items: start;
gap: 5rem;
padding: clamp(1rem , 4vw , 4rem);
.leftSection{
  flex: 1;
  .svg_wrapper{
    svg{
    height:clamp(5rem , 5vw , 15rem) ;
    width: clamp(5rem , 5vw , 15rem) ;
    }

  }
  h1{
    font-size:clamp(2rem , 3vw , 3rem);
    text-transform: uppercase;
    color: ${ props => props.theme.colors.text};
  }
  .sub_text{
    font-size: clamp(.75rem , 1rem , 1.25rem) ;
    text-align: left;
    color: white;
  }
}
.rightSection{
    flex: 1;
   .teamImage{
    width: 100%;
    height: 100%;
    min-height: 40rem; 
    object-fit: cover;
   }
}
@media screen and (max-width:858px) {
   flex-direction: column-reverse;
   gap: 2rem;
}
`