import React from 'react'
import './header.css'
import Logo from '../../../../images/agentshades logo.png'
import { Link } from 'react-router-dom'
export default function HeaderAdmin() {
  return (
    <div className="DashBoard_Header_container d-flex align-items-center justify-content-between">
      <Link to="/">
        <img
          src={Logo}
          alt="Logo"
          className="Logo"
        />
        AGENTSHADES | ADMIN
      </Link>

    </div>
  )
}

