import styled from "styled-components";

type TextHighLighterProps = {
  outlinecheck: boolean;
  active: "active" | "inactive" | null;
};

const getOutLineColor = (props) => {
  if (props.outlinecheck === true) {
    if (props.active === "active") {
      return "4px solid green";
    } else if (props.active === "inactive") {
      return "4px solid orange";
    } else {
      return "";
    }
  } else {
    return "";
  }
};

export const TextHighLighter = styled.div<TextHighLighterProps>`
  outline: ${(props) => getOutLineColor(props)};
  border-radius: 3px;
  &::before {
  }
`;

export const ImageHighLighter = styled.div<TextHighLighterProps>`
  outline: ${(props) => getOutLineColor(props)};
  border-radius: 3px;
  height: inherit;
  position: relative;
  &::before {
  }
  &::after {
  }
`;

export const SpinnerPlace = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
`;
