import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

import LiveEditorHighLighterProvider from './context/liveEditorHighighlighter.context';
import MessageBoxContextProvider from './context/Message.context';
import AuthContextProvider from './context/Auth.context';
import { HelmetProvider } from 'react-helmet-async';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <LiveEditorHighLighterProvider>
        <MessageBoxContextProvider>
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </MessageBoxContextProvider>
      </LiveEditorHighLighterProvider>
    </AuthContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
