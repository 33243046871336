import React from 'react'
import axios from 'axios'
import { useAdmin } from '../../../context/DashboardAdmin.context'
import Cookies from "js-cookie";
export default function useAgentAdmin({ id }: { id?: string }) {
  const { setAgentProfile, setAgentList, setBlockAgentList } = useAdmin()
  const token = Cookies.get("token");

  const getAgentProfile = async () => {
    try {
      const Profile = await axios.get(`/superadmin/agents/${id}`);
      const AgentProfileData = Profile.data.data;
      setAgentProfile(AgentProfileData)
    } catch (e) {
      throw new Error(e)
    }
  }
  const updateThePassword = async (updatedPassword: {}) => {
    try {
      await axios.post(`/superadmin/update-password/${id}`, updatedPassword,
        { headers: { 'x-access-token': token } })
    } catch (e) {
      throw new Error(e)
    }
  }
  const banUser = async (updatedUser: {}) => {
    try {
      await axios.put(`/superadmin/ban-user/${id}`, updatedUser, {
        headers: {
          'x-access-token': token
        }
      })
      await getAgentList()
      await getBanAgentList()
    } catch (e) {
      throw new Error(e)
    }
  }

  const activateUser = async (updatedUser: {}) => {
    try {
      await axios.put(`/superadmin/activate-user/${id}`, updatedUser, {
        headers: {
          'x-access-token': token
        }
      })
      await getAgentList()
      await getBanAgentList()
    } catch (e) {
      throw new Error(e)
    }
  }

  const updateTheUser = async (updatedUser: {}) => {
    try {
      await axios.put(`/superadmin/update-user/${id}`, updatedUser, {
        headers: {
          'x-access-token': token,
        }
      })
      await getAgentList()
      await getBanAgentList()
    } catch (e) {
      throw new Error(e)
    }
  }

  const getAgentList = async () => {
    try {
      const Data = await axios.get('/superadmin/agents', {
        headers: {
          'x-access-token': token,
        }
      })
      const AgentList = Data.data.data
      setAgentList(AgentList)
    } catch (e) {
      throw new Error(e)
    }
  }
  const getBanAgentList = async () => {
    try {
      const Data = await axios.get('/superadmin/banned-agents', {
        headers: {
          'x-access-token': token
        }
      })
      const BanAgentList = Data.data.data;
      setBlockAgentList(BanAgentList)
    } catch (e) {
      throw new Error(e)
    }
  }
  return {
    getAgentProfile,
    getBanAgentList,
    updateThePassword,
    banUser,
    activateUser,
    updateTheUser,
    getAgentList
  }
}

