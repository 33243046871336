import { ReactNode, createContext, useContext, useReducer } from 'react';

export type StateProps = {
  AgentList: [] | null
  AgentBlockList: [] | null
  AgentProfile: {} | null
}

type ContextValue = {
  state: StateProps;
  setAgentList: (AgentList: []) => void;
  setBlockAgentList: (AgentBlockList: []) => void;
  setAgentProfile: (AgentProfile: {}) => void;
}

const initialState: StateProps = {
  AgentList: null,
  AgentBlockList: null,
  AgentProfile: null
}

type Action = { type: 'set_agent_list'; payload: { AgentList: [] } } | { type: 'set_block_agent_list'; payload: { AgentBlockList: [] } } | { type: 'set_agent_profile', payload: { AgentProfile: {} } }

const AdminReducer = (state: StateProps, action: Action) => {
  switch (action.type) {
    case 'set_agent_list':
      return { ...state, AgentList: action.payload.AgentList };
    case 'set_block_agent_list':
      return { ...state, AgentBlockList: action.payload.AgentBlockList };
    case 'set_agent_profile':
      return { ...state, AgentProfile: action.payload.AgentProfile }
  }
}

const AdminContext = createContext<ContextValue | undefined>(undefined)

interface AdminProviderProps {
  children: ReactNode;
}

const AdminContextProvider = ({
  children
}: AdminProviderProps) => {
  const [state, dispatch] = useReducer(AdminReducer, initialState);

  const setAgentList = (AgentList: []) => {
    dispatch({ type: 'set_agent_list', payload: { AgentList } })
  }
  const setBlockAgentList = (AgentBlockList: []) => {
    dispatch({ type: 'set_block_agent_list', payload: { AgentBlockList } })
  }
  const setAgentProfile = (AgentProfile: {}) => {
    dispatch({ type: 'set_agent_profile', payload: { AgentProfile } })
  }
  const contextValue: ContextValue = {
    state,
    setAgentList,
    setBlockAgentList,
    setAgentProfile
  }
  return (
    <AdminContext.Provider value={contextValue}>
      {children}
    </AdminContext.Provider>
  )
}

export function useAdmin() {
  const context = useContext(AdminContext);
  if (!context) {
    throw new Error('AdminContext must be used in withing a AdminContext')
  }
  return context
}

export default AdminContextProvider

