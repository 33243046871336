import { styled } from "styled-components";


export const MainContainer = styled.div`
width:min(120rem,94%);
margin:0 auto;
h1{
width:fit-content;
margin:0 auto;
margin-top:3rem;
}
`

export const Container = styled.div`
 width: min(115rem, 94%);
  display: grid;
  grid-template-columns: repeat(auto-fit, minMax(288px, 1fr));
  justify-content: center;
  justify-items: center;
  margin: 3rem auto;
  gap: 1rem;
  @media screen and (min-width: 1281px) {
    width: min(95rem, 95%);
    grid-template-columns: repeat(auto-fit, minMax(290px, 1fr));
  }

`
