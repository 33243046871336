/** @format */

import axios from 'axios';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
export default function useSearchDropDown() {
  const [city, setCity] = useState<{ city: string }[]>(null);
  const [community, setCommunity] = useState<{ community: string }[]>(null);
  const [filterCity, setFilterCity] = useState([]);
  const [filterCommunity, setFilterCommunity] = useState([]);
  const [search, setSearch] = useState(null);
  const [data, setData] = useState({
    city: '',
    community: '',
    address: '',
    listing: [],
  });
  const [loader, setLoader] = useState<boolean>(true);
  const handleInputChange = (event: string) => {
    setSearch(event);
  };

  const handleFilterChange = async (search) => {
    const Listing = await getListing(search);
    setData((prev) => ({
      ...prev,
      listing: Listing,
    }));
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleInputChange = useCallback(
    _.debounce((value) => {
      handleFilterChange(value);
      handleFilterCity(value);
      handleFilterCommunity(value);
    }, 1500),
    [city, community]
  );
  const handleFilterCity = (search: string) => {
    const checkThisString = search.toLowerCase();
    if (checkThisString?.length > 0) {
      const filterData = city
        .filter((item: { city: string }) =>
          item.city.toLowerCase().includes(checkThisString)
        )
        .sort((a: { city: string }, b: { city: string }) => {
          const aCity = a.city.toLowerCase();
          const bCity = b.city.toLowerCase();
          if (
            aCity.startsWith(checkThisString) &&
            !bCity.startsWith(checkThisString)
          ) {
            return -1;
          } else if (
            !aCity.startsWith(checkThisString) &&
            bCity.startsWith(checkThisString)
          ) {
            return 1;
          } else {
            return aCity.localeCompare(bCity);
          }
        });
      setFilterCity(filterData);
    } else {
      setFilterCity([]);
    }
  };

  const handleFilterCommunity = (search: string) => {
    const CheckThisString = search.toLowerCase();
    if (CheckThisString?.length > 0) {
      const filterData = community.filter((item: { community: string }) =>
        item.community.toLowerCase().includes(CheckThisString)
      );
      setFilterCommunity(filterData);
    } else {
      setFilterCommunity([]);
    }
  };
  const onChange = (event: string) => {
    handleInputChange(event);
    debouncedHandleInputChange(event);
  };

  const getListing = async (search) => {
    setLoader(false);
    if (search.length > 0) {
      try {
        const getListing = await axios.get(
          'https://valuation.santhoshmathew.com/api/v1/get-search-property-suggestion',
          {
            params: {
              search: search,
            },
          }
        );
        setLoader(true);
        return getListing.data.data;
      } catch (e) {
        setLoader(true);
        return [];
      }
    }
  };
  const getStreetData = async (search: string) => {
    const GOOGLE_API = process.env.REACT_APP_GOOGLE_API;
    try {
      const url = `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${'harthill'}&types=address&key=${GOOGLE_API}`;
      const response = await fetch(url);
      const data = await response.json();
      if (data.status === 'OK') {
        console.log(data.predictions);
      } else {
        console.error('Error fetching autocomplete data:', data.status);
      }
    } catch (e) {
      console.log(e.message);
    }
  };
  useEffect(() => {
    const source = axios.CancelToken.source();
    const getCity = async () => {
      try {
        const city = await axios.get(
          'https://valuation.santhoshmathew.com/api/v1/cities',
          {
            cancelToken: source.token,
          }
        );
        let concatenatedArray: { city: string }[] = [].concat(
          ...city.data.data
        );
        setCity(concatenatedArray);
      } catch (e) {
        throw new Error(e.message);
      }
    };
    getCity();
    return () => {
      source.cancel('Api call got canceled by cleanup.');
    };
  }, []);

  //get community
  useEffect(() => {
    const source = axios.CancelToken.source();
    const getCommunity = async () => {
      try {
        const city = await axios.get(
          'https://valuation.santhoshmathew.com/api/v1/communities',
          {
            cancelToken: source.token,
          }
        );
        let concatenatedArray: { community: string }[] = [].concat(
          ...city.data.data
        );
        setCommunity(concatenatedArray);
      } catch (e) {
        throw new Error(e.message);
      }
    };
    getCommunity();
  }, []);

  useEffect(() => { }, [search]);
  return {
    data,
    search,
    setSearch,
    onChange,
    filterCity,
    filterCommunity,
    loader,
  };
}
