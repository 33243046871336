import imageCompression from "browser-image-compression";
import React from "react";
import useProfileCropper from "./useProfileCropper";
import {
  Container,
  ImageUploadContainer,
  InputBoxContainer,
  MainContainer,
  SpinnerContainer,
} from "./ProfileUpload.style";
import Cropper from "react-easy-crop";
import ColorPicker from "../../../../WebsiteDesigner/ColorPicker";
import Spinner from "../../../../../utils/spinner/spinner.component";
type ProfileUploadImageProps = {
  aspect: any;
  handlImageInputChange: any;
  blendcolor?: string | null;
};
export default function ProfileUploadImage({
  aspect,
  handlImageInputChange,
  blendcolor = null,
  ...props
}: ProfileUploadImageProps) {
  const {
    handleUpload,
    closeModal,
    setBlend,
    setColor,
    onZoomChange,
    onCropComplete,
    onCropChange,
    onSelectFile,
    loader,
    color,
    ObjectFit,
    imgSrc,
    crop,
    zoom,
    showModal,
    blend,
  } = useProfileCropper({ aspect, blendcolor, handlImageInputChange });
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const isImage = file.type.startsWith("image/");
      const MAX_IMAGE_SIZE = 2 * 1024 * 1024; // 2 MB for images
      const MAX_VIDEO_SIZE = 50 * 1024 * 1024; // 50 MB for videos
      const COMPRESS_VIDEO_SIZE = 5 * 1024 * 1024; // 5 MB for video compression alert

      if (isImage && file.size > MAX_IMAGE_SIZE) {
        alert("Image file size exceeds 2 MB. Compressing the file...");
        try {
          const compressedFile = await imageCompression(file, {
            maxSizeMB: 2,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
          });
          onSelectFile({
            ...event,
            target: {
              ...event.target,
              files: [compressedFile] as unknown as FileList,
            },
          });
        } catch (error) {
          console.error("Error compressing the image:", error);
        }
      } else if (!isImage) {
        if (file.size > MAX_VIDEO_SIZE) {
          alert(
            "Video file size exceeds 50 MB. Please choose a smaller video."
          );
        } else if (file.size > COMPRESS_VIDEO_SIZE) {
          alert(
            "Video file size exceeds 5 MB. It will be compressed. Please wait a few seconds."
          );
          onSelectFile(event);
        } else {
          onSelectFile(event);
        }
      } else {
        onSelectFile(event);
      }
    }
  };

  return (
    <Container>
      {!loader && (
        <InputBoxContainer
          className="des-image-upload"
          type="file"
          accept={"image/*"}
          onChange={handleFileChange}
          {...props}
        />
      )}
      {loader && (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      )}
      {showModal && (
        <ImageUploadContainer>
          <MainContainer>
            <div className="header_container">
              <h1 className="heading">Crop Upload Media</h1>
              <div className="Action_button">
                <button type="button" className="close" onClick={closeModal}>
                  Cancel
                </button>
                <button type="button" className="Proceed" onClick={handleUpload}>
                  Proceed
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.20668 4.47334C6.14488 4.53501 6.09585 4.60827 6.06239 4.68892C6.02894 4.76957 6.01172 4.85603 6.01172 4.94334C6.01172 5.03065 6.02894 5.11711 6.06239 5.19775C6.09585 5.2784 6.14488 5.35166 6.20668 5.41334L8.79335 8L6.20668 10.5867C6.08203 10.7113 6.012 10.8804 6.012 11.0567C6.012 11.233 6.08203 11.402 6.20668 11.5267C6.33133 11.6513 6.5004 11.7214 6.67668 11.7214C6.85297 11.7214 7.02203 11.6513 7.14668 11.5267L10.2067 8.46667C10.2685 8.40499 10.3175 8.33174 10.351 8.25109C10.3844 8.17044 10.4016 8.08398 10.4016 7.99667C10.4016 7.90936 10.3844 7.8229 10.351 7.74225C10.3175 7.66161 10.2685 7.58835 10.2067 7.52667L7.14668 4.46667C6.89335 4.21334 6.46668 4.21334 6.20668 4.47334Z"
                      fill="#13131A"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="container_image_uploader">
              <div className="left_container">
                <ColorPicker
                  color={color}
                  setColor={setColor}
                  blendColor={blendcolor}
                  blend={blend}
                  setBlend={setBlend}
                />
              </div>
              <div className="right_container">
                <div className="cropper">
                  {imgSrc && (
                    <Cropper
                      image={imgSrc}
                      crop={crop}
                      zoom={zoom}
                      aspect={aspect}
                      onCropChange={onCropChange}
                      onZoomChange={onZoomChange}
                      onCropComplete={onCropComplete}
                      objectFit={ObjectFit}
                      restrictPosition={false}
                    />
                  )}
                </div>
                <div className="range">
                  <svg
                    width="41"
                    height="41"
                    viewBox="0 0 41 41"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="41" height="41" rx="8" fill="#232325" />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M23.2942 23.3035C23.9907 22.6071 24.5432 21.7803 24.9202 20.8703C25.2972 19.9604 25.4912 18.9851 25.4913 18.0002C25.4913 17.0152 25.2974 16.0399 24.9205 15.1299C24.5437 14.22 23.9912 13.3931 23.2948 12.6966C22.5984 12.0001 21.7716 11.4476 20.8617 11.0706C19.9517 10.6937 18.9765 10.4996 17.9915 10.4995C16.0024 10.4994 14.0946 11.2895 12.688 12.696C11.2813 14.1025 10.491 16.0101 10.4909 17.9993C10.4908 19.9885 11.2809 21.8962 12.6873 23.3029C14.0938 24.7095 16.0015 25.4998 17.9906 25.4999C19.9798 25.5 21.8876 24.71 23.2942 23.3035ZM25.0617 10.9285C26.7845 12.6512 27.8193 14.9436 27.9716 17.3752C28.124 19.8068 27.3835 22.2104 25.8892 24.1347C25.9092 24.151 25.9267 24.1685 25.9467 24.1872L31.2492 29.4897C31.4838 29.7241 31.6156 30.0421 31.6157 30.3737C31.6158 30.7053 31.4842 31.0233 31.2498 31.2579C31.0154 31.4924 30.6975 31.6243 30.3659 31.6244C30.0343 31.6245 29.7163 31.4929 29.4817 31.2585L24.178 25.9547C24.1599 25.9365 24.1424 25.9178 24.1255 25.8985C22.1157 27.4551 19.5886 28.188 17.0578 27.9482C14.5271 27.7084 12.1826 26.5139 10.501 24.6076C8.81936 22.7012 7.92679 20.2259 8.0047 17.685C8.08262 15.1441 9.12518 12.7283 10.9205 10.9285C12.7958 9.0534 15.3391 8 17.9911 8C20.643 8 23.1864 9.0534 25.0617 10.9285ZM12.9917 16.7497V19.2497H22.9917V16.7497H12.9917Z"
                      fill="#999999"
                    />
                  </svg>

                  <input
                    className="range_input"
                    type="range"
                    value={zoom}
                    min={0.1}
                    max={3}
                    step={0.04}
                    onChange={(e) => onZoomChange(parseFloat(e.target.value))}
                  />
                  <svg
                    width="41"
                    height="41"
                    viewBox="0 0 41 41"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="41" height="41" rx="8" fill="#232325" />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M23.2942 23.3035C23.9907 22.6071 24.5432 21.7803 24.9202 20.8703C25.2972 19.9604 25.4912 18.9851 25.4913 18.0002C25.4913 17.0152 25.2974 16.0399 24.9205 15.1299C24.5437 14.22 23.9912 13.3931 23.2948 12.6966C22.5984 12.0001 21.7716 11.4476 20.8617 11.0706C19.9517 10.6937 18.9765 10.4996 17.9915 10.4995C16.0024 10.4994 14.0946 11.2895 12.688 12.696C11.2813 14.1025 10.491 16.0101 10.4909 17.9993C10.4908 19.9885 11.2809 21.8962 12.6873 23.3029C14.0938 24.7095 16.0015 25.4998 17.9906 25.4999C19.9798 25.5 21.8876 24.71 23.2942 23.3035ZM25.0617 10.9285C26.7845 12.6512 27.8193 14.9436 27.9716 17.3752C28.124 19.8068 27.3835 22.2104 25.8892 24.1347C25.9092 24.151 25.9267 24.1685 25.9467 24.1872L31.2492 29.4897C31.4838 29.7241 31.6156 30.0421 31.6157 30.3737C31.6158 30.7053 31.4842 31.0233 31.2498 31.2579C31.0154 31.4924 30.6975 31.6243 30.3659 31.6244C30.0343 31.6245 29.7163 31.4929 29.4817 31.2585L24.178 25.9547C24.1599 25.9365 24.1424 25.9178 24.1255 25.8985C22.1157 27.4551 19.5886 28.188 17.0578 27.9482C14.5271 27.7084 12.1826 26.5139 10.501 24.6076C8.81936 22.7012 7.92679 20.2259 8.0047 17.685C8.08262 15.1441 9.12518 12.7283 10.9205 10.9285C12.7958 9.0534 15.3391 8 17.9911 8C20.643 8 23.1864 9.0534 25.0617 10.9285ZM16.7405 12.9997H19.2405V16.7497H22.9905V19.2497H19.2405V22.9997H16.7405V19.2497H12.9905V16.7497H16.7405V12.9997Z"
                      fill="#999999"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </MainContainer>
        </ImageUploadContainer>
      )}
    </Container>
  );
}
