/** @format */

import { transparentize } from "polished";
import styled from "styled-components";
import {
  SoldPropertyError,
  SliderContainer,
  PropertyInformation,
  PropertyInfoContainer,
  HoverSection,
  BuilderPropertyCompany,
} from "./PropertyCard.commonstyle";
type BasePropertyCardProps = {
  $property_type: string;
};
export const BasePropertyCard = styled.div<BasePropertyCardProps>`
  width: 18rem;
  height: ${(props) =>
    props.$property_type === "builder_deals" || props.$property_type === "sold"
      ? "31.0625rem"
      : "28.0625rem"};
  position: relative;
  margin: 0.25rem auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  cursor: pointer;
  .home_Images {
    position: relative;
    overflow: hidden;

    .status_info {
      position: absolute;
    }
    .time_info_label {
      position: absolute;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.3s ease-in-out;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
  .bar {
    display: none;
  }
  ${SoldPropertyError} {
    .info_content_sold_property {
      background-color: white;
    }
  }
  ${HoverSection} {
    display: none;
  }
  &::before {
    display: none;
  }
  @media screen and (min-width: 1281px) {
    width: 99%;
    max-width: 23rem;
  }
`;
export const PropertyCardOne = styled(BasePropertyCard)`
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.3) 3px 3px 7px -3px;
  &:hover {
    .price_address {
      .price {
        padding-bottom: 5px;
        transition: all 0.5s;
      }
    }
  }
  ${PropertyInformation} {
    .price_address {
      .price {
        color: var(--primary);
        font-family: Syncopate;
        font-weight: 400;
        padding-left: 10px;
        padding-right: 10px;
      }
      .address {
        color: var(--primary);
        font-family: Syncopate;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
  ${PropertyInfoContainer} {
    justify-content: space-evenly;
  }
  ${PropertyInfoContainer} {
    .bed,
    .bath,
    .gar {
      font-family: Garamond;
      font-size: 1.5rem;
      font-weight: 400;
    }
    .bed {
      border-right: 1px solid #000000;
      padding-right: 0.5rem;
    }
    .gar {
      border-left: 1px solid #000000;
      padding-left: 0.5rem;
    }
  }
  ${SliderContainer} {
    .home_Images {
      .status_info {
        background-color: var(--primary);
        color: var(--secondary);
        padding-inline: 0.5rem;
      }
      .time_info_label {
        background-color: var(--primary);
        color: var(--secondary);
        padding-inline: 0.5rem;
      }
    }
  }
`;

export const PropertyCardTwo = styled(BasePropertyCard)`
  box-shadow: rgba(0, 0, 0, 0.5) 5px 5px 10px 0px;
  max-width: 100%;
  height: 100%;
  background-color: white;
  &::before {
    display: block;
  }
  ${HoverSection} {
    cursor: pointer;
    display: flex;
  }
  /* &:hover {
    &::before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 100;
      background-color: ${(props) =>
    props?.theme?.colors?.background
      ? props.theme.colors.background
      : "#f8f9fa"};
      opacity: 0.8;
    }
    ${HoverSection} {
      opacity: 1;
    }
  } */
  ${SliderContainer} {
    .home_Images {
      .status_info {
        background-color: var(--primary);
        color: var(--secondary);
        padding-inline: 0.5rem;
      }
    }
  }
  ${PropertyInfoContainer} {
    justify-content: space-evenly;
  }
  ${PropertyInfoContainer} {
    .bed,
    .bath,
    .gar {
      font-family: Garamond;
      font-size: 1.5rem;
      font-weight: 400;
    }
    .bed {
      border-right: 1px solid #000000;
      padding-right: 0.5rem;
    }
    .gar {
      border-left: 1px solid #000000;
      padding-left: 0.5rem;
    }
  }
  ${SliderContainer} {
    .home_Images {
      .status_info {
        background-color: #000000;
        color: ${({ theme: { colors } }) => colors.text};
        padding-inline: 0.5rem;
        right: 0;
      }
    }
  }
  ${PropertyInformation} {
    .price_address {
      width: 100%;
      .price {
        color: #000000;
        font-family: Syncopate;
        font-weight: 400;
        font-family: Tenor Sans;
        font-size: 2.1225rem;
        font-weight: 400;
        text-align: center;
      }
      .address {
        color: #000000;
        font-family: Tenor Sans;
        font-size: 1.1225rem;
        font-weight: 400;
        text-align: center;
        width: 95%;
      }
    }
  }
`;

export const PropertyCardThree = styled(BasePropertyCard)`
  border-radius: 0.9375rem;
  padding: 0.625rem;
  background: #fdfdfe;
  box-shadow: ${({ theme: { colors } }) =>
    colors?.primary &&
    `0px 0px 5px 0px ${transparentize(0.8, colors.primary)}`};
  .home_Images {
    .status_info {
      top: 1.5%;
      left: 1.5%;
      font-family: Inter;
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.21rem;
      text-align: left;
      padding: 0.125rem 0.3125rem;
      background-color: white;
      border-radius: 0.3125rem;
    }
    .time_info_label {
      bottom: 1.5%;
      left: 1.5%;
      font-family: Inter;
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.21rem;
      text-align: left;
      padding: 0.125rem 0.3125rem;
      background-color: white;
      border-radius: 0.3125rem;
    }
    img {
      border-radius: 0.625rem;
    }
  }
  .bar {
    background: #eef0f4;
    height: 0.0625rem;
    border-radius: 0.0625rem;
  }
  ${SoldPropertyError} {
  }
  ${BuilderPropertyCompany} {
    margin-top: 0.25rem;
    border-radius: 0.625rem;
  }
  ${SliderContainer} {
    .slick-dots {
      li {
        &.slick-active {
          button::before {
            color: ${({ theme: { colors } }) =>
              colors?.primary || "var(--primary)"};
          }
        }
      }
    }
    .slick-prev {
      #card-arrows {
        fill: ${({ theme: { colors } }) => colors?.primary || "var(--primary)"};
      }
    }
    .slick-next {
      #card-arrows {
        fill: ${({ theme: { colors } }) => colors?.primary || "var(--primary)"};
      }
    }
  }
  ${PropertyInformation} {
    .price_address {
      max-width: 100%;
      .price {
        color: ${({ theme: { colors } }) =>
          colors?.primary || "var(--primary)"};
        //  filter: blur(10px);
        font-family: Inter;
      }
      .blurred {
        color: ${({ theme: { colors } }) =>
          colors?.primary || "var(--primary)"};
        filter: blur(10px);
      }
      .address {
        color: ${({ theme: { colors } }) =>
          colors?.TextGray || "var(--primary)"};
        font-family: Inter;
      }
    }
    .search_icon {
      display: flex;
      &:hover {
        background: ${({ theme: { colors } }) =>
          colors?.primary || "var(--primary)"};
      }
    }
  }
  ${PropertyInfoContainer} {
    justify-content: space-between;
    .property_info {
      font-family: Inter;
      color: ${({ theme: { colors } }) => colors?.primary || "var(--primary)"};
    }
    .bed,
    .bath,
    .gar {
      svg {
        display: block;
        path {
          fill: ${({ theme: { colors } }) =>
            colors?.primary || "var(--primary)"};
        }
      }
    }
  }
`;
