/** @format */

import styled from 'styled-components';

export const Container = styled.div`
  font-family: 'Montserrat';
  width: min(95%, 85rem);
  margin: 1rem auto;
  h3,
  p {
    font-family: 'Cormorant Garamond';
    text-align: left;
  }
  h1 {
    text-align: center;
  }
`;

export const ReviewCard = styled.div`
  padding: 1rem;
  .description {
    color: ${(props) => props.theme.colors.text2};
    margin: 1rem 0;
    font-size: clamp(0.75rem, 1vw, 1.25rem);
    text-align: left;
  }
  .user_name{
    font-size: clamp(1rem, 1.5vw, 1.5rem);
  }
`;
