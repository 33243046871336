import styled from "styled-components";

import { BlogSliderContainer } from "../blog_slider/blog_slider.style";
export const Ourteamcontainer = styled(BlogSliderContainer)`
.slider_container{
    margin:2.5rem;
}
`

export const TeamMemberContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18.75rem, 1fr));
  gap: 1rem;
  max-width: 100%;
  margin-top: 1rem;
`
