/** @format */

import styled from "styled-components";

export const DashboardMainContainer = styled.div`
  background: #18181a;
  border-radius: 0.9375rem;
  margin-top: 1rem;
  padding: 0.94rem;
  flex: 1;
`;

export const FormContainer = styled.div`
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.4)
  );
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
`;
