import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { MainContainer, InputContainer } from "../profile/pofile.style";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";


type ProfileProps = {
  _id: string;
  activetempid: string;
  domain: string; 
};

const LargeRadioLabel = styled.label`
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-size: 18px;
`;

const LargeRadioButton = styled.input`
  width: 24px;
  height: 24px; 
  margin-right: 10px; 
`;

export const TemplateSwitchingDashboard = () => {
  const token = Cookies.get("token");
  const navigate = useNavigate();
  const [profile, setProfile] = useState<ProfileProps>({
    _id: "",
    activetempid: "",
    domain: "",
  });

  const [notification, setNotification] = useState<string>(""); 
  const [showBackButton, setShowBackButton] = useState<boolean>(false); 
  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    const fetchProfile = async (): Promise<void> => {
      try {
        const profileData = await axios.get("/api/get-agent-profile", {
          headers: {
            "x-access-token": token,
          },
        });
        setProfile(profileData.data.data);
      } catch (e) {
        console.error("Error fetching profile:", e);
      }
    };
    fetchProfile();
  }, []);

  const handleTemplateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProfile((prev) => ({ ...prev, activetempid: e.target.value }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    if (!profile.domain) {
      setNotification(
        "Template switching is possible only when domain linking is done. Please make sure your domain is linked."
      );
      setShowBackButton(true); 
      return;
    }

    try {
      await axios.post(
        `/admin/update-agent/${profile._id}`,
        { activetempid: profile.activetempid },
        {
          headers: {
            "x-access-token": token,
          },
        }
      );

      setIsUpdated(true);
      setNotification("Template updated successfully!");
    } catch (e) {
      console.error("Error updating template:", e);
      setNotification("Error updating the template. Please try again.");
      setShowBackButton(true); 
    }
  };

  const handleBackButtonClick = () => {
    setNotification("");
    setShowBackButton(false);
    setIsUpdated(false);
  };

  const handleProceed = () => {
    navigate(`/designer/${profile.activetempid}/colorselector`);
  };

  return (
    <MainContainer>
    {isUpdated || showBackButton ? (
      <div>
        <h3>{notification}</h3>
        {isUpdated && (
          <button style={{margin:'5px'}} onClick={handleProceed}>Continue Editing Template {profile.activetempid}</button>
        )}
        <button onClick={handleBackButtonClick}>Back</button>
        <br />
          <br />
          <label style={{ color: "white" }}>
            Note: After updating, clear your browser history and cached images and files. Then refresh the domain to see the changes.
          </label>
      </div>
    ) : (
      <>
        <h3>Switch Template</h3>
        <br />
        {notification && <p style={{ color: "red" }}>{notification}</p>}
        <form onSubmit={handleSubmit}>
          <InputContainer>
            <label>Select the template you want to use </label>
            <div>
              {[1, 2, 3].map((id) => (
                <LargeRadioLabel key={id}>
                  <LargeRadioButton
                    type="radio"
                    name="activetempid"
                    value={id.toString()}
                    checked={profile.activetempid === id.toString()}
                    onChange={handleTemplateChange}
                  />
                  Template {id}
                </LargeRadioLabel>
              ))}
            </div>
          </InputContainer>
          <button type="submit">Update</button>
          <br />
          <br />
          <label style={{ color: "white" }}>
            Note: After updating, clear your browser history and cached images and files. Then refresh the domain to see the changes.
          </label>
          <br />
        </form>
      </>
    )}
  </MainContainer>
);
};