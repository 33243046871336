import styled from "styled-components";
import mort from "./mort.jpg";

export const Mort = styled.div`
  background-image: url(${mort});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 5rem 3rem;
  position: relative;
  font-family: Tenor Sans, sans-serif;
    color: #fff;  
    min-height: 100vh;  

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
  }
  h1 {
    color: #fff;
    text-transform: uppercase;
  }
  p {
    color: #bbbbbb;
  }
  /* Container styling */
  .form-group {
    position: relative;
    margin-bottom: 2rem;
  }

  /* Base styling for Material UI-like underline */
  .material-input,
  .material-select {
    font-size: 16px;
    padding: 8px 0;
    border: none;
    border-bottom: 2px solid #bbbbbb;
    outline: none;
    width: 100%;
    transition: border-color 0.3s ease;
    background-color: transparent;
    color: #fff;
  }

  /* Focus effect */
  .material-input:focus,
  .material-select:focus {
    border-color: #fff; /* Material UI primary color */
  }

  /* Floating label style */
  .form-label {
    position: absolute;
    top: -10px;
    font-size: 14px;
    color: #ccc;
    transition: color 0.3s ease;
  }

  /* Hover effect for select */
  .material-select:hover {
    cursor: pointer;
  }

  option {
   color: #000;
  }
`;