/** @format */

import styled from 'styled-components';
import { RgbaColorPicker } from 'react-colorful';
export const ColorPickerContainer = styled.div`
  /* position: absolute;
 top: 0%;
 right:100%; */
  .InputBox {
    background: #474747;
    width: Fill (200px) px;
    height: Hug (40px) px;
    padding: 12px 10px 12px 10px;
    border-radius: 8px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    text-align: left;
    color: #d9d9d9;
    width: 100%;
  }
  border-radius: 1px;
  .color_picker_heading {
    text-align: center;
  }
`;
export const ColorButton = styled.button`
  width: 100%;
  margin-top: 24px;
  padding: 0.625rem 0.9375rem 0.625rem 0.9375rem;
  border-radius: 0.5rem;
  border: 0.0625rem;
  opacity: 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.0625rem solid #fafafc;
  background: #232325;
  display: flex;
  justify-content:flex-start;
  align-items: center;
  gap: 1.5rem;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: left;
  color: #d9d9d9;
`;

export const ColorContainer = styled(RgbaColorPicker)`
   width: 100% !important;
   margin-bottom: 1rem;
`