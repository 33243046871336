import React from 'react'
import { useAuth } from '../context/Auth.context'
import DashboardAdminRoute from './dashboardAdmin.route'
import DashBoardRoute from './dashboard.route'
export default function DashboardWrapper() {
  const { state: { Admin } } = useAuth()
  return (
    <>
      {Admin?.isAdmin ? <DashboardAdminRoute /> : <DashBoardRoute />}
    </>
  )
}

