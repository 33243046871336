/** @format */

import { useEffect, useState } from 'react';

import axios from 'axios';
import { useDashboardMlsContext } from '../../../context/DashboardMlsListing.context';

type property = {
  name: string;
  address: string;
  price: number;
  bed: number;
  bath: number;
  garage: string;
  house_type: string;
  square_footage: string;
  description: string;
  photos: string[];
  mls_number: string;
  property_details?: { Sqft: string };
  city: string;
};

export default function useFetchMlsProperty() {
  const {
    state: { mlsNumber },
  } = useDashboardMlsContext();

  const [propertyData, setPropertyData] = useState<property>({
    name: '',
    address: '',
    price: 0,
    bed: 0,
    bath: 0,
    garage: '0',
    house_type: '',
    square_footage: '',
    photos: [],
    mls_number: '',
    description: '',
    city: ''
  });
  const [error, setError] = useState<string>('');
  const FetchProperty = async () => {
    if (mlsNumber === null) {
      throw new Error('add mls Number first');
    }
    try {
      const fetchData = await axios.get(
        `https://valuation.santhoshmathew.com/api/v1/idx-property-details/${mlsNumber}`
      );
      const propertyData = fetchData.data.data;
      setPropertyData((prev) => ({
        ...prev,
        name: propertyData.realtor,
        address: `${propertyData.address} , ${propertyData.city}`,
        price: propertyData.list_price,
        bed:
          propertyData?.bedrooms === null
            ? 0
            : propertyData?.bedrooms > Number(propertyData?.additional_bathroom)
              ? propertyData?.bedrooms - Number(propertyData?.additional_bathroom)
              : propertyData?.bedrooms,
        bath: propertyData.bathrooms,
        garage: propertyData.garage_spaces,
        house_type: propertyData.property_type,
        square_footage: propertyData.property_details.Sqft,
        description: propertyData.property_description,
        photos: propertyData.photos,
        mls_number: propertyData.mls_num,
      }));
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    const getFetch = async (): Promise<void> => {
      if (mlsNumber !== null) {
        FetchProperty();
      }
    };
    getFetch();
  }, [mlsNumber]);

  return {
    FetchProperty,
    propertyData,
    setPropertyData,
    error,
  };
}
