/** @format */

import styled from 'styled-components';

export const MlsDetailContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4.8px;
  padding: 1.25rem;
  gap: 1.25rem;
  border-radius: 0.9375rem;
  border: 0.0625rem;
  opacity: 0rem;
  cursor: pointer;
  &:hover {
    outline: 1px solid#FAFAFC;
    background: #232325;
    .detail_right_section {
      .mls_preview,
      .mls_edit,
      .mls_delete {
        background:#18181a;
      }
    }
  }
  .detail_left_section {
    display: flex;
    flex: 0.95;
    gap: 1.25rem;
    .property_image {
      width: 8.625rem;
      height: 7.5rem;
      border-radius: 0.625rem;
      opacity: 0rem;
      flex: 0.45;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0.9375rem;
      }
    }
    .property_details {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 0.65;
      .upper_section {
        .address {
          font-family: Montserrat;
          font-size: 1.125rem;
          font-weight: 700;
          line-height: 1.3713rem;
          text-align: left;
          color: #fafafc;
          width: 100%;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden ;
        }
        .price {
          color: #999999;
          font-family: Montserrat;
          font-size: 1.125rem;
          font-weight: 600;
          line-height: 1.3713rem;
          text-align: left;
        }
      }
      .bottom_section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #474747;
        padding-top: 0.7rem;
        .bed,
        .bath,
        .garage {
          display: flex;
          align-items: end;
          gap: 0.375rem;
          p {
            color: #999999;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 400;
            line-height: 18.7504px;
            text-align: left;
            margin: 0;
          }
        }
        .bed {
        }
        .bath {
        }
        .garage {
        }
      }
    }
  }
  .detail_right_section {
    flex: 0.05;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    border-left: 1px solid #474747;
    padding-left: 1.25rem;
    .mls_preview,
    .mls_edit,
    .mls_delete {
      width: 1.85rem;
      height: 1.85rem;
      border-radius: 6.25rem;
      background: #232325;
      padding: 0.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .mls_preview {
      &:hover {
        background: #3c93f7;
        svg {
          path {
            fill: white;
          }
        }
      }
    }
    .mls_edit {
      &:hover {
        background: #ffb10a;
        svg {
          path {
            fill: white;
          }
        }
      }
    }
    .mls_delete {
      &:hover {
        background: #ff5b5b;
        svg {
          path {
            fill: white;
          }
        }
      }
    }
  }
`;

export const ConfirmDelete = styled.div`
 position: fixed;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
 width: 40rem;
 height: 20rem;
 background-color: white;
 text-align:center;
 display:flex;
 justify-content: center;
 align-items:center;
 flex-direction:column;
 border-radius:1.20rem;
 .mls_actions{
     margin-top:1rem;
    .mls_cancle,.mls_delete{
      border:none;
      background-color:black;
      padding-block:0.35rem;
      padding-inline:0.55rem;
      color:white;
      margin-inline:0.25rem;
      border-radius:0.20rem;
   }
}
`
