import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../Template1/Template1.css";
import "../BlogView/BlogView.css";
import { MainContainer } from "./ExclusiveView.style";

type ExclusiveViewProps = {
  id?: string;
};

export default function ExclusiveView({ id }: ExclusiveViewProps) {
  const [data, setData] = useState(null);
  const [exclusiveData, setExclusiveData] = useState(null);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);

  useEffect(() => {
    let exclusiveId = new URLSearchParams(window.location.search).get("id");

    if (!exclusiveId && id !== null) {
      exclusiveId = id;
    }
    const token = Cookies.get("token");

    axios
      .get(`/api/get-agent-exclusivelisting/${exclusiveId}`, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((res) => {
        setExclusiveData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get("/api/get-template?n=1&p=1", {
        headers: {
          "x-access-token": token,
        },
      })
      .then((res) => {
        res.data && setData(res.data);
      });
  }, []);

  const customStyles = data
    ? `
        :root {
            --primary: ${data.styles[0].css.primary};
            --secondary: ${data.styles[0].css.secondary};
        }
        .footer {
            background: ${data.styles[0].css.primary};
            color: ${data.styles[0].css.secondary};
        }
        body {
            min-height: 100vh;
        }
        #root{
            min-height: 100vh;
        }
        .footer{
            display: flex;
            width: 100%;
            padding-top: 3rem;
            padding-bottom: 3rem;
        }
        .card-img-top{
            margin: auto;
            margin-bottom: 2rem;
        }
    `
    : "";

  const mainSliderSettings = {
    dots: false,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    asNavFor: nav2,
  };

  const thumbnailSliderSettings = {
    slidesToShow: Math.min(exclusiveData?.images.length, 5),
    slidesToScroll: 1,
    asNavFor: nav1,
    focusOnSelect: true,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(exclusiveData?.images.length, 4),
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: Math.min(exclusiveData?.images.length, 3),
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: Math.min(exclusiveData?.images.length, 2),
        },
      },
    ],
  };

  return data ? (
    <div style={{ width: "min(120rem, 100%)", margin: "0 auto" }}>
      <style>{customStyles}</style>
      <MainContainer>
        <div className="image_container_hero_section">
          {exclusiveData?.images && (
            <>
              {/* Main slider */}
              <div>
                <Slider
                  {...mainSliderSettings}
                  ref={(slider) => setNav1(slider)}
                >
                  {exclusiveData.images.slice(0, 5).map((image, index) => (
                    <div key={index}>
                      <img
                        src={`/public/${image}`}
                        alt={`Exclusive Image ${index + 1}`}
                        style={{ maxWidth: "100%", margin: "0 auto" }}
                      />
                    </div>
                  ))}
                </Slider>
              </div>

              {/* Thumbnail slider */}
              <div style={{ maxHeight: "35vh" }}>
                <Slider
                  {...thumbnailSliderSettings}
                  ref={(slider) => setNav2(slider)}
                  className="thumbnail-slider"
                >
                  {exclusiveData.images.slice(0, 5).map((image, index) => (
                    <div key={index}>
                      <img
                        src={`/public/${image}`}
                        alt={`Thumbnail ${index + 1}`}
                        style={{
                          maxWidth: "220px",
                          height: "auto",
                          margin: "0 auto",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  ))}
                </Slider>
              </div>


              <div className="thumbnail-content" style={{ padding: "3rem" }}>
                <h2
                  style={{
                    fontFamily: "montserrat",
                    paddingBottom: "1rem",
                    textTransform: "uppercase",
                  }}
                  dangerouslySetInnerHTML={{ __html: exclusiveData?.title }}
                ></h2>
                <p
                  style={{
                    fontFamily: "montserrat",
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: `$${exclusiveData?.price}`,
                  }}
                ></p>
                <p
                  style={{ fontFamily: "montserrat" }}
                  dangerouslySetInnerHTML={{
                    __html: exclusiveData?.description,
                  }}
                ></p>
              </div>

            </>
          )}
        </div>
      </MainContainer>
    </div>
  ) : (
    <div></div>
  );
}

