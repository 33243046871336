import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 480px;
  transition: all 0.5s ease-in-out;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 10px;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none;
    -webkit-transition: all 0.3s;
    transition: all 0.5s;
    background: linear-gradient(
      11deg,
      rgba(19, 24, 38, 0.2) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  &:hover::before {
    background: linear-gradient(360deg, #131826 0%, rgba(19, 24, 38, 0) 100%);
  }
  &:hover {
    z-index: 1;
    transition: all 0.7s ease-in-out;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .lean-card-content {
    padding: 5%;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 3;
    vertical-align: baseline;
    h4 {
      font-family: Montserrat, sans-serif;
      font-size: 24px;
      font-weight: 800;
      letter-spacing: normal;
      line-height: 1.31;
      -webkit-transition: font-size 0.6s;
      transition: font-size 0.6s;
      margin-bottom: 4px;
      color: #ffffff;
    }
    p {
      font-size: 12px;
      font-weight: 700;
      line-height: 1.33;
      text-transform: uppercase;
      color: #ffffff;
    }
    .item-footer {
      max-height: 0;
      opacity: 0;
      transition: all 0.5s ease-in-out;
      p {
        color: #ffffff;
        font-size: 14px;
        margin: 0;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        pointer-events: none;
        max-width: 400px;
      }
    }
  }
  &:hover {
    h4 {
      font-size: 25px;
      transition: all 0.7s ease-in-out;
    }
    & > .lean-card-content {
      .item-footer {
        max-height: 500px;
        opacity: 1;
        transition: all 0.9s ease-in-out;
      }
    }
  }
`;
