import { styled } from "styled-components";

export const Container = styled.div`
// width:70vw;
// margin:0 auto;
`

export const HomeValuationForm = styled.form`
display:flex;
flex-direction:column;

    .mui-input:focus ~ .mui-label,
    .mui-input:not(:placeholder-shown) ~ .mui-label {
      top: -10px;
      font-size: 12px;
      color: #3f51b5;
    }

    .mui-select:focus ~ .mui-label,
    .mui-select:valid ~ .mui-label {
      top: -10px;
      font-size: 12px;
      color: #3f51b5;
    }

    .mui-label {
      position: absolute;
      top: 10px;
      left: 0;
      font-size: 16px;
      color: #aaa;
      transition: all 0.3s ease-in-out;
      pointer-events: none;
    }

    .mui-input,
    .mui-select {
      border: none;
      border-bottom: 2px solid #ccc;
      padding: 8px 0;
      width: 100%;
      font-size: 16px;
      background-color: transparent;
      outline: none;
      transition: border-color 0.3s ease;
    }

    .mui-input:focus,
    .mui-select:focus {
      border-bottom: 2px solid #3f51b5;
    }

    .mui-select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='7'%3E%3Cpath fill='%233f51b5' d='M5 7L0 0h10z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right 8px center;
      background-size: 10px 10px;
      padding-right: 20px;
    }

    .mui-form-group {
      position: relative;
      margin-top: 24px;
    }
`
export const HomeValue = styled.div`
  &#estimated-section {
    .estimated-card {
      background-color: #f7fbff;
      padding: 30px;
      box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.2);
      border-radius: 20px;
    }

    .estimated-card-header {
      display: flex;
      gap: 20px;
      align-items: flex-start;
      flex-wrap: wrap;
      &,
      small {
        font-size: 13px;
      }

      .estimated-property-details {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 6px;
        line-height: 0;
        width: 270px;

        .d-info {
          svg {
            width: 20px;
            path {
              fill: ${(props) => props.theme.colors.primary};
            }
          }
        }
        & > * {
          display: flex;
          align-items: center;
          gap: 3px;
        }

        & > :first-child {
          flex-basis: 100%;
          margin-bottom: 3px;
        }
      }
      .redbuyers_info {
        small,
        p {
          font-weight: bold;
          color: ${(props) => props.theme.colors.primary};
        }
      }
      .sale_range {
        p {
          color: ${(props) => props.theme.colors.primary};
        }
      }
      margin-bottom: 40px;
    }

    .estimated-card-body {
      margin-bottom: 100px;

      .estimated-slider-wrapper {
        background: linear-gradient(90deg, #fff 0%, #ebecf0 100%);
        height: 100px;
        padding: 30px 50px;
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.2);

        .slider-bar {
          height: 7px;
          background-color: ${(props) => props.theme.colors.primary};
          position: relative;

          .slider-bar-ball {
            width: 19px;
            height: 19px;
            border-radius: 50%;
            background: ${(props) => props.theme.colors.primary};
            position: absolute;
          }

          &::before,
          &::after {
            content: '';
            display: block;
            width: 17px;
            height: 17px;
            border-radius: 50%;
            background: ${(props) => props.theme.colors.primary};
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
          }

          &::after {
            right: 0;
            transform: translate(50%, -50%);
          }

          .slider-bar-ball.center {
            width: 28px;
            height: 28px;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 6px solid #fff;
          }
        }
      }

      .slider-price {
        width: 110px;
        height: 45px;
        background: linear-gradient(
          119deg,
          #fff 0%,
          rgba(235, 236, 240, 0.19) 100%
        );
        box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.2);
        color: var(--primary-clr);
        position: absolute;
        top: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 15px;
        transform: translateX(-50%);

        &::after {
          content: '';
          display: block;
          position: absolute;
          border: 8px solid transparent;
          border-bottom-color: #f9f9fa;
          top: -8px;
          transform: translateY(-50%);
        }

        &--right {
          right: 0;
          transform: translateX(50%);
        }

        &--center {
          background: ${(props) => props.theme.colors.primary};
          color: #fff;
          box-shadow: 0px 31.539297103881836px 63.07859420776367px
            rgba(0, 0, 0, 0.2);
        }

        &--center::after {
          border-bottom-color: ${(props) => props.theme.colors.primary};
        }
      }

      .slider-bar-ball.center,
      .slider-price--center {
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .estimated-card-footer {
      p {
        font-size: 13px;
        color: ${(props) => props.theme.colors.primary};
      }
    }
  }
`;

export const PropertyContainer = styled.div`
  width: min(115rem, 94%);
  display: grid;
  grid-template-columns: repeat(auto-fit, minMax(350px, 1fr));
  justify-content: center;
  justify-items: center;
  margin: 0 auto 3rem;
  gap: 0.5rem;
  @media screen and (min-width: 1281px) {
    width: min(95rem, 95%);
    grid-template-columns: repeat(auto-fit, minMax(350px, 1fr));
  }
`;

export const SpinnerMainContainer = styled.div`
 height:30rem;
`
