/** @format */

import { APIProvider, Map } from '@vis.gl/react-google-maps';
import Spinner from '../../../utils/spinner/spinner.component';
import Citysearch from '../CitySearch/citysearch.component';
import FilterProperty from '../FilterBox/filter.component';
import { SearchFilterContainer } from '../idxListing/idxListing.style';
import ClusterPropertyMarkers from './component/ClusterPropertyMarker.component';
import { Polyline } from './component/polyline.component';
import {
  MapContainer,
  SpinnerContainer,
  MapIdxContainer,
  IdxListing,
  MapListing,
} from './ListingMap.style';
import useMap from './useMap';
import { PROPERTY_TYPE_CLASSES } from '../PropertyCard/PropertyCard.utilComponent';
import PropertyColumn from './component/Property_column';
import { useNavigate } from "react-router-dom";

type ListingMapProps = {
  cardType: PROPERTY_TYPE_CLASSES;
};
export default function ListingMap({ cardType }: ListingMapProps) {
  const {
    encodedPath,
    resultCount,
    property,
    loading,
    cameraState,
    currentPage,
    lastPage,
    handleCameraChange,
    handlePageChange,
  } = useMap();
  const Navigate = useNavigate()
  const GOOGLE_API = process.env.REACT_APP_GOOGLE_API;
  return (
    <MapContainer>
      <SearchFilterContainer>
        <Citysearch types="idx" />
        <FilterProperty
          types="idx"
          totalCount={resultCount}
        />
        <button className="button_map" onClick={() => Navigate("../idxlisting")}>
          IDX Search
        </button>
      </SearchFilterContainer>
      <MapIdxContainer>
        <IdxListing>
          <PropertyColumn
            property={property}
            handlePageChange={handlePageChange}
            currentPage={currentPage}
            lastPage={lastPage}
            cardType={cardType}
          />
        </IdxListing>
        <MapListing>
          <div className="map">
            <APIProvider apiKey={GOOGLE_API}>
              <Map
                mapId={'bf51a910020fa25a'}
                style={{
                  width: '100% ',
                  height: '100%',
                }}
                gestureHandling={'greedy'}
                disableDefaultUI={true}
                zoomControl={true}
                onCameraChanged={handleCameraChange}
                {...cameraState}>
                <ClusterPropertyMarkers
                  properties={property}
                  cardType={cardType}
                />
                <Polyline
                  strokeWeight={3}
                  strokeColor={'#ff22cc88'}
                  encodedPath={encodedPath}
                />
              </Map>
            </APIProvider>
          </div>
        </MapListing>
      </MapIdxContainer>

      {loading && (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      )}
    </MapContainer>
  );
}
