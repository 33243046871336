import axios from "axios";
import { useState, useEffect } from "react";
import { PreviewButton, PreviewWrapper } from "./previewwrapper.style";

import {
  Outlet,
  useNavigate,
  useParams,
  useLocation,
  Link,
} from "react-router-dom";

export default function Previewwrapper() {
  const templates = {
    blue1: 1,
    blue2: 2,
    blue3: 3,
  };
  const navigate = useNavigate();
  const { preview } = useParams();
  const location = useLocation();
  const currentPath = location.pathname;
  const segments = currentPath.split("/");
  const tempId = segments[1];
  console.log(tempId);
  const Navigate = (path) => {
    navigate(path);
  };
  const handleBackClick = () => {
    navigate(`http://agentshades.com/designer/${templates[tempId]}/`);
  };
  return (
    <>
      {preview === "preview" && (
        <PreviewWrapper>
          <Link to={`http://agentshades.com/designer/${templates[tempId]}/`}>
            <PreviewButton>Back To Editor</PreviewButton>
          </Link>
          <Link to={`https://agentshades.com/dashboard/blogs`}>
            <PreviewButton>Dashboard</PreviewButton>
          </Link>
        </PreviewWrapper>
      )}
      <Outlet />
    </>
  );
}
