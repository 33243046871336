import { styled } from "styled-components";

export const AgentContainer = styled.div`
  display: grid;
   grid-template-columns: 1fr 1fr 1fr 1fr  1fr 0.4fr;
  align-items: start;
  padding-right:0.75rem;
  padding:0.94rem;
  p{
   color: var(--Dark-Grey---Paragraph-Text, #999);
   font-family: Roboto;
   font-size: 0.75rem;
   font-weight: 600;
   padding:0.94rem;
   margin-left:0.90rem;
  }
  .edit{
    width:100%;
    display:flex;
    justify-content: center;
    background:none;
    cursor:pointer;
  }
`

export const EditContainer = styled.div`
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.4)
  );
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
`
