import React, { useEffect, useState } from "react";
import {
  MainContainer,
  ConfirmUpdate,
  InputContainer,
  InputImageContainer,
  FormContainer,
  Button
} from "./pofile.style";
import axios from "axios";
import Cookies from "js-cookie";
import ProfileUploadImage from "./ProfileUploadImage/ProfileUploadImage";
import Spinner from "../../../../utils/spinner/spinner.component";

type ProfileProps = {
  _id: string;
  name: string;
  brokeragename:string,
  phone: string;
  description: string;
  email: string;
  image: string;
  domain: string;
  activetempid: string;
};

export const ProfileDashboard = () => {
  const token = Cookies.get("token");

  const [profile, setProfile] = useState<ProfileProps>({
    _id: "",
    name: "",
    brokeragename:"",
    phone: "",
    description: "",
    email: "",
    image: "",
    domain: "",
    activetempid: "",
  });

  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [tempImage, setTempImage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("")
  useEffect(() => {
    const fetchProfile = async (): Promise<void> => {
      try {
        const profileData = await axios.get("/api/get-agent-profile", {
          headers: {
            "x-access-token": token,
          },
        });
        setProfile(profileData.data.data);
      } catch (e) {
        console.error("Error fetching profile:", e);
      }
    };
    fetchProfile();
  }, []);

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setProfile((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleImageUpload = (data: string) => {
    setTempImage(data);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    const dataToUpdate = {
      name: profile.name,
      brokeragename:profile.brokeragename,
      email: profile.email,
      phone: profile.phone,
      description: profile.description,
      image: tempImage || profile.image,
    };
    setLoading(true)
    try {
      await axios.post(
        `/admin/update-agent/${profile._id}`,
        dataToUpdate,
        {
          headers: {
            "x-access-token": token,
          },
        }
      );

      setProfile((prev) => ({
        ...prev,
        image: tempImage || prev.image,
      }));

      setTempImage("");
      setIsUpdated(true);
      setMessage("Profile Updated Successfully!")
    } catch (e) {
      console.error("Error updating profile:", e);
    } finally {
      setLoading(false)
    }
  };

  const handleBackButtonClick = () => {
    setIsUpdated(false);
  };

  return (
    <>
      {(isUpdated && !loading) && < ConfirmUpdate >
        <h3>{message}</h3>
        <Button onClick={handleBackButtonClick}>Back</Button>
      </ConfirmUpdate>}
      <MainContainer>
        <h3>Profile</h3>
        <FormContainer className="Profile" onSubmit={handleSubmit}>
          <div className="profile_image_section">
            <InputImageContainer>
              <label>Upload Profile Image</label>
              <img
                className="profile_image"
                src={`/public/${tempImage || profile.image}`}
                alt="Agent_Profile_Image"
              />
              <ProfileUploadImage
                aspect={3 / 2}
                handlImageInputChange={handleImageUpload}
              />
            </InputImageContainer>
          </div>
          <div className="profile_info_section">
            <InputContainer>
              <label>Name</label>
              <input
                name="name"
                type="text"
                onChange={handleInputChange}
                value={profile.name}
              />
            </InputContainer>
            <InputContainer>
              <label>Brokerage Name</label>
              <input
                name="brokeragename"
                type="text"
                onChange={handleInputChange}
                value={profile.brokeragename}
              />
            </InputContainer>
            <InputContainer>
              <label>Email</label>
              <input
                name="email"
                type="email"
                onChange={handleInputChange}
                value={profile.email}
              />
            </InputContainer>
            <InputContainer>
              <label>Phone</label>
              <input
                name="phone"
                type="text"
                onChange={handleInputChange}
                value={profile.phone}
              />
            </InputContainer>
            <InputContainer>
              <label>Description</label>
              <textarea
                name="description"
                onChange={handleInputChange}
                value={profile.description}
              />
            </InputContainer>
            <Button type="submit">{loading ? <Spinner /> : 'Update'}</Button>
          </div>
        </FormContainer>
      </MainContainer>
    </>
  );
};
