/** @format */

import { position } from "polished";
import styled from "styled-components";

export const Container = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
`;
export const InputBoxContainer = styled.input`
  padding: 10px 12px;
  width: 100%;
  border: 0px;
  background-color: #474747;
  border-radius: 8px;
  cursor: pointer;
`;

export const SpinnerContainer = styled.div`
  padding: 10px 12px;
  width: 100%;
  border: 0px;
  background-color: #474747;
  border-radius: 8px;
  cursor: pointer;
`;

export const ImageUploadContainer = styled.div`
  height: 100vh;
  overflow-x: auto;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const MainContainer = styled.div`
  width: 82.5625rem;
  padding: 1.875rem;
  /*max-height: 100vh;*/
  gap: 0.625rem;
  border-radius: 1.5625rem;
  box-shadow: 0px 0px 4px 0px #00000040;
  background: #18181a;
  .header_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .heading {
      font-family: Montserrat;
      font-size: 24px;
      font-weight: 700;
      line-height: 29.26px;
      text-align: left;
      color:white;
    }
    .Action_button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      .close {
        padding: 0.625rem 0.9375rem 0.625rem 0.9375rem;
        gap: 0.3125rem;
        border-radius: 0.5rem;
        border: 0.0625rem;
        border: 0.0625rem solid #fafafc1a;
        background: var(--Active-Black, #232325);
        color: #999999;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.41px;
        text-align: left;
      }
      .Proceed {
        background: #fafafc;
        padding: 0.625rem 0.9375rem 0.625rem 0.9375rem;
        gap: 0.3125rem;
        border-radius: 0.5rem;
        color: #13131a;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.41px;
        text-align: left;
        border: none;
      }
    }
  }
  .container_image_uploader {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: calc(100% - 60px);
    width: 100%;
    gap: 1rem;
    .left_container {
      flex: 0.2;
    }
    .right_container {
      flex: 0.8;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .cropper {
        position: relative;
        width: 100%;
        height: 300px;
        border: 0.0625rem solid #474747;
        border-radius: 0.9375rem;
        overflow: hidden;
      }
      .range {
        padding: 0.25rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.86rem;
        .range_input {
          width: 100%;
        }
        /* .range_input {
          overflow: hidden;
          width: 100%;
          height: 0.25rem;
          border-radius: 0.25rem;
          background: var(--Active-Black, #232325);
          -webkit-appearance: none;
        }
        .range_input::-webkit-slider-runnable-track {
          height: 10px;
          width: 100%;
          -webkit-appearance: none;
          background: var(--Active-Black, #232325);
          margin-top: -1px;
        }
        .range_input::-webkit-slider-thumb {
           width: 10px;
          -webkit-appearance: none;
          height: 10px;
          cursor: ew-resize;
          background:#FAFAFC;
          border-radius: 100%;
          box-shadow: -80px 0 0 100% #474747;

        } */
      }
    }
  }
`;

export const LoaderContainer = styled.div`
  position: fixed;
`;
