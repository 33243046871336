import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import BlogCardList from './components/bloglistcard.component';
import { Container, MainContainer } from './BlogListing.style';
export default function BlogListing() {
  const [data, setData] = useState([])
  useEffect(() => {
    const FetchData = async () => {
      const token = Cookies.get("token");
      const agentUrl = window.location.href.split("/")[2];

      let config = {
        headers: {},
        params: {},
      };

      if (token) {
        config.headers["x-access-token"] = token;
      } else {
        config.params["domain"] = agentUrl;
      }

      try {
        const fetchData = await axios.get("/api/get-agent-blog", config);
        const data = await fetchData.data.data;
        setData(data);
      } catch (e) {
        throw new Error(e);
      }
    };
    FetchData();
  }, []);

  return (
    <MainContainer>
      <h1>Blogs</h1>
      <Container>
        {data && data?.map((data: any) => (<BlogCardList BlogData={data} />))}
      </Container>
    </MainContainer>
  )
}

