/** @format */

import { useEffect, useRef } from 'react';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import Slider from 'react-slick';
import LiveEdithTextHighLighter from '../../../../liveEditHighlighterstyles/liveEdithTextHighLighter.component';
import { Container, ReviewCard } from './clientReeview.style';

import { useLiveEditorHighLighter } from '../../../../context/liveEditorHighighlighter.context';

import { useLocation } from 'react-router-dom';

type ClientReviewProps = {
  data?: {
    text: string;
    cards: Record<string, string>[];
  };
};

export default function ClientReview({ data }: ClientReviewProps) {
  const location = useLocation();
  const pathname = location.pathname;
  const { state } = useLiveEditorHighLighter();
  const SliderRef = useRef(null);
  useEffect(() => {
    const SlideNumber = state.type.split('-');
    if (SlideNumber.length > 1) {
      if (SliderRef.current) {
        SliderRef?.current?.slickGoTo(Number(SlideNumber[1]));
      }
    }
  }, [state.type]);
  const ClientReviewsettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: Math.min(3, data?.cards.length),
    slidesToScroll: 1,
    arrows: false,
    autoplay: pathname.includes('designer') ? false : true,
    responsive: [
      {
        breakpoint: 877,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 393,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <Container id="sec7">
      <h1>
        {' '}
        <LiveEdithTextHighLighter
          section="sec8"
          type="text">
          {data.text}
        </LiveEdithTextHighLighter>{' '}
      </h1>
      <Slider
        {...ClientReviewsettings}
        ref={SliderRef}>
        {data.cards &&
          data.cards.map((data, index) => (
            <ReviewCard key={data?.text}>
              <div className="svg_wrapper">
                <svg
                  width="54"
                  height="54"
                  viewBox="0 0 54 54"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_1262_2126)">
                    <path
                      d="M11.8125 20.25C14.1488 20.25 16.4326 20.9428 18.3752 22.2408C20.3177 23.5387 21.8318 25.3836 22.7258 27.542C23.6199 29.7005 23.8538 32.0756 23.398 34.367C22.9422 36.6584 21.8172 38.7632 20.1652 40.4152C18.5132 42.0672 16.4084 43.1922 14.117 43.648C11.8256 44.1038 9.4505 43.8699 7.29205 42.9758C5.1336 42.0818 3.28873 40.5677 1.99076 38.6252C0.692787 36.6826 -4.64313e-06 34.3988 -4.84738e-06 32.0625L-0.0540058 30.375C-0.0540061 27.2725 0.557074 24.2004 1.74434 21.3341C2.93161 18.4678 4.67181 15.8634 6.86559 13.6696C9.05938 11.4758 11.6638 9.73561 14.5301 8.54834C17.3964 7.36108 20.4685 6.75 23.571 6.75L23.571 13.5C21.354 13.494 19.1577 13.9276 17.1093 14.7757C15.0609 15.6238 13.201 16.8696 11.637 18.441C11.0291 19.0477 10.4684 19.6998 9.95962 20.3917C10.5725 20.2954 11.192 20.2469 11.8125 20.2466L11.8125 20.25ZM42.1875 20.25C44.5238 20.25 46.8076 20.9428 48.7502 22.2408C50.6927 23.5387 52.2068 25.3836 53.1008 27.542C53.9949 29.7005 54.2288 32.0756 53.773 34.367C53.3172 36.6584 52.1922 38.7632 50.5402 40.4152C48.8882 42.0672 46.7834 43.1922 44.492 43.648C42.2006 44.1038 39.8255 43.8699 37.667 42.9758C35.5086 42.0818 33.6637 40.5677 32.3658 38.6252C31.0678 36.6826 30.375 34.3988 30.375 32.0625L30.321 30.375C30.321 24.1093 32.8101 18.1001 37.2406 13.6696C41.6711 9.23905 47.6803 6.74999 53.946 6.74999L53.946 13.5C51.729 13.494 49.5327 13.9276 47.4843 14.7757C45.4359 15.6238 43.576 16.8696 42.012 18.441C41.4041 19.0477 40.8434 19.6998 40.3346 20.3917C40.9476 20.2954 41.567 20.2469 42.1875 20.2466L42.1875 20.25Z"
                      fill="black"
                      fill-opacity="0.5"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1262_2126">
                      <rect
                        width="54"
                        height="54"
                        fill="white"
                        transform="translate(54 54) rotate(180)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <LiveEdithTextHighLighter
                section="sec8"
                type={`description-${index}`}>
                <p className="description">{data?.description}</p>
              </LiveEdithTextHighLighter>
              <LiveEdithTextHighLighter
                section="sec8"
                type={`text-${index}`}>
                <h3 className="user_name">{data?.text}</h3>
              </LiveEdithTextHighLighter>
            </ReviewCard>
          ))}
      </Slider>
    </Container>
  );
}
