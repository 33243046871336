import React, { useEffect, useState } from 'react';
import { ColorButton, ColorContainer, ColorPickerContainer } from './ColorPicker.style';
declare global {
  interface Window {
    EyeDropper: any;
  }
}
export type RGBAprops = {
  r: number | null;
  g: number | null;
  b: number | null;
  a: number | null;
};
type ColorPickerProps = {
  color: RGBAprops;
  setColor: React.Dispatch<React.SetStateAction<RGBAprops>>;
  blendColor?: string | null;
  blend?: boolean;
  setBlend?: React.Dispatch<React.SetStateAction<boolean>>;
};
const hexToRgba = (hex: string): RGBAprops => {
  let r = 0, g = 0, b = 0, a = 1;
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  } else if (hex.length === 9) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
    a = parseInt(hex[7] + hex[8], 16) / 255;
  }
  return { r, g, b, a };
};
const rgbaToHex = (color: RGBAprops): string => {
  const r = (color.r ?? 0).toString(16).padStart(2, '0');
  const g = (color.g ?? 0).toString(16).padStart(2, '0');
  const b = (color.b ?? 0).toString(16).padStart(2, '0');
  const a = Math.round((color.a ?? 1) * 255).toString(16).padStart(2, '0');
  return `#${r}${g}${b}${a}`;
};
export default function ColorPicker({ color, setColor, blendColor, blend, setBlend }: ColorPickerProps) {
  const [inputColor, setInputColor] = useState<string>(rgbaToHex(color));
  const [isEyeDropperActive, setIsEyeDropperActive] = useState<boolean>(false);
  useEffect(() => {
    setInputColor(rgbaToHex(color));
  }, [color]);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputColor(value);
    // Check for rgba format
    const rgbaMatch = value.match(/rgba?\((\d+),\s*(\d+),\s*(\d+),?\s*(\d?.?\d*)?\)/);
    if (rgbaMatch) {
      const [_, r, g, b, a] = rgbaMatch.map(Number);
      setColor({
        r: r ?? 0,
        g: g ?? 0,
        b: b ?? 0,
        a: a ?? 1,
      });
    }
    // Check for hex format
    if (/^#[0-9A-F]{6}$/i.test(value) || /^#[0-9A-F]{8}$/i.test(value)) {
      const rgbaColor = hexToRgba(value);
      setColor(rgbaColor);
    }
  };
  const startEyeDropper = async () => {
    if (!window.EyeDropper) {
      alert('EyeDropper API is not supported in this browser.');
      return;
    }
    try {
      const eyeDropper = new window.EyeDropper();
      const { sRGBHex } = await eyeDropper.open();
      const rgbaColor = hexToRgba(sRGBHex);
      setColor(rgbaColor);
      setInputColor(sRGBHex);
    } catch (error) {
      console.error('Error using EyeDropper API:', error);
    }
  };
  const handleCheckBoxChagne = () => {
    setBlend(prev => !prev)
  }
  return (
    <ColorPickerContainer>

      <ColorContainer color={color} onChange={setColor} />
      <input
        type="text"
        value={inputColor}
        onChange={handleInputChange}
        placeholder="Enter color code"
        className='InputBox'
      />
      <div style={{
        display: 'flex',
        gap: "0.5rem"
      }}>
        <input
          type="checkbox"
          checked={blend}
          onChange={handleCheckBoxChagne}
        />
        <p className='pl-3'>Blend mode</p>
      </div>
      <ColorButton
        onClick={startEyeDropper}
      >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_2694_1461)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.4769 3.511C20.1983 3.23237 19.8675 3.01135 19.5035 2.86055C19.1395 2.70976 18.7494 2.63214 18.3554 2.63214C17.9614 2.63214 17.5712 2.70976 17.2072 2.86055C16.8432 3.01135 16.5124 3.23237 16.2339 3.511L14.7009 5.044C14.1423 4.77553 13.5142 4.68724 12.9033 4.79132C12.2924 4.8954 11.729 5.18673 11.2909 5.625L10.5779 6.339C10.3921 6.52473 10.2447 6.74524 10.1441 6.98795C10.0436 7.23065 9.99179 7.49079 9.99179 7.7535C9.99179 8.01621 10.0436 8.27635 10.1441 8.51905C10.2447 8.76176 10.3921 8.98227 10.5779 9.168L4.09187 15.653C3.81333 15.9317 3.59243 16.2626 3.44178 16.6267C3.29114 16.9908 3.21369 17.381 3.21387 17.775V19.575C3.21387 19.8933 3.3403 20.1985 3.56534 20.4235C3.79038 20.6486 4.09561 20.775 4.41387 20.775H6.21387C7.00925 20.7743 7.7718 20.4578 8.33387 19.895L14.8199 13.411C15.0056 13.5968 15.2261 13.7442 15.4688 13.8448C15.7115 13.9453 15.9717 13.9971 16.2344 13.9971C16.4971 13.9971 16.7572 13.9453 16.9999 13.8448C17.2426 13.7442 17.4631 13.5968 17.6489 13.411L18.3629 12.696C18.8011 12.2579 19.0925 11.6944 19.1965 11.0835C19.3006 10.4727 19.2123 9.84452 18.9439 9.286L20.4769 7.754C20.7555 7.47542 20.9765 7.14468 21.1273 6.78067C21.2781 6.41666 21.3557 6.02651 21.3557 5.6325C21.3557 5.23849 21.2781 4.84834 21.1273 4.48433C20.9765 4.12032 20.7555 3.78958 20.4769 3.511ZM5.50687 17.068L11.9919 10.582L13.4059 11.996L6.92087 18.482C6.73337 18.6695 6.47906 18.7749 6.21387 18.775H5.21387V17.775C5.21392 17.5098 5.31932 17.2555 5.50687 17.068Z" fill="#FAFAFC" />
          </g>
          <defs>
            <clipPath id="clip0_2694_1461">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>

        Pick Color
      </ColorButton>
    </ColorPickerContainer>
  );
}
