/** @format */

import { useEffect, useState } from 'react';
import Spinner from '../../../../../utils/spinner/spinner.component';
import useBuyerLead from '../../../../CustomHooks/useBuyerLead.customHook';
import { FormContainer } from './AppointmentForm.style';
export default function AppointmentForm({ Data }) {
  const [formData, setFormData] = useState({
    domain_name: '',
    name: '',
    email: '',
    phone: '',
    message: `I would like an appointment scheduled on the above mentioned date and time to know more about this property at ${Data?.address},${Data?.county},${Data?.zip_code}`,
  });
  // const [loader, setloader] = useState('form');
  useEffect(() => {
    const agentUrl = window.location.href.split('/')[2];
    // const agentUrl = 'https://dev.agentshades.com/'.split('/')[2]
    setFormData({ ...formData, domain_name: agentUrl });
  }, []);
  // const ScheduleAppointment = async (e) => {
  //     const token = Cookies.get('token');
  //     e.preventDefault();
  //     setloader('loader');
  //     if (
  //         formData.name !== '' &&
  //         formData.email !== '' &&
  //         formData.phone !== '' &&
  //         formData.message !== ''
  //     ) {
  //         const agentUrl = window.location.href.split('/')[2];
  //         try {
  //             let config = {};
  //             if (agentUrl.includes('agentshades')) {
  //                 config = {
  //                     headers: {
  //                         'x-access-token': token,
  //                     },
  //                 };
  //             }
  //             await axios.post('/api/add-buyer-lead', formData, config);
  //             setloader('submitted');
  //         } catch (err) {
  //             console.log(err);
  //             setloader('error');
  //         }
  //     }
  // };
  const { loader, handleBuyerLead } = useBuyerLead(formData);
  return (
    <FormContainer
      onClick={(event) => event.stopPropagation()}
      onSubmit={handleBuyerLead}>
      <h3>
        Interested? <br />
        Schedule Appointment <br /> Now.
      </h3>
      <input
        type="text"
        required
        value={formData.name}
        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
        placeholder="Name"
        className="Name Input"
      />
      <input
        type="text"
        maxLength={10}
        required
        value={formData.phone}
        onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
        placeholder="Phone"
        className="Contact Input"
      />
      <input
        type="email"
        required
        value={formData.email}
        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
        placeholder="Email"
        className="Email Input"
      />
      {/* <input
                        type="date"
                        required
                        value={formData.date}
                        onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                        placeholder="Date"
                        className="Date Input"
                    />
                    <input
                        type="time"
                        required
                        value={formData.time}
                        onChange={(e) => setFormData({ ...formData, time: e.target.value })}
                        className="Time Input"
                    /> */}
      <textarea
        className="TextArea Input"
        value={formData.message}
        onChange={(e) =>
          setFormData({ ...formData, message: e.target.value })
        }></textarea>
      {/* <div className="CheckBox">
                        <input type="checkbox" checked={formData.is_appointment} onChange={(e) => setFormData({
                            ...formData, is_appointment: e.target.checked
                        })} />
                        <p>
                            Please send me information and include price lists and floor
                            plans.
                        </p>
                    </div> */}
      <button> {loader === true ? <Spinner /> : 'Schedule Appointment'}</button>
    </FormContainer>
  );
}
