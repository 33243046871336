
import type { Marker } from '@googlemaps/markerclusterer';
import { AdvancedMarker } from '@vis.gl/react-google-maps';
import { useCallback } from 'react';
import { PropertyType } from './Property.component';
export type PropertyMarkerProps ={
    property:PropertyType,
    onClick:(property:PropertyType)=>void,
    setMarkerRef:(marker:Marker | null,key:string)=>void
}

export default function PropertyMarker(props: PropertyMarkerProps) {
    const { property, onClick, setMarkerRef } = props;
    const handleClick = useCallback(()=>onClick(property),[onClick,property]);
    const ref = useCallback(
        (marker: google.maps.marker.AdvancedMarkerElement) =>
        setMarkerRef(marker, String(property.unique_id)),
      [setMarkerRef, property.unique_id]
    );
  return (
    <AdvancedMarker position={property.position} ref={ref} onClick={handleClick}>
      <span className='marker-cluster-property' style={{fontSize:'1.5rem', color: 'var(--primary)'}}>●</span>
    </AdvancedMarker>
  )
}
