import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ConvertToCanadianNumber from '../../../../utils/New_construction_details/ConvertNumberToDollar.util';
import { TimeLabel } from '../../../../utils/New_construction_details/timelabel';
import { PropertySetImageBanner } from '../../../../utils/New_construction_details/propertyCardSetImage';
import { Heading } from './ListingBuilder.style';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
import BuilderPropertyCard from '../../../utilComponent/BuilderPropertyCard/BuilderPropertyCard.utilcomponent';
export default function ListingBuilderDeals() {
  const [property, setProperty] = useState([]);
  const Navigate = useNavigate();
  const ListingSettings = {
    infinite: true,
    variableWidth: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    swipe: true,
    touchMove: true
  };
  const handleNavigation = () => {
    Navigate('../builderdeals');
  };
  useEffect(() => {
    const fetchData = async () => {
      const builderdata = await axios.get(
        `https://www.redbuyers.com/api/new-construction`,
      );
      const PropertyData = builderdata.data.data.property.data;
      setProperty(
        PropertyData.map((item) => ({
          id: item.id,
          img: PropertySetImageBanner(item),
          price: ConvertToCanadianNumber(item.list_price),
          title: `${item.street_address}`,
          bedrooms: item.bedroom,
          bathrooms: item.bathroom,
          garage: item.garage,
          sale_or_lease: item.plan_properties[0].building_status || 'New',
          plan: item.property_name,
          company: item.plan_properties[0].builder_name,
          parent_property: item.plan_properties[0].property_name,
          property_sqft: item.sqft_area,
          timeLabel: TimeLabel(item.updated_at),
          redirect: '../builderdeals/builder_view',
        })),
      );

      try {
      } catch (e) {
        throw new Error(e.message);
      }
    };
    fetchData();
  }, []);
  return (
    <div>
      <Heading>
        <div>
          <h1>PRE-CONSTRUCTION HOT DEALS</h1>
          {/* <p>Check out some of our exclusive listings</p> */}
        </div>
        <div className='button' onClick={handleNavigation}>
          <p>See More</p>
          <svg
            width='19'
            height='19'
            viewBox='0 0 19 19'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M4.8125 2.625C4.23234 2.625 3.67594 2.85547 3.2657 3.2657C2.85547 3.67594 2.625 4.23234 2.625 4.8125V14.1875C2.625 15.395 3.605 16.375 4.8125 16.375H14.1875C14.7677 16.375 15.3241 16.1445 15.7343 15.7343C16.1445 15.3241 16.375 14.7677 16.375 14.1875V11.6875C16.375 11.4389 16.4738 11.2004 16.6496 11.0246C16.8254 10.8488 17.0639 10.75 17.3125 10.75C17.5611 10.75 17.7996 10.8488 17.9754 11.0246C18.1512 11.2004 18.25 11.4389 18.25 11.6875V14.1875C18.25 15.2649 17.822 16.2983 17.0601 17.0601C16.2983 17.822 15.2649 18.25 14.1875 18.25H4.8125C3.73506 18.25 2.70175 17.822 1.93988 17.0601C1.17801 16.2983 0.75 15.2649 0.75 14.1875V4.8125C0.75 3.73506 1.17801 2.70175 1.93988 1.93988C2.70175 1.17801 3.73506 0.75 4.8125 0.75H7.3125C7.56114 0.75 7.7996 0.848772 7.97541 1.02459C8.15123 1.2004 8.25 1.43886 8.25 1.6875C8.25 1.93614 8.15123 2.1746 7.97541 2.35041C7.7996 2.52623 7.56114 2.625 7.3125 2.625H4.8125ZM10.125 1.6875C10.125 1.43886 10.2238 1.2004 10.3996 1.02459C10.5754 0.848772 10.8139 0.75 11.0625 0.75H17.3125C17.5611 0.75 17.7996 0.848772 17.9754 1.02459C18.1512 1.2004 18.25 1.43886 18.25 1.6875V7.9375C18.25 8.18614 18.1512 8.4246 17.9754 8.60041C17.7996 8.77623 17.5611 8.875 17.3125 8.875C17.0639 8.875 16.8254 8.77623 16.6496 8.60041C16.4738 8.4246 16.375 8.18614 16.375 7.9375V3.95L11.725 8.6C11.6392 8.69211 11.5357 8.76599 11.4207 8.81723C11.3057 8.86847 11.1815 8.89602 11.0557 8.89824C10.9298 8.90046 10.8047 8.87731 10.688 8.83015C10.5713 8.783 10.4652 8.71282 10.3762 8.6238C10.2872 8.53477 10.217 8.42873 10.1698 8.312C10.1227 8.19526 10.0995 8.07023 10.1018 7.94435C10.104 7.81847 10.1315 7.69433 10.1828 7.57933C10.234 7.46433 10.3079 7.36083 10.4 7.275L15.05 2.625H11.0625C10.8139 2.625 10.5754 2.52623 10.3996 2.35041C10.2238 2.1746 10.125 1.93614 10.125 1.6875Z'
              fill='white'
            />
          </svg>
        </div>
      </Heading>
      <Slider {...ListingSettings}>
        {property.map((card) => (
          <BuilderPropertyCard PropertyData={card} />
        ))}
      </Slider>
    </div>
  );
}
