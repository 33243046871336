import React from "react";
import PropertyCard from "../../PropertyCard/PropertyCard.utilComponent";
import { ImageConverter } from "../../idxView/idxhelperfunctions";
import ConvertToCanadianNumber from "../../../../utils/New_construction_details/ConvertNumberToDollar.util";
import ReactPaginate from "react-paginate";
import justimg from "../../../Template2/components/ImageRedirector/Images/ourTeam.jpg";
import { useNavigate } from "react-router-dom";
export default function PropertyColumn({
  property,
  handlePageChange,
  currentPage,
  lastPage,
  cardType,
}) {
  const Navigate = useNavigate();
  const handleRedirect = (id: string) => {
    Navigate(`../idxlisting/idxView/${id}`);
  };
  return (
    <div className="container">
      <div className="row p-3">
        {property &&
          property.map((data) => {
            const img = ImageConverter(data.photos, "idx");
            const price = ConvertToCanadianNumber(data.list_price);
            return (
              <div
                className="col-12 col-xl-6"
                id={data.unique_id}
                onClick={() => handleRedirect(data.unique_id)}
              >
                <div className="realty-card">
                  <div
                    id={`realtyCarousel${data.unique_id}`}
                    className="carousel slide"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <img
                          src={(img && img[0]) || justimg}
                          className="d-block w-100"
                          alt="Realty_Image_1"
                        />
                      </div>
                      <div className="carousel-item">
                        <img
                          src={(img && img[1]) || justimg}
                          className="d-block w-100"
                          alt="Realty_Image_2"
                        />
                      </div>
                      <div className="carousel-item">
                        <img
                          src={(img && img[2]) || justimg}
                          className="d-block w-100"
                          alt="Realty_Image_3"
                        />
                      </div>
                    </div>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target={`#realtyCarousel${data.unique_id}`}
                      data-bs-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target={`#realtyCarousel${data.unique_id}`}
                      data-bs-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div>

                  <div className="realty-info">
                    <div className="realty-price">{price}</div>
                    <p>
                      {data.address} {data.city}
                    </p>
                    <div className="realty-features">
                      <span>
                        🛏 <b>{data.bedrooms}</b> Bedrooms
                      </span>
                      <span>
                        🛁 <b>{data.bathrooms}</b> Bathrooms
                      </span>
                      <span>
                        🚗 <b>{data.garage}</b> Garage
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
            // <PropertyCard
            //   propertyCardType={cardType}
            //   propertyType={'idx'}
            //   propertyData={{
            //     id: data.unique_id.toString(),
            //     img: ImageConverter(data.photos, 'idx'),
            //     price: ConvertToCanadianNumber(data.list_price),
            //     title: `${data.address} ${data.city}`,
            //     bedrooms: data.bedrooms?.toString(),
            //     bathrooms: data.bathrooms?.toString(),
            //     garage: data?.garage?.toString(),
            //     sale_or_lease: data.sale_or_lease,
            //     redirect: `idxView`,
            //   }}
            //   key={data.unique_id}
            // />
          })}
      </div>
      <ReactPaginate
        containerClassName="peginate_class"
        pageClassName="page"
        onPageChange={handlePageChange}
        forcePage={currentPage - 1}
        pageCount={lastPage}
      />
    </div>
  );
}
